import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Collapsible } from 'components'
import { Grid, Box, Hidden, Typography } from '@material-ui/core'
import getVideoId from 'get-video-id'
import ImagesSlider from '../../ImagesSlider'
import { DialogContent } from './StyledComponents'

const DrillBody = ({ drill }) => {
  const getVideoUrl = (url) => {
    const { service, id } = getVideoId(url)
    const baseUrl =
      service === 'youtube'
        ? 'https://www.youtube.com/embed/'
        : 'https://player.vimeo.com/video/'

    return `${baseUrl}${id}`
  }

  return (
    <DialogContent dividers>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          {drill.description && (
            <Typography variant="h5" component="h5">
              {drill.description}
            </Typography>
          )}
          {drill.drill_flow && (
            <Collapsible title={<Translate id="drills.drill-flow" />}>
              <div dangerouslySetInnerHTML={{ __html: drill.drill_flow }} />
            </Collapsible>
          )}
          {drill.organization && (
            <Collapsible title={<Translate id="drills.organization" />}>
              <div dangerouslySetInnerHTML={{ __html: drill.organization }} />
            </Collapsible>
          )}
          {drill.coaching_points && (
            <Collapsible title={<Translate id="drills.coaching-points" />}>
              <div
                dangerouslySetInnerHTML={{ __html: drill.coaching_points }}
              />
            </Collapsible>
          )}
          {drill.variations && (
            <Collapsible title={<Translate id="drills.variations" />}>
              <div dangerouslySetInnerHTML={{ __html: drill.variations }} />
            </Collapsible>
          )}

          {drill.video_url !== null && (
            <Box mt={1}>
              <iframe
                width="100%"
                height="300px"
                title="coachbetter Video"
                src={getVideoUrl(drill.video_url)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Box>
          )}
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={6} xs={12}>
            {drill.relationships.images.length > 0 && (
              <ImagesSlider images={drill.relationships.images} />
            )}
          </Grid>
        </Hidden>
      </Grid>
    </DialogContent>
  )
}

DrillBody.propTypes = {
  drill: PropTypes.shape({
    drill_flow: PropTypes.string,
    description: PropTypes.string.isRequired,
    organization: PropTypes.string,
    coaching_points: PropTypes.string,
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          full_url: PropTypes.string.isRequired,
        })
      ),
    }),
    variations: PropTypes.string,
    video_url: PropTypes.string,
  }).isRequired,
}

export default DrillBody
