import React from 'react'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Input } from 'components'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Translate } from 'react-localize-redux'
import { Alert } from '@material-ui/lab'
import { TASK_GROUP_QUESTION_TYPES } from 'constants/taskGroups'
import PROP_TYPES from 'constants/propTypes'

const AddEditQuestions = ({
  taskGroup,
  questions,
  handleQuestion,
  addNewQuestion,
  setQuestions,
  setTaskGroup,
}) => {
  const handleDelete = (element) => {
    setQuestions(
      questions.filter(
        (value) =>
          value.id !== element.id ||
          value.question !== element.question ||
          value.questionType !== element.questionType
      )
    )
    if (questions.length <= 1) {
      setTaskGroup({
        ...taskGroup,
        error_questions: false,
      })
    }
  }

  return (
    <>
      <Grid xs={12} style={{ marginTop: '1rem'}}>
        <Typography variant="h4" style={{ marginBottom: '.5rem'}}>
          <Translate id="task-management.questions-title" />
        </Typography>
        {questions?.map((ques, index) => (
          <>
            <Grid container alignItems="center">
              <Grid xs={11}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  required
                  disabled={taskGroup?.completed}
                >
                  <InputLabel id="goal-type-select-label">
                    <Translate id="task-management.question-type-label" />
                  </InputLabel>
                  <Select
                    name="questionType"
                    value={ques.questionType}
                    onChange={(event) => handleQuestion(event, index)}
                    label={
                      <Translate id="task-management.question-type-label" />
                    }
                  >
                    {TASK_GROUP_QUESTION_TYPES.map((p) => (
                      <MenuItem key={p.type} value={p.type}>
                        {p.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Input
                  label={<Translate id="task-management.question-label" />}
                  margin="dense"
                  onChange={(event) => handleQuestion(event, index)}
                  name="question"
                  value={ques.question}
                  required
                  disabled={taskGroup?.completed}
                />
              </Grid>
              <Grid xs={1}>
                <Tooltip
                  title={<Translate id="button.delete" />}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={() => handleDelete(ques)}
                    disabled={taskGroup?.completed}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="text"
          color="primary"
          onClick={addNewQuestion}
          disabled={taskGroup?.completed}
        >
          <AddIcon style={{ fontSize: 20 }} />{' '}
          <Translate id="task-management.add-question" />
        </Button>
      </Grid>
      {taskGroup?.error_questions && (
        <Grid item xs={12}>
          <Alert severity="error">
            <Translate id="task-management.task-question.question-validation" />
          </Alert>
        </Grid>
      )}
    </>
  )
}
AddEditQuestions.propTypes = {
  questions: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  handleQuestion: PROP_TYPES.func.isRequired,
  addNewQuestion: PROP_TYPES.func.isRequired,
  setQuestions: PROP_TYPES.func.isRequired,
  taskGroup: PROP_TYPES.shape().isRequired,
  setTaskGroup: PROP_TYPES.func.isRequired,
}
export default AddEditQuestions
