import React from 'react'
import PropTypes from 'prop-types'
import { SubLink } from 'containers'
import { Translate } from 'react-localize-redux'
import { Button } from '@material-ui/core'
import { TEAM_PLAYER_CREATE_ROUTE } from 'constants/routes'

const CreateNew = ({
  fromPathname,
  position,
  variant = '',
  color = '',
  size = '',
  className = '',
}) => (
  <Button
    to={TEAM_PLAYER_CREATE_ROUTE}
    fromPathname={fromPathname}
    position={position}
    component={SubLink}
    variant={variant}
    color={color}
    size={size}
    className={className}
  >
    <Translate id="player.add" />
  </Button>
)

CreateNew.defaultProps = {
  fromPathname: '',
  position: '',
  size: '',
  variant: 'contained',
  color: 'primary',
  className: '',
}

CreateNew.propTypes = {
  fromPathname: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default CreateNew
