import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import binIcon from 'assets/images/16px/bin@1x.svg'
import { Translate } from 'react-localize-redux'
import AlertDialog from 'components/AlertDialog'

const Actions = (params) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
  const handleDelete = (playerId, id) => {
    params.row.deletePlayer(playerId, id).then(() => {
      window.location.reload()
    })
    setOpen(false);
  }

  return <>
    {params.row.userRoleUuid && (
      <>
        <AlertDialog
          open={open}
          onClose={handleClose}
          onCancel={handleClose}
          onSuccess={() => handleDelete({ playerId: params.row.playerId, id: params.row.userRoleUuid })}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />

        <Tooltip title={<Translate id="button.delete"/>} placement="top" arrow>
          <IconButton onClick={handleClickOpen}>
            <img src={binIcon} alt={binIcon} />
          </IconButton>
        </Tooltip>
      </>
    )}
  </>
}

export default Actions

