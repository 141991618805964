/* eslint-disable react/no-multi-comp */
import React, { useState, useMemo } from 'react'
import { makeStyles, styled } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drills as drillsModule } from 'redux/modules'
import OwlCarousel from 'react-slick'
import { DrillsSnackbar, TrainingPackageModal } from 'components'

import {
  CardContent,
  Card,
  CardHeader,
  Typography,
  Box,
  Button,
  Tooltip,
  CardMedia,
  Hidden,
  IconButton,
  Popover,
} from '@material-ui/core'
import playerIcon from 'assets/images/16px/player@1x.svg'
import timeIcon from 'assets/images/16px/time@1x.svg'
import IconLeft from 'assets/images/16px/chevron-left@1x.svg'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconRight from 'assets/images/16px/chevron-right@1x.svg'
import MuiAlert from '@material-ui/lab/Alert'
import { HeartIcon } from '../../../../containers'
import fallbackImageUrl from 'assets/images/login-coach.jpg'
import StyledTrainingPackage from './StyledTrainingPackage'
import PROP_TYPES from 'constants/propTypes'

const SESSION_CATEGORIES = ['warmup', 'main_part', 'final_part']

function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />
}
const useStyles = makeStyles((theme) => ({
  addButton: {
    top: '-16px',
    left: '81%',
  },
  PlayerNum: {
    margin: '0 .8rem',
    display: 'flex',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  CustomCardMedia: {
    height: '7.5rem',
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  AddButton: {
    color: theme.palette.primary.main,
    fontSize: '1.8rem',
  },

  slickSlider: {
    '& .slick-prev, & .slick-next': {
      top: '-8%',
      width: '30px',
      height: '30px',
    },
    '& .slick-prev': {
      right: '42px',
      left: 'inherit',
    },
    '& .slick-next': {
      right: '4px',
    },
    '& .slick-prev:before, & .slick-next:before': {
      content: '""',
      width: '30px',
      borderRadius: '8px',
      position: 'absolute',
      left: '0',
      height: '30px',
      backgroundSize: '24px !important',
      background: '#f3f3f3',
      top: '0',
    },
    '& .slick-prev:before': {
      background: `url(${IconLeft}) no-repeat center #ededed`,
    },
    '& .slick-next:before': {
      background: `url(${IconRight}) no-repeat center #ededed`,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2.1875rem',
      '& .slick-prev, & .slick-next': {
        top: 'inherit',
        bottom: '-3.625rem',
      },
      '& .slick-prev:before, & .slick-next:before': {
        left: '0',
      },
      '& .slick-prev': {
        left: '-5px',
      },
      '& .slick-next': {
        right: '-5px',
      },
    },
  },
  card: {
    '& .MuiCardContent-root': {
      paddingTop: '0',
    },
  },
  [theme.breakpoints.down('sm')]: {
    addPackage: {
      display: 'none',
    },
  },
}))

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    minWidth: 280,
    maxWidth: 280,
    padding: 24,
    borderRadius: 8,
    border: '1px solid #ededed',
    boxShadow: '14px 5px 35px 0 rgba(0, 0, 0, 0.08)',
  },
  '& .MuiTypography-root': {
    marginBottom: theme.spacing(1),
  },
  '& p': {
    color: '#a6a6a6',
  },
}))

const TrainingPackages = ({
  sessionDrills,
  changeSessionDrills,
  trainingPackages,
  favoritesTrainingPackages,
  fetchDrill,
}) => {
  const [openedPackageUuid, setOpenedPackageUuid] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedPopoverIndex, setSelectedPopoverIndex] = useState(null)
  const [trainingPackageForNotify, setTrainingPackageNotify] =
    React.useState(null)

  const handleClose = () => {
    setOpen(false)
  }
  const onAddPackage = (e, id) => {
    e.stopPropagation()

    const targetPackage = trainingPackages.findById(id)

    const newSessionDrills = [...sessionDrills]

    SESSION_CATEGORIES.forEach((category) => {
      newSessionDrills.unshift(
        ...targetPackage[`${category}_drills`].map((drill) => {
          fetchDrill(drill.uuid, { added: true, track: 'training_session' })

          return {
            ...drill,
            session_part: category,
            customized_duration: drill.duration,
          }
        })
      )
    })

    changeSessionDrills(newSessionDrills)
    setTrainingPackageNotify(targetPackage)
    setOpen(true)
  }

  const openedPackage = useMemo(
    () => trainingPackages.findByUuid(openedPackageUuid),
    [openedPackageUuid, trainingPackages]
  )

  const closeOpenedPackage = () => setOpenedPackageUuid(null)

  const getIsFavorite = (id) => Boolean(favoritesTrainingPackages.findById(id))
  const handlePopoverClick = (event, index) => {
    setAnchorEl(event.currentTarget)
    setSelectedPopoverIndex(index)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)

  const classes = useStyles()

  const SETTINGS = {
    className: 'slider variable-width',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    variableWidth: true,
  }

  return (
    <>
      {openedPackageUuid && (
        <TrainingPackageModal
          trainingPackage={openedPackage}
          onHide={closeOpenedPackage}
          open={openedPackageUuid}
        />
      )}
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography variant="h4" component="h4">
              <Translate id="training.featured-training-packages" />
            </Typography>
          }
        />
        <CardContent>
          <OwlCarousel {...SETTINGS} className={classes.slickSlider}>
            {trainingPackages.map((trainingPackage, index) => (
              <Box key={trainingPackage.id}>
                <StyledPopover
                  id={`popover-${index}`}
                  open={openPopover && selectedPopoverIndex === index}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className={classes.popoverContent}>
                    <Typography>
                      <b>Warmup</b>
                      <br />
                      {trainingPackage.warmup_drills.map(({ title }) => title)}
                    </Typography>
                    <Typography>
                      <b>Main Part</b>
                      <br />
                      {trainingPackage.main_part_drills.map(
                        ({ title }) => title
                      )}
                    </Typography>
                    <Typography>
                      <b>Final Part</b>
                      <br />
                      {trainingPackage.final_part_drills.map(
                        ({ title }) => title
                      )}
                    </Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => setOpenedPackageUuid(trainingPackage.id)}
                    >
                      Show More
                    </Button>
                  </div>
                </StyledPopover>
                <StyledTrainingPackage
                  aria-describedby={
                    openPopover ? `popover-${index}` : undefined
                  }
                  onClick={(event) => handlePopoverClick(event, index)}
                >
                  <CardMedia
                    component="div"
                    className={classes.CustomCardMedia}
                    image={trainingPackage.images.length ? trainingPackage.images[trainingPackage.images.length-1].full_url : fallbackImageUrl
                  }
                  />

                  <div style={{ position: 'relative', paddingTop: '.8rem' }}>
                    <div className="title">{trainingPackage.title}</div>
                    <div
                      style={{
                        display: 'flex',
                        padding: '0 12px 6px',
                        alignItems: 'center',
                      }}
                    >
                      <div className="packagetime">
                        <img src={timeIcon} alt={timeIcon} />{' '}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{ fontSize: '0.875rem' }}
                        >
                          {trainingPackage.duration}min
                        </Typography>
                      </div>
                      <div className="player">
                        <Tooltip
                          title={<Translate id="events.table-player" />}
                          placement="top"
                          arrow
                        >
                          <Box className={classes.PlayerNum}>
                            {' '}
                            <img src={playerIcon} alt={playerIcon} />{' '}
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              style={{ fontSize: '0.875rem' }}
                            >
                              {trainingPackage.number_of_players}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </div>
                      <div style={{ marginLeft: 'auto' }}>
                        <HeartIcon
                          isActive={getIsFavorite(trainingPackage.id)}
                          entity="trainingPackages"
                          entityId={trainingPackage.id}
                        />
                      </div>
                      <IconButton>
                        <AddCircleIcon
                          className={classes.AddButton}
                          onClick={(e) => onAddPackage(e, trainingPackage.id)}
                        />
                      </IconButton>
                    </div>
                  </div>
                </StyledTrainingPackage>
              </Box>
            ))}
          </OwlCarousel>
        </CardContent>
      </Card>
      <Hidden only={['md', 'lg', 'xl']}>
        {trainingPackageForNotify && (
          <DrillsSnackbar
            open={open}
            autoHideDuration={13000}
            onClose={handleClose}
            className={classes.snackbar}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              className={classes.alert}
            >
              <Box className="d-flex" alignItems="center">
                <Box className="Snackbar-Box">
                  <Typography variant="h4" component="h4" className="title">
                    <Translate id="snackbar.package-added" />
                  </Typography>
                  <Typography variant="h6" component="h6">
                    {trainingPackageForNotify.title}
                  </Typography>
                </Box>
              </Box>
            </Alert>
          </DrillsSnackbar>
        )}
      </Hidden>
    </>
  )
}

TrainingPackages.defaultProps = {
  sessionDrills: [],
  trainingPackages: [],
  favoritesTrainingPackages: [],
}

TrainingPackages.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  trainingPackages: PROP_TYPES.arrayOfObjects,
  favoritesTrainingPackages: PROP_TYPES.arrayOfObjects,
}

export default connect(
  ({ trainingPackages, favorites }) => ({
    trainingPackages: trainingPackages.items,
    favoritesTrainingPackages: favorites.items.trainingPackages,
  }),
  {
    fetchDrill: drillsModule.fetchDrill,
  }
)(TrainingPackages)
