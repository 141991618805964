import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from 'utils/axios'

export const fetchClubAgeClasses = (clubUuid) => {
  const url = `v2/clubs/${clubUuid}/age-classes`

  return getRequest(url)
}

export const fetchClubDrillCategories = (clubUuid, queryOptions = {}) => {
  const url = `v2/clubs/${clubUuid}/drill-categories`

  return getRequest(url, queryOptions)
}

export const fetchClubDrills = (clubUuid, filterParams = {}) => {
  const url = `v2/clubs/${clubUuid}/drills`

  return getRequest(url, filterParams)
}

export const fetchTeamDrills = (teamUuid, filterParams = {}) => {
  const url = `v2/teams/${teamUuid}/drills`

  return getRequest(url, filterParams)
}

export const fetchFavouriteDrills = (teamUuid, filterParams = {}) => {
  const url = `v2/teams/${teamUuid}/favorite-drills`

  return getRequest(url, filterParams)
}

export const updateFavoriteDrill = (drillUuid, favorite) => {
  const url = `v2/drills/${drillUuid}/favorite`
  const data = { favorite }

  return postRequest(url, data)
}

export const duplicateDrill = (uuid, team_id) => {
  const url = `/duplicate/drill/${uuid}?team_id=${team_id}`

  return postRequest(url)
}

export const fetchSeasonTrainings = (seasonUuid, params = {}) => {
  const url = `v2/seasons/${seasonUuid}/events`

  return getRequest(url, params)
}

export const attachTrainingDrill = (trainingUuid, params = {}) => {
  const url = `/v2/trainings/${trainingUuid}/drills`

  return postRequest(url, params)
}
export const attachTrainingPackage = (trainingUuid, params = {}) => {
  const url = `/v2/trainings/${trainingUuid}/attach-training-package`

  return postRequest(url, params)
}

export const deleteTrainingDrill = (trainingDrillUuid) => {
  const url = `v2/training-drills/${trainingDrillUuid}`

  return deleteRequest(url)
}

export const updateTrainingDrill = (trainingDrillUuid, data) => {
  const url = `v2/training-drills/${trainingDrillUuid}`

  return patchRequest(url, data)
}

export const fetchTrainingDetails = (trainingUuid) => {
  const url = `v2/trainings/${trainingUuid}`

  return getRequest(url)
}
export const createTraining = (data) => {
  const url = `/trainings`

  return postRequest(url, data)
}
