import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
}))

const AddEditPlayerStrengthWeakness = ({
  onClose,
  selectedValue,
  open,
  setStrengths,
  setWeaknesses,
  player,
  createPlayerAnalysis,
  updatePlayerAnalysis,
  fetchTeamPlayer,
  updateID,
  note,
  setNote,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    onClose(selectedValue)
  }
  const handleAnalysisSave = () => {
    const analysisName = document.getElementById('note').value
    if (analysisName === '') return

    onClose()
    createPlayerAnalysis(
      player.id,
      selectedValue === 'strength'
        ? { strengths: [{ name: analysisName }] }
        : { weaknesses: [{ name: analysisName }] }
    ).then((response) => {
      setStrengths(
        response.response.data
          .filter((s) => s.type === 'strength')
          .map((st) => {
            return { id: st.id, name: st.note }
          })
      )
      setWeaknesses(
        response.response.data
          .filter((w) => w.type === 'weakness')
          .map((weak) => {
            return { id: weak.id, name: weak.note }
          })
      )
    })
    setStrengths((prevStrength) => {
      return [...prevStrength, { id: analysisName, name: analysisName }]
    })
  }
  const updateRecord = () => {
    updatePlayerAnalysis(updateID, { selectedValue, note })
    onClose()
    fetchTeamPlayer(player.id, {
      fields: 'avatar,sequences,statistics,journal,last_match,logo',
    })
      .then((response) => {
        setStrengths(
          response.response.data.strengths_weaknesses
            .filter((s) => s.type === 'strength')
            .map((st) => {
              return { id: st.id, name: st.note }
            })
        )
        setWeaknesses(
          response.response.data.strengths_weaknesses
            .filter((w) => w.type === 'weakness')
            .map((weak) => {
              return { id: weak.id, name: weak.note }
            })
        )
      })
      .catch((e) => {
        toast(e.error)
      })
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Box display="flex" alignItems="center">
        <DialogTitle>
          {updateID ? (
            <Translate id="team.update-record" />
          ) : (
            <Translate id="team.add-record" />
          )}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          size="small"
          style={{ marginLeft: 'auto', marginRight: 18 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <form name="PlayerFitnessMedical" autoComplete="off">
        <Translate>
          {({ translate }) => (
            <>
              <DialogContent>
                <Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" margin="dense" disabled>
                      <InputLabel id="demo-simple-select-outlined-label">
                        {translate('form.type')}*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedValue}
                        required
                        label={translate('form.type')}
                      >
                        <MenuItem value="strength">
                          <Translate id="team.strengths" />
                        </MenuItem>
                        <MenuItem value="weakness">
                          {' '}
                          <Translate id="team.weaknesses" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="note"
                      variant="outlined"
                      margin="dense"
                      value={note}
                      multiline
                      rows={4}
                      required
                      label={translate('form.additional-note')}
                      onChange={(event) => {
                        setNote(event.target.value)
                      }}
                      id="note"
                      className={classes.textarea}
                    />
                  </Grid>
                </Grid>
                {updateID ? (
                  <Grid container spacing={2} className="my-3">
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={updateRecord}
                        fullWidth
                      >
                        <Translate id="team.update-record" />
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} className="my-3">
                    <Grid item xs={12} md={6}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAnalysisSave}
                        fullWidth
                      >
                        <Translate id="team.add-record" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleClose}
                        fullWidth
                      >
                        <Translate id="button.close" />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
            </>
          )}
        </Translate>
      </form>
    </Dialog>
  )
}

AddEditPlayerStrengthWeakness.defaultProps = {
  note: '',
  updateID: 0,
}

AddEditPlayerStrengthWeakness.propTypes = {
  createPlayerAnalysis: PropTypes.func.isRequired,
  updatePlayerAnalysis: PropTypes.func.isRequired,
  fetchTeamPlayer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setStrengths: PropTypes.func.isRequired,
  setWeaknesses: PropTypes.func.isRequired,
  player: PropTypes.shape().isRequired,
  updateID: PropTypes.number,
  note: PropTypes.string,
  setNote: PropTypes.func.isRequired,
}
export default AddEditPlayerStrengthWeakness
