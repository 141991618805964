import { IconButton, Menu, MenuItem, Tooltip, makeStyles } from "@material-ui/core"
import MoveIcon from "assets/images/move@1x.svg"
import React, { useState } from "react"
import { Translate } from "react-localize-redux"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { players as playersModule } from "../../../../redux/modules"
import DeactivatePlayerDialog from "./DeactivatePlayerDialog"
import TransferDestinationDialog from "./TransferDestinationDialog"
import TransferPlayerConfirm from "./TransferPlayerConfirm"

const useStyles = makeStyles( {
  menuRoot: {
    '& .MuiPopover-paper': {
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    }
  },
})

const MovePlayer = ({
  playerUuid,
  playerStatus,
  withTransfer,
  deactivatePlayer,
  transferPlayer,
}) => {
  const classes = useStyles()
  const [ anchorEl, setAnchorEl ] = useState(null)
  const [ deactivateOpen, setDeactivateOpen ] = useState(false)
  const [ transferDestinationOpen, setTransferDestinationOpen ] = useState(false)
  const [ transferConfirmationOpen, setTransferConfirmationOpen ] = useState(false)
  const [ destinationTeam, setDestinationTeam ] = useState(null)

  const deactivateEnabled =['ACTIVE', 'TRANSFERRED'].includes(playerStatus)
  const transferEnabled =['ACTIVE', 'TRANSFERRED', 'DEACTIVATED'].includes(playerStatus)

  const handleMoveOptionsOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoveOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDeactivateClick = () => {
    setAnchorEl(null)
    setDeactivateOpen(true)
  }

  const handleDeactivateClose = () => {
    setDeactivateOpen(false)
  }

  const handleDeactivateSubmit = () => {
    setDeactivateOpen(false)
    deactivatePlayer(playerUuid)
  }

  const handleTransferDestinationOpen = () => {
    setAnchorEl(null)
    setTransferDestinationOpen(true)
  }

  const handleTransferDestinationClose = () => {
    setTransferDestinationOpen(false)
  }

  const handleTransferDestinationSubmit = (teamUuid) => {
    setDestinationTeam(teamUuid)
    setTransferDestinationOpen(false)
    setTransferConfirmationOpen(true)
  }

  const handleTransferConfirmationClose = () => {
    setTransferConfirmationOpen(false)
  }

  const handleTransferConfirmationSubmit = () => {
    setTransferConfirmationOpen(false)
    transferPlayer(playerUuid, destinationTeam)
  }

  return (
    <>
      <Tooltip title={<Translate id="button.transfer"/>} placement="top" arrow>
        <IconButton onClick={ handleMoveOptionsOpen }>
          <img src={ MoveIcon } alt=""/>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={ anchorEl }
        className={ classes.menuRoot }
        open={ !!anchorEl }
        onClose={ handleMoveOptionsClose }
      >
        <MenuItem onClick={ handleDeactivateClick } disabled={ !deactivateEnabled }>
          <Translate id="movePlayers.deactivate-player"/>
        </MenuItem>
        { withTransfer && (
          <MenuItem onClick={ handleTransferDestinationOpen } disabled={ !transferEnabled }>
            <Translate id="movePlayers.transfer-player"/>
          </MenuItem>
        ) }
      </Menu>
      <DeactivatePlayerDialog
        open={ deactivateOpen }
        onClose={ handleDeactivateClose }
        onSubmit={ handleDeactivateSubmit }
      />
      <TransferDestinationDialog
        playerUuid={ playerUuid }
        open={ transferDestinationOpen }
        onClose={ handleTransferDestinationClose }
        onSubmit={ handleTransferDestinationSubmit }
      />
      <TransferPlayerConfirm
        open={ transferConfirmationOpen }
        onClose={ handleTransferConfirmationClose }
        onSubmit={ handleTransferConfirmationSubmit }
      />
    </>
  )
}

MovePlayer.propTypes = {
  playerUuid: PropTypes.string.isRequired,
  playerStatus: PropTypes.string.isRequired,
  withTransfer: PropTypes.bool.isRequired,
  deactivatePlayer: PropTypes.func.isRequired,
  transferPlayer: PropTypes.func.isRequired,
}

export default connect(
  ({ clubs, players }, { playerUuid }) => ({
    playerStatus: players.items.findByUuid(playerUuid)?.status,
  }),
  {
    deactivatePlayer: playersModule.deactivatePlayer,
    transferPlayer: playersModule.transferPlayer,
  },
)(MovePlayer)
