import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Toolbar, Box } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { fetchPlayersAttendance } from 'containers/TeamPlayersData/api'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { useStyles } from '../style/styled'
import StatisticsFilter from '../StatisticsFilter/index'
import AttendanceTable from './AttendanceTable'

const PlayersAttendance = ({
  theme,
  match: {
    params: { seasonId },
  },
}) => {
  const tabs = ['TRAINING', 'MATCH', 'EVENT']
  const [eventTypeTabValue, changeEventTypeTabValue] = useState(tabs[0])
  const [attendanceData, setAttendanceData] = useState([])
  const [loading, setLoading] = useState(false)
  const [matchTypeFilterValue, setMatchTypeFilterValue] = useState('')
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  })
  const classes = useStyles(theme)

  const handleChange = (event, newValue) => {
    changeEventTypeTabValue(newValue)
    setFilterDate({
      startDate: null,
      endDate: null,
    })
    setMatchTypeFilterValue('')
  }

  const fetchAttendance = (params = {}) => {
    fetchPlayersAttendance(seasonId, {
      ...params,
      event_type: eventTypeTabValue,
    }).then(
      (res) => {
        setLoading(false)
        setAttendanceData(res.data)
      },
      () => {
        setLoading(false)
        setAttendanceData([])
      }
    )
  }

  useEffect(() => {
    // When filters are clear and tab loads
    if (
      !filterDate.startDate &&
      !filterDate.endDate &&
      matchTypeFilterValue === ''
    ) {
      setLoading(true)
      setAttendanceData([])

      fetchAttendance()
    }

    // When filters applied
    if (filterDate.startDate && filterDate.endDate) {
      let params = {
        start_date: filterDate.startDate,
        end_date: filterDate.endDate,
        match_type: matchTypeFilterValue,
      }
      if (matchTypeFilterValue) {
        params = { ...params, match_type: matchTypeFilterValue }
      }
      setLoading(true)
      setAttendanceData([])

      fetchAttendance(params)
    }
  }, [eventTypeTabValue, matchTypeFilterValue, filterDate])

  return (
    <>
      <Toolbar className={classes.root}>
        <Tabs
          value={eventTypeTabValue}
          className={classes.title}
          indicatorColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={<Translate id="tab.tab-attendance.trainings" />}
            value="TRAINING"
          />
          <Tab
            label={<Translate id="tab.tab-attendance.matches" />}
            value="MATCH"
          />
          <Tab
            label={<Translate id="tab.tab-attendance.events" />}
            value="EVENT"
          />
        </Tabs>
        <Box ml="auto">
          <StatisticsFilter
            muiSM1={8}
            muiMD1={12}
            muiSM2={3}
            tab={eventTypeTabValue}
            matchType={matchTypeFilterValue}
            setMatchType={setMatchTypeFilterValue}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
          />
        </Box>
      </Toolbar>
      {eventTypeTabValue === 'TRAINING' && (
        <AttendanceTable
          changeTab={changeEventTypeTabValue}
          rows={attendanceData}
          loading={loading}
        />
      )}
      {eventTypeTabValue === 'MATCH' && (
        <AttendanceTable
          changeTab={changeEventTypeTabValue}
          rows={attendanceData}
          loading={loading}
        />
      )}
      {eventTypeTabValue === 'EVENT' && (
        <AttendanceTable
          changeTab={changeEventTypeTabValue}
          rows={attendanceData}
          loading={loading}
        />
      )}
    </>
  )
}

PlayersAttendance.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  match: PropTypes.shape().isRequired,
}

export default withRouter(PlayersAttendance)
