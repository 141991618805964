import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Link,
} from '@material-ui/core'
import urlConstructor from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'
import { INITIAL_ROUTE, SEASONS_ROUTE } from 'constants/routes'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    '& .MuiInputBase-formControl:before': {
      border: '0 solid',
    },
    '& .MuiInputBase-formControl:hover:before': {
      border: '0 solid',
    },
    '& label': {
      color: theme.header.label,
    },
    '& .MuiSelect-root': {
      color: theme.header.dropdown,
    },
    '& fieldset': {
      borderColor: theme.header.label,
      '&:hover': {
        borderColor: theme.header.label,
      },
    },
    '& .MuiSelect-icon': {
      color: theme.header.label,
      right: '5px',
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const createPathname = (newSeasonId, pathname) => {
  const match = matchPath(pathname, {
    path: INITIAL_ROUTE,
  })

  const startPositionOfPathWithoutTeamIdAndSeasonId = match.url.length

  const pathWithoutTeamIdAndSeasonId = pathname.slice(
    startPositionOfPathWithoutTeamIdAndSeasonId
  )

  return urlConstructor(
    match.params.teamId,
    newSeasonId,
    pathWithoutTeamIdAndSeasonId
  )
}

const SeasonSelector = props => {
  const {
    location: { pathname },
    match: {
      isExact,
      params: { teamId, seasonId },
    },
    seasons: { items },
  } = props
  const classes = useStyles()
  const seasonsForCurrentTeam = items[teamId] || []

  const normalizedSeasons = seasonsForCurrentTeam.map(item => {
    const { start_date: startDay, end_date: endDay, id: value } = item

    const title = `${moment(startDay).format('MM.YYYY')} - ${moment(
      endDay
    ).format('MM.YYYY')}`

    return { value, title, disabled: !isExact }
  })

  const onSelect = newSeasonId => {
    const newPathname = createPathname(newSeasonId, pathname)

    window.location = newPathname
  }

  const [currentSession, changeCurrentSession] = React.useState(parseInt(seasonId, 10))

  React.useEffect(() => {
    changeCurrentSession(parseInt(seasonId, 10));
  }, [seasonId]);

  return (
    <Translate>
      {({ translate }) => (
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel shrink id="season-label">
            <Translate id="menu.current-season" />
          </InputLabel>
          <Select
            labelId="season-label"
            id="season"
            value={currentSession}
            onChange={event => {
              changeCurrentSession(+event.target.value)
              onSelect(event.target.value)
            }}
            displayEmpty
            className={classes.selectEmpty}
            autoWidth
          >
            {normalizedSeasons.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
            <Link href={`/team/${teamId}/season/${seasonId}${SEASONS_ROUTE}`}>
              <MenuItem>{translate('season.manage')}</MenuItem>
            </Link>
          </Select>
        </FormControl>
      )}
    </Translate>
  )
}

SeasonSelector.propTypes = {
  // history: PROP_TYPES.history.isRequired,
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
  seasons: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
  connect(({ seasons }) => ({ seasons }))
)(SeasonSelector)
