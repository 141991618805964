import { styled } from '@material-ui/core/styles'
import { Box, Card } from '@material-ui/core'
import { rgba } from 'polished'

// eslint-disable-next-line import/prefer-default-export
export const SessionCategoryBox = styled(Card)(({ theme }) => ({
    display:'flex',
    alignItems: 'flex-start !important',
    padding: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
        fontSize:'1.4rem',
        color:"gray",
        margin: theme.spacing(0, 0.1),
    }
}))
export const StyledArea = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(0.4),
    padding: theme.spacing(0.8),
    borderRadius: 8,
    border: `dashed 3px ${rgba(theme.palette.primary.main, 1)}`,
    backgroundColor: rgba(theme.palette.primary.main, 0.2),
    minHeight: props => (props.isDraggingOver ? '50px' : ''),
    cursor: 'copy',
    outline: 'none',
  }))
  
export const SessionCategoryStyled = styled(Box)(({ theme }) => ({
    display:'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    
    '& .MuiIconButton-root': {
        border: '1px solid',
        borderRadius:0,
        '& .MuiSvgIcon-fontSizeSmall': {
            fontSize:'1.1285714285714284rem'
        }
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after': {
        borderBottom:0,
       
    },
    '& .MuiFormControl-root': {
    marginBottom:theme.spacing(0),
    },
    '& .MuiInput-root': {
        width:30,
        height:30,
      
        '& .MuiInputBase-input': {
            textAlign:'center',
            padding: '0',
        }
    },
'& .MuiIconButton-sizeSmall': {
   
    padding: theme.spacing(0.6),  
    [theme.breakpoints.down('sm')]: { 
        padding: theme.spacing(0.6),
        width:30,
        height:30
    }
},


}))





