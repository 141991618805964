import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { SequencesOrder, MuiDivider } from 'components'
import { SequencesList, InnrHead } from 'containers'
import { sequences as sequencesModule } from 'redux/modules'
import { Chip, Box, Paper, Button, Popover } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Checkbox from './Checkbox'
import PROP_TYPES from 'constants/propTypes'
import SEQUENCE_TAGS from 'constants/sequenceTags'
import AdditionalTag from './AdditionalTag'

const Sequences = ({
  orderState,
  sequences,
  selectedTags,
  changeSelectedTags,
  fetchSequences,
}) => {

  const visibleTags = SEQUENCE_TAGS.slice(0, 11)
  const additionalDropdownTags = SEQUENCE_TAGS.slice(11)
  const sortedTags = additionalDropdownTags.sort((a, b) => {
    const valueA = a.value && typeof a.value === 'string' ? a.value : ''
    const valueB = b.value && typeof b.value === 'string' ? b.value : ''
    return valueA.localeCompare(valueB)
  })

  const [anchorEl1, setAnchorEl1] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)

  let isMounted = true
  const checkSequencesOnProcessed = debounce(() => {
    if (!isMounted) {
      return
    }

    fetchSequences().then(({ response: { data = [] } }) => {
      if (
        data.some(({ processed }) => processed === 0 || processed === false)
      ) {
        checkSequencesOnProcessed()
      }
    })
  }, 30000)

  const [allMatches, setAllMatches] = useState(
    sequences.length > 0
      ? sequences[0].allMatches.map(p => {
          p.title = p.opponent === null ? '' : `VS ${p.opponent}`
          p.checked = false
          return p
        })
      : []
  )


  const handlePlayerClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handlePlayerClose = () => {
    setAnchorEl1(null);
  };

  const handleMatchClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMatchClose = () => {
    setAnchorEl2(null);
  };

  const openPlayer = Boolean(anchorEl1);
  const popoverIdPlayer = openPlayer ? 'popover1' : undefined;

  const openMatch = Boolean(anchorEl2);
  const popoverIdMatch = openMatch ? 'popover2' : undefined;
  
  const [allPlayers, setAllPlayers] = useState(
    sequences.length > 0
      ? sequences[0].allPlayers.map(p => {
          /*eslint-disable */
          p.title = `${p.first_name} ${p.last_name}`
          p.checked = false
          /*eslint-disable */
          return p
        })
      : []
  )

  useEffect(() => {
    if (
      sequences.some(({ processed }) => processed === 0 || processed === false)
    ) {
      checkSequencesOnProcessed()
    }

    return () => {
      isMounted = false
    }
  }, [allMatches, allPlayers])

  const sequencesSortedAndFiltered = useMemo(() => {
    return sequences.sort((a, b) => a.tag === b.tag)
  }, [sequences])

  return (
    <Paper>
      <InnrHead title={<Translate id="videos.sequences" />}>
        {' '}
        {orderState && <SequencesOrder orderState={orderState} />}
      </InnrHead>
      <Box style={{ padding: '24px', paddingTop: '0' }}>
        <Box display="flex" flexWrap="wrap">
          {visibleTags.map(
            ({ value, title: tagTitle, background, color }) => {
              const isSelected = selectedTags.includes(value)

              const onClick = () => {
                if (!isSelected) {
                  return changeSelectedTags([...selectedTags, value])
                }

                return changeSelectedTags([
                  ...selectedTags.filter(tag => tag !== value),
                ])
              }

              return (
                <Chip
                  label={tagTitle}
                  key={value}
                  component={Box}
                  m={(0.3, 0.6)}
                  p={2}
                  color={isSelected ? 'primary' : ''}
                  onClick={onClick}
                  onDelete={isSelected ? onClick : ''}
                />
              )
            }
          )}
          <AdditionalTag 
           selectedTags={selectedTags}
           changeSelectedTags={changeSelectedTags}
           additionalDropdownTags={sortedTags}
            />
        </Box>
        <MuiDivider />
        <Box display="flex" flexWrap="wrap">
          {allPlayers.length > 0 ? (
            <Box style={{ marginRight: '1rem' }}>
                 <Button aria-describedby={popoverIdPlayer} variant="outlined"
                  size="small" endIcon={<ArrowDropDownIcon />} onClick={handleMatchClick}>
                 <Translate id="videos.by-player" />
      </Button>
      <Popover
        id={popoverIdPlayer}
        open={openPlayer}
        anchorEl={anchorEl1}
        onClose={handlePlayerClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
                    {allPlayers.map(cat => (
                      <Checkbox
                        key={cat.id}
                        id={cat.id}
                        label={cat.title}
                        checked={cat.checked}
                        onChange={() => {
                          const players = allPlayers.map(p => {
                            if (p.id === cat.id) {
                              let newObj = { ...p }
                              newObj.checked = !newObj.checked
                              p = newObj
                            }
                            return p
                          })
                          setAllPlayers(players)
                          const ids = players
                            .filter(p => p.checked === true)
                            .map(m => m.id)
                          const playersString =
                            ids.length > 0 ? 'players=' + ids.join(',') : ''
                          let final = []
                          let index = -1
                          if (selectedTags.length === 0) {
                            final = [playersString]
                          } else {
                            index = selectedTags.findIndex(p =>
                              p.startsWith('players=')
                            )
                            if (index < 0) {
                              final = [...selectedTags, playersString]
                            } else {
                              final = [...selectedTags]
                              final[index] = playersString
                            }
                          }

                          return changeSelectedTags(final)
                        }}
                      />
                    ))}
      </Popover>
            </Box>
          ) : (
            ''
          )}

          {allMatches.length > 0 ? (
            <Box>
          <Button
                  variant="outlined"
                  size="small"
                  aria-describedby={popoverIdMatch}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handlePlayerClick}
                >
                  <Translate id="videos.by-match" />
                </Button>
      <Popover
        id={popoverIdMatch}
        open={openMatch}
        anchorEl={anchorEl2}
        onClose={handleMatchClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
                    {allMatches.map(cat => (
                      <Checkbox
                        key={cat.id}
                        id={cat.id}
                        label={
                          cat.opponent === null ? '' : `VS ${cat.opponent}`
                        }
                        checked={cat.checked}
                        onChange={() => {
                          const matches = allMatches.map(p => {
                            if (p.id === cat.id) {
                              let newObj = { ...p }
                              newObj.checked = !newObj.checked
                              p = newObj
                            }
                            return p
                          })
                          setAllMatches(matches)
                          const ids = matches
                            .filter(p => p.checked === true)
                            .map(m => m.id)
                          const matchesString =
                            ids.length > 0 ? 'matches=' + ids.join(',') : ''
                          let final = []
                          let index = -1
                          if (selectedTags.length === 0) {
                            final = [matchesString]
                          } else {
                            index = selectedTags.findIndex(p =>
                              p.startsWith('matches=')
                            )
                            if (index < 0) {
                              final = [...selectedTags, matchesString]
                            } else {
                              final = [...selectedTags]
                              final[index] = matchesString
                            }
                          }

                          return changeSelectedTags(final)
                        }}
                      />
                    ))}
    
      </Popover>
            </Box>
          ) : (
            ''
          )}
        </Box>

        {/* Remove filter */}
        <Box display="flex" flexWrap="wrap" style={{marginBottom: '1rem'}}>
          {selectedTags.length >= 1 && (
            <>
              <MuiDivider />
              <Chip
                label={<Translate id="videos.remove-all-filters" />}
                color="primary"
                component={Box}
                mx={0.6}
                p={2}
                onClick={() => {
                  setAllMatches(
                    allMatches.map(p => {
                      p.checked = false
                      return p
                    })
                  )
                  setAllPlayers(
                    allPlayers.map(p => {
                      p.checked = false
                      return p
                    })
                  )
                  return changeSelectedTags([])
                }}
                onDelete={() => {
                  setAllMatches(
                    allMatches.map(p => {
                      p.checked = false
                      return p
                    })
                  )
                  setAllPlayers(
                    allPlayers.map(p => {
                      p.checked = false
                      return p
                    })
                  )
                  return changeSelectedTags([])
                }}
              />
            </>
          )}
        </Box>
        <SequencesList
          sequences={sequencesSortedAndFiltered}
          orderState={orderState}
        />
      </Box>
    </Paper>
  )
}

Sequences.defaultProps = {
  sequences: [],
  selectedTags: [],
}

Sequences.propTypes = {
  orderState: PROP_TYPES.shape().isRequired,
  sequences: PROP_TYPES.arrayOfObjects,
  selectedTags: PropTypes.arrayOfObjects,
  changeSelectedTags: PROP_TYPES.func.isRequired,
  fetchSequences: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ sequences }) => ({
    sequences: sequences.items,
  }),
  {
    fetchSequences: sequencesModule.fetchSequences,
  }
)(Sequences)
