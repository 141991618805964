import React, { useState, useEffect, useCallback } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  Dialog,
  Grid,
  Box,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Chip,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Translate } from 'react-localize-redux'
import SearchBar from 'material-ui-search-bar'
import { connect } from 'react-redux'
import { ageClasses as ageClassesModule } from 'redux/modules'
import Checkbox from './components/Checkbox'
import PROP_TYPES from 'constants/propTypes'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 1.4, 1, 3),
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      marginLeft: 'auto',
    },
  },
})

const INITIAL_FILTER_STATUS = {
  filter_by_own: 0,
  filter_by_favorites: 0,
  filter_by_age_class: 1,
  categories: [],
}
const resetFilter = {
  ...INITIAL_FILTER_STATUS,
  filter_by_age_class: 0, 
}

const DrillsFilter = ({
  drillsCategories,
  locale,
  parentCategories,
  categoriesIdsForTargetCategory,
  fetchDrills,
  searchedValue,
  changeSearchedValue,
  club,
  fetchAllAgeClasses,
  team,
}) => {
  const [filterStatus, changeFilterStatus] = useState(INITIAL_FILTER_STATUS)
  const [selectedAgeClass, setSelectedAgeClass] = useState(team.age_class_uuid || 'all') 
  const [ageClasses, setAgeClasses] = useState([])

  const [isFilterActive, changeIsFilterActive] = useState(false)

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props

    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton size="small" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  useEffect(() => {
    fetchAllAgeClasses(club.uuid)
      .then((res) => {
        setAgeClasses(res.response.data)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    changeIsFilterActive(
      Boolean(
        filterStatus.filter_by_own ||
          filterStatus.filter_by_favorites ||
          filterStatus.filter_by_age_class ||
          filterStatus.categories.length > 0
      )
    )

    const categories = filterStatus.categories.length
      ? filterStatus.categories
      : categoriesIdsForTargetCategory
    const selectedCategoriesFilter = filterStatus.categories.length ? 1 : 0
    fetchDrills({ ...filterStatus, categories, selectedCategoriesFilter, age_class_uuid: selectedAgeClass })
  }, [locale, filterStatus, selectedAgeClass])

  const changeActiveCategories = useCallback(
    (newActiveCategories, isDrillCategories = false) => {
      if (isDrillCategories) {
        changeFilterStatus({
          ...filterStatus,
          categories: newActiveCategories,
          'categories-select': true,
        })

        return
      }
      changeFilterStatus({
        ...filterStatus,
        categories: newActiveCategories,
        'categories-select': false,
      })
    },
    [filterStatus]
  )

  const changeOwnAndFavoritesCheckStatus = useCallback(
    (newOwnAndFavoritesCheckStatus) => {
      changeFilterStatus({ ...filterStatus, ...newOwnAndFavoritesCheckStatus })
    },
    [filterStatus]
  )
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheckboxClick = ({ target: { id, checked } }) => {
    changeOwnAndFavoritesCheckStatus({
      ...filterStatus,
      [id]: +checked,
    })
  }

  const handleCheckbox = ({ target: { id, checked } }) => {
    if (checked) {
      changeActiveCategories([...filterStatus.categories, +id], true)
    } else {
      changeActiveCategories(
        filterStatus.categories.filter((category) => category !== +id),
        true
      )
    }
  }

  const handleAgeClassFilter = (event) => {
    const newSelectedAgeClass = event.target.value
    setSelectedAgeClass(newSelectedAgeClass)
    changeFilterStatus({
      ...filterStatus,
      filter_by_age_class: newSelectedAgeClass !== 'all' ? 1 : 0,
      age_class_uuid:
        newSelectedAgeClass !== 'all' ? newSelectedAgeClass : null,
    })
  }

  const handleResetFilter = useCallback(() => {
    handleClose()
    const resetFilter = {
      ...filterStatus,
      categories: [],
    }
    if (filterStatus.age_class_uuid === 'all') {
      resetFilter.age_class_uuid = null
    }
    changeFilterStatus(resetFilter)
  }, [filterStatus, changeFilterStatus])

  const classes = makeStyles((theme) => ({
    searchBar: {
      border: '1px solid #e8e8e8',
      marginRight: 10,
    },
    ageClassDropdown: {
      marginRight: 10,
      marginLeft: 10,
    },
    formControl: {
      marginTop: 0,
      marginBottom: 0,
      '& .MuiInputBase-formControl': {
        height: '48px',
      },
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('xs')]: {
      ageClassDropdown: {
        display: 'contents',
        margin: 0,
      },
      flexRow: {
        flexDirection: 'column',
      },
      formControl: {
        marginTop: 10,
        marginBottom: 10,
      },
      searchBar: {
        marginRight: 0,
        marginBottom: 10,
      },
    },
  }))()

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle onClose={handleClose}>
          <Translate id="training.select-filters" />
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h4">
            <Translate id="drills.custom-activities" />
          </Typography>
          <Translate>
            {({ translate }) => (
              <Box>
                <Checkbox
                  id="filter_by_own"
                  label={translate('drills.own-drills')}
                  checked={Boolean(filterStatus.filter_by_own)}
                  onChange={handleCheckboxClick}
                />
                <Checkbox
                  id="filter_by_favorites"
                  label={translate('drills.favorite-drills')}
                  checked={Boolean(filterStatus.filter_by_favorites)}
                  onChange={handleCheckboxClick}
                />
              </Box>
            )}
          </Translate>
          {parentCategories.map((category) => (
            <>
              <Typography variant="h4">{category.title}</Typography>
              <Box>
                {[
                  category,
                  ...drillsCategories.filter(
                    ({ parent_id: parentId }) => parentId === category.id
                  ),
                ].map((category2) => (
                  <Checkbox
                    key={category2.id}
                    id={category2.id}
                    label={category2.title}
                    checked={filterStatus.categories.includes(category2.id)}
                    onChange={handleCheckbox}
                  />
                ))}
              </Box>
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleResetFilter}
          >
            <Translate id="button.reset-filter" />
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            <Translate id="button.apply-filter" />
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.flexContainer}>
        <Box className={classes.flexRow}>
          <Button
            variant="contained"
            color="primary"
            component={Box}
            size="sm"
            onClick={handleClickOpen}
          >
            <Translate id="button.apply-filter" />
          </Button>
          <Grid xs={2} className={classes.ageClassDropdown}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.formControl}
            >
              <InputLabel id="cutoff-time-label">
                <Translate id="drills.age-class-filter" />
              </InputLabel>
              <Select
                value={selectedAgeClass}
                onChange={handleAgeClassFilter}
                label={<Translate id="drills.age-class-filter"/>}
                fullWidth
              >
                <MenuItem value="all">
                  <Translate id="drills.any-age-class" />
                </MenuItem>
                {ageClasses.map((option) => (
                  <MenuItem key={option.uuid} value={option.uuid}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <SearchBar
            className={classes.searchBar}
            value={searchedValue}
            onChange={(value) => changeSearchedValue(value)}
            onCancelSearch={() => changeSearchedValue('')}
          />
          {isFilterActive && (
            <Chip
              label={<Translate id="videos.remove-all-filters" />}
              size="sm"
              component={Box}
              py={1}
              color="primary"
              onClick={() => {
                changeIsFilterActive(false)
                changeFilterStatus(resetFilter)
                setSelectedAgeClass('all')
              }}
              onDelete={() => {
                changeIsFilterActive(false)
                changeFilterStatus(resetFilter)
                setSelectedAgeClass('all')
              }}
            />
          )}
        </Box>
        <Box>
          {Boolean(filterStatus.filter_by_own) && (
            <Checkbox
              id="filter_by_own"
              label="Filter by own"
              checked={Boolean(filterStatus.filter_by_own)}
              onChange={handleCheckboxClick}
            />
          )}
          {Boolean(filterStatus.filter_by_favorites) && (
            <Checkbox
              id="filter_by_favorites"
              label="Filter by favorites"
              color="primary"
              checked={Boolean(filterStatus.filter_by_favorites)}
              onChange={handleCheckboxClick}
              onDelete={() => {
                handleCheckboxClick()
              }}
            />
          )}
          {filterStatus.categories.map((p) => {
            const { id, title } = drillsCategories.find((f) => f.id === p)

            return (
              <Checkbox
                key={id}
                id={id}
                label={title}
                checked
                onChange={handleCheckbox}
              />
            )
          })}
        </Box>
      </Box>
      <Grid md={12}>
        {isFilterActive && (
          <Typography variant="h4">
            {' '}
            <Translate id="button.filter-search" />
          </Typography>
        )}
        {!isFilterActive && (
          <Typography variant="h4">
            <Translate id="training.new-activities" />
          </Typography>
        )}
      </Grid>
    </>
  )
}

DrillsFilter.defaultProps = {
  parentCategories: [],
  categoriesIdsForTargetCategory: [],
}

DrillsFilter.propTypes = {
  drillsCategories: PROP_TYPES.arrayOfObjects.isRequired,
  locale: PROP_TYPES.string.isRequired,
  parentCategories: PROP_TYPES.arrayOfObjects,
  categoriesIdsForTargetCategory: PROP_TYPES.arrayOfNumbers,
  fetchDrills: PROP_TYPES.func.isRequired,
  searchedValue: PROP_TYPES.string.isRequired,
  changeSearchedValue: PROP_TYPES.func.isRequired,
  club: PROP_TYPES.shape().isRequired,
  fetchAllAgeClasses: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ clubs, ageClasses, team }) => ({
    club: clubs.current,
    team,
    ageClasses,
  }),
  {
    fetchAllAgeClasses: ageClassesModule.V2FetchAllAgeClasses,
  }
)(DrillsFilter)
