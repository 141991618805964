import React, { useState } from 'react'
import { connect } from 'react-redux'
import { players } from 'redux/modules'
import { withStyles, makeStyles, styled } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  Paper,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Box,
  Divider,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import binIcon from 'assets/images/16px/bin@1x.svg'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import DescriptionIcon from '@material-ui/icons/Description'
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import HeightIcon from '@material-ui/icons/Height'
import StraightenIcon from '@material-ui/icons/Straighten'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import editIcon from 'assets/images/16px/edit@1x.svg'
import { InnrHead } from 'containers'
import AlertDialog from 'components/AlertDialog'
import { formatCostumDateForUI } from 'utils/formatDate'
import { Snackbars } from 'components'
import AddEditPlayerFitnessMedical from './AddEditPlayerFitnessMedical'

const MuiBox = withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(-2),
    display: 'flex',
    justifyContent: 'center',
    '& .MuiBox-root': {
      minWidth: 40,
      maxWidth: 180,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiTypography-root': {
        padding: theme.spacing(0, 2),
      },
    },
  },
}))(Box)

const useToolbarStyles = makeStyles(theme => ({
  BoxPaper: {
    padding: theme.spacing(3),
    height: 288,
    overflowY: 'scroll',
  },
}))

const CustomButtonIcon = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    background: '#f1f1f1',
    borderRadius: '0.5rem',
    marginRight: theme.spacing(1)
  },
}));

const PlayerFitnessMedical = ({
  playerId,
  fitnessNmedicalRecords,
  createPlayerPhysique,
  updatePlayerPhysique,
  deletePlayerPhysique,
  createPlayerInjury,
  updatePlayerInjury,
  deletePlayerInjury,
  createPlayerNote,
  updatePlayerNote,
  deletePlayerNote,
  physicalAge,
  physicalHeight,
  physicalWeight,
  fetchTeamPlayer,
  dateFormat
}) => {
  const classes = useToolbarStyles()
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState()
  const [selectedTabValue, setSelectedTabValue] = useState(0)
  const [existingData, setExistingData] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [recordToDelete, setRecordToDelete] = useState(null)
  const [Snackbar, changeSnackbar] = React.useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })

  const handleClickOpen = (type, recId = null) => {
    setSelectedTabValue(type)
    setExistingData(
      fitnessNmedicalRecords.filter(rec => rec.unique_id === recId)
    )
    setOpen(true)
  }

  const handleOpenAlert = (record) => {
    setRecordToDelete(record);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setRecordToDelete(null);
    setOpenAlert(false);
  };

  const updatePlayerAfter = (tempPlayerId)=>{fetchTeamPlayer(tempPlayerId,{fields:'avatar,sequences,statistics,journal,last_match,logo'})}

  const handleDeleteRecord = id => {
    deletePlayerPhysique(id).then(
      updatePlayerAfter(playerId)
    )
  }

  const handleDeleteInjuryRecord = id => {
    deletePlayerInjury(id).then(
      updatePlayerAfter(playerId)
    )
  }

  const handleDeleteNoteRecord = id => {
    deletePlayerNote(id).then(
      updatePlayerAfter(playerId)
    )
  }
  const handleDelete = () => {
    if (recordToDelete) {
      const recordId = recordToDelete.id;
      const recordType = recordToDelete.type;

      if (recordType === 'physique') {
        handleDeleteRecord(recordId);
        changeSnackbar({
          show: true,
          message: 'Delete Physique record',
        });
      } else if (recordType === 'injury') {
        handleDeleteInjuryRecord(recordId);
        changeSnackbar({
          show: true,
          message: 'Delete Injury record',
        });
      } else if (recordType === 'note') {
        handleDeleteNoteRecord(recordId);
        changeSnackbar({
          show: true,
          message: 'Delete Note record',
        });
      }

      handleCloseAlert();
    }
  };

  const handleClose = value => {
    setOpen(false)
    setSelectedValue(value)
  }

  const borderBottom = {
    borderBottom: '1px solid lightgray',
    borderRadius: '10;',
  }

  return (
    <>
      <AlertDialog
        open={openAlert}
        onClose={handleCloseAlert}
        onSuccess={handleDelete}
        onCancel={handleCloseAlert}
        title={<Translate id="button.delete" />}
        subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
        submitButtonLabel={<Translate id="button.delete" />}
        cancelButtonLabel={<Translate id="button.cancel" />}
      />
      {open && <AddEditPlayerFitnessMedical
        playerId={playerId}
        createPlayerPhysique={createPlayerPhysique}
        updatePlayerPhysique={updatePlayerPhysique}
        createPlayerInjury={createPlayerInjury}
        updatePlayerInjury={updatePlayerInjury}
        createPlayerNote={createPlayerNote}
        updatePlayerNote={updatePlayerNote}
        fetchTeamPlayer={updatePlayerAfter}
        selectedValue={selectedValue}
        selectedTabValue={selectedTabValue}
        existingData={existingData.length ? existingData[0] : null}
        open={open}
        onClose={handleClose}
      />}
      <Paper>
        <InnrHead
          title={<Translate id="team.player-fitness-and-medical" />}
          divider={<Divider />}
        >
          {' '}
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleClickOpen(null, null)}
          >
            <Translate id="team.add-record" />
          </Button>
        </InnrHead>
        {fitnessNmedicalRecords.length > 0 ? (
          <>
            <MuiBox>
              <Box>
                {' '}
                <CalendarTodayIcon color="disabled" size="small" />
                <Typography variant="h4">
                  {physicalAge}
                  &nbsp;
                  <Translate id="player.yrs" />
                </Typography>
              </Box>
              <Box>
                {' '}
                <HeightIcon color="disabled" size="small" />
                <Typography variant="h4">
                  {physicalHeight}
                  &nbsp;cm
                </Typography>
              </Box>
              <Box>
                <StraightenIcon color="disabled" size="small" />
                {' '}
                <Typography variant="h4">
                  {physicalWeight}
                  &nbsp;kg
                </Typography>
              </Box>
            </MuiBox>
            <Divider my={1.4} />
            <Box className={classes.BoxPaper}>
              <List>
                {fitnessNmedicalRecords.map((record, index) => (
                  <ListItem
                    alignItems="flex-start"
                    key={record.id + record.type}
                    id={index}
                    style={borderBottom}
                  >
                    <ListItemAvatar>
                      {record.type === 'injury' ? (
                        <HealingOutlinedIcon color="disabled" size="small" />
                      ) : (
                        ''
                      )}
                      {record.type === 'physique' ? (
                        <AccessibilityIcon color="disabled" size="small" />
                      ) : (
                        ''
                      )}
                      {record.type === 'note' ? (
                        <DescriptionIcon color="disabled" size="small" />
                      ) : (
                        ''
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={(
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            className=""
                            color="textPrimary"
                          >
                            {record.type === 'injury' ? (
                              <>
                                <strong>
                                  <Translate id="player.record.medical-note" />
                                  {' '}
                                  &bull; 
                                  {' '}
                                  {record.user}
                                </strong>
                                <br />
                                <Translate id="form.from" />&nbsp;
                                {formatCostumDateForUI(record.from,dateFormat)}
                                &nbsp;<Translate id="form.to" />&nbsp;
                                {formatCostumDateForUI(record.to,dateFormat)}
                                <br />
                                {record.note}
                              </>
                            ) : (
                              ''
                            )}
                            {record.type === 'physique' ? (
                              <>
                                <strong>
                                  <Translate id="player.record.body-data" />
                                  {' '}
                                  &bull; 
                                  {' '}
                                  {record.user}
                                </strong>
                                <br />
                                {formatCostumDateForUI(record.noted_at,dateFormat)}
                                <HeightIcon />
                                {record.height}
                                cm &nbsp;&nbsp;&nbsp;
                                <StraightenIcon />
                                &nbsp;&nbsp;&nbsp;
                                {record.weight}
                                kg &nbsp;&nbsp;
                                <br />
                                {record.note}
                              </>
                            ) : (
                              ''
                            )}

                            {record.type === 'note' ? (
                              <>
                                <strong>
                                  <Translate id="player.record.additional-note" />
                                  {' '}
                                  &bull; 
                                  {' '}
                                  {record.user}
                                </strong>
                                <br />
                                {formatCostumDateForUI(record.noted_at,dateFormat)}
                                <br />
                                {record.note}
                              </>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </>
)}
                    />
                    <p
                      style={{
                        float: 'right',
                        paddingRight: '20px',
                        color: 'gray',
                        fontSize: '12px',
                        lineHeight: 3,
                      }}
                    >
                      {formatCostumDateForUI(record.updated_at,dateFormat)}
                    </p>
                    <Tooltip
                      title={<Translate id="button.edit"/>}
                      placement="top"
                      arrow
                      onClick={() =>
                        handleClickOpen(record.type, record.unique_id)
                      }
                    >
                      <IconButton>
                        <img src={editIcon} alt={editIcon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<Translate id="button.delete"/>} placement="top" arrow>
                      <CustomButtonIcon
                      onClick={() => handleOpenAlert(record)}
                      >
                        <img src={binIcon} alt={binIcon} />
                      </CustomButtonIcon>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        ) : (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '30vh' }}
          >
            <Grid item xs={12}>
              <Paper elevation={3} spacing={3} style={{ textAlign: 'center' }}>
                <IconButton onClick={handleClickOpen} color="primary">
                  <ControlPointIcon />
                </IconButton>
                {' '}
                <Typography variant="h6">
                  <Translate id="team.fitness-empty-note" />
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Paper>
      {Snackbar.show && <Snackbars {...Snackbar} />}
    </>
  )
}

PlayerFitnessMedical.defaultProps = {
  fitnessNmedicalRecords: [],
  physicalAge: 0,
  physicalHeight: 0,
  physicalWeight: 0,
}

PlayerFitnessMedical.propTypes = {
  fitnessNmedicalRecords: PropTypes.arrayOf(PropTypes.shape()),
  createPlayerPhysique: PropTypes.func.isRequired,
  updatePlayerPhysique: PropTypes.func.isRequired,
  deletePlayerPhysique: PropTypes.func.isRequired,
  createPlayerInjury: PropTypes.func.isRequired,
  updatePlayerInjury: PropTypes.func.isRequired,
  createPlayerNote: PropTypes.func.isRequired,
  updatePlayerNote: PropTypes.func.isRequired,
  deletePlayerInjury: PropTypes.func.isRequired,
  deletePlayerNote: PropTypes.func.isRequired,
  playerId: PropTypes.number.isRequired,
  physicalAge: PropTypes.number,
  physicalHeight: PropTypes.number,
  physicalWeight: PropTypes.number,
  fetchTeamPlayer: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
}

export default connect(
  ({ auth }) => ({
    dateFormat: auth.user.datetime_format
  }),
  {
    createPlayerPhysique: players.createPlayerPhysique,
    updatePlayerPhysique: players.updatePlayerPhysique,
    deletePlayerPhysique: players.deletePlayerPhysique,
    createPlayerInjury: players.createPlayerInjury,
    updatePlayerInjury: players.updatePlayerInjury,
    createPlayerNote: players.createPlayerNote,
    updatePlayerNote: players.updatePlayerNote,
    deletePlayerInjury: players.deletePlayerInjury,
    deletePlayerNote: players.deletePlayerNote,
    fetchTeamPlayer: players.fetchTeamPlayer
  }
)(PlayerFitnessMedical)