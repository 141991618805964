import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Button from '@material-ui/core/Button'
import { PLAYER_TAGS } from 'constants/player'

const PlayerCurrentTag = ({ tag }) => {
  const targetTagData = PLAYER_TAGS.find(({ value }) => value === tag)

  if (!targetTagData) {
    return (
      <Button variant="outlined">
        <Translate id="player.available" />
      </Button>
    )
  }

  const { value, title: tagTitle } = targetTagData

  return (
    <Button key={value} size="small" variant="outlined">
      {tagTitle}
    </Button>
  )
}

PlayerCurrentTag.defaultProps = {
  tag: '',
}

PlayerCurrentTag.propTypes = {
  tag: PropTypes.string,
}

export default PlayerCurrentTag
