import React, { useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import TrashIcon from 'assets/images/16px/bin@1x.svg'
import EditIcon from 'assets/images/16px/edit@1x.svg'
import PropTypes from 'prop-types'
import AlertDialog from 'components/AlertDialog'
import EditDialog from './EditDialog'
import MovePlayer from './MovePlayer'

const GridActionsCell = ({
  row: { player, uuid, onUpdate, onDelete, showTransferOption },
}) => {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)

  const handleDeleteClick = () => {
    setDeleteIsOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteIsOpen(false)
  }

  const handleDeleteConfirm = () => {
    setDeleteIsOpen(false)
    onDelete()
  }

  const handleEditClick = () => {
    setEditIsOpen(true)
  }

  const handleEditClose = () => {
    setEditIsOpen(false)
  }

  return (
    <>
      {deleteIsOpen && (
        <AlertDialog
          open={deleteIsOpen}
          onClose={handleDeleteClose}
          onSuccess={handleDeleteConfirm}
          onCancel={handleDeleteClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}

      <EditDialog
        open={editIsOpen}
        onClose={handleEditClose}
        player={player}
        updatePlayer={onUpdate}
      />

      <Tooltip title={<Translate id="button.edit" />} placement="top" arrow>
        <IconButton onClick={handleEditClick}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={<Translate id="button.delete" />} placement="top" arrow>
        <IconButton onClick={handleDeleteClick}>
          <img src={TrashIcon} alt="" />
        </IconButton>
      </Tooltip>

      <MovePlayer playerUuid={uuid} withTransfer={showTransferOption} />
    </>
  )
}

GridActionsCell.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    player: PropTypes.shape({}).isRequired,
    showTransferOption: PropTypes.bool.isRequired,
  }).isRequired,
}

export default GridActionsCell
