import { styled } from '@material-ui/core/styles'
import InfoImage from 'assets/images/drill-info-w.svg'

const CONTAINER_HEIGHT = 220;
const MARGIN_HORIZONTAL = 6;
const TIME_ICON_WIDTH = 15;

const StyledTrainingPackage = styled('div')({
  width: `calc(265px - ${MARGIN_HORIZONTAL * 2}px) !important`,
  marginLeft: `${MARGIN_HORIZONTAL}px`,
  marginRight: `${MARGIN_HORIZONTAL}px`,
  marginTop: '3px',
  marginBottom: '0',
  border: '1px solid #ededed',
  borderRadius: '12px',
  position: 'relative',
  background: '#ddd',
  height: `${CONTAINER_HEIGHT}px`,
  background: `url(${({ image }) => image}) no-repeat center`,
  backgroundSize: 'cover',
  '& .title': {
    fontSize: '0.875rem',
    margin: '0.5rem 0.9rem 0 0.9rem',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 2rem)',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    lineHeight: '1.3',
  },
  '& .packagetime': {
    display: 'flex',
  },
  '& > div:first-child .time': {
    margin: 'auto 7px 7px 7px',
    position: 'relative',
    paddingLeft: `${TIME_ICON_WIDTH + 5}px`,
    lineHeight: '1.2',
    '&::before': {
      content: ' ',
      position: 'absolute',
      left: '0px',
      verticalAlign: 'middle',
      width: `${TIME_ICON_WIDTH}px`,
      height: `${TIME_ICON_WIDTH}px`,
      background: `url(${InfoImage}) no-repeat center`,
      backgroundSize: 'contain',
    },
    '&::after': {
      content: '"min"',
    },
  },
})

export default StyledTrainingPackage
