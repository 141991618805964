import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize, Translate } from 'react-localize-redux'
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import useStyles from 'routes/styleComponents'
import languages from '../constants/languages'

const LanguageDropdown = ({
  language,
  handleChange, // @todo: send value instead of object
  margin,
  theme,
}) => {
  const classes = useStyles(theme)

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      margin={margin || 'normal'}
    >
      <InputLabel htmlFor="language-switcher">
        <Translate id="login.language" />
      </InputLabel>
      <Select
        labelId="language-switcher-label"
        label={<Translate id="login.language" />}
        value={language}
        onChange={handleChange}
        inputProps={{
          // name: <Translate id="login.language" />,
          id: 'language-switcher',
        }}
      >
        {languages.map(({ code, name }) => (
          <MenuItem key={code} value={code}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

LanguageDropdown.defaultProps = {
  margin: '',
  theme:{}
}

LanguageDropdown.propTypes = {
  theme: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
  language: PropTypes.string.isRequired,
}
export default withLocalize(LanguageDropdown)
