import React from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Autocomplete } from '@material-ui/lab'
import { Checkbox, makeStyles, TextField } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      minHeight: '40px',
      height: 'auto',
    },
  },
}))

const MultiSelect = ({
  options,
  label,
  handleSelection,
  selectedValues,
  error,
  helperText,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Autocomplete
      multiple
      className={classes.root}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => handleSelection(newValue)}
      getOptionSelected={(option, tempvalue) => option.id === tempvalue.id}
      value={selectedValues}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          label={label}
          margin="dense"
          helperText={helperText}
          required
        />
      )}
      {...rest}
    />
  )
}
MultiSelect.defaultProps = {
  error: false,
  helperText: '',
}
MultiSelect.propTypes = {
  handleSelection: PROP_TYPES.func.isRequired,
  options: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  label: PROP_TYPES.string.isRequired,
  selectedValues: PROP_TYPES.arrayOf(PROP_TYPES.string.isRequired).isRequired,
  error: PROP_TYPES.bool,
  helperText: PROP_TYPES.string,
}
export default MultiSelect
