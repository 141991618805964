import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { taskGroups as taskGroupsModule } from 'redux/modules'
import AddTaskGroupDialog from './AddTaskGroupDialog'
import TaskGroupsTable from './TaskGroupsTable'
import PROP_TYPES from 'constants/propTypes'

const TaskGroupsContainer = ({ taskGroups, fetchTaskGroups, team }) => {
  const [open, setOpen] = useState(false)
  const [taskGroupsList, setTaskGroupsList] = useState([])
  const [taskGroup, setTaskGroup] = useState(null)
  const [eventFilter, setEventFilter] = useState('ALL')
  const [statusFilter, setStatusFilter] = useState('OPEN')
  const [paramsObj, setParamsObj] = useState({})

  const handleOpen = (selectedTaskGroup) => {
    setTaskGroup(selectedTaskGroup)
    setOpen(true)
  }
  const handleFilter = (event, type) => {
    const { value } = event.target
    let params = { ...paramsObj }

    switch (type) {
      case 'EVENT_TYPE':
        setEventFilter(value)
        if (value !== 'ALL') {
          params = { ...params, filter_by: value }
        } else {
          delete params.filter_by
        }
        break
      case 'STATUS':
        setStatusFilter(value)
        if (value === 'COMPLETED') {
          params = { ...params, completed: 1 }
        } else {
          delete params.completed
        }
        break

      default:
        break
    }

    setParamsObj(params)
    fetchTaskGroups(team.id, params)
  }

  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    setTaskGroupsList(taskGroups)
  }, [taskGroups])

  return (
    <Grid container spacing={3}>
      {open && (
        <AddTaskGroupDialog
          open={open}
          onClose={handleClose}
          selectedTaskGroup={taskGroup}
        />
      )}
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Typography variant="h4">
          <Translate id="task-management.title" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="h4">
                <Translate id="task-management.next-tasks" />
              </Typography>
            }
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpen(null)}
              >
                <Translate id="task-management.add-task" />
              </Button>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="task-group-event-type-label">
                    <Translate id="task-management.select-task-type" />
                  </InputLabel>
                  <Select
                    labelId="task-group-event-type-label"
                    onChange={(event) => handleFilter(event, 'EVENT_TYPE')}
                    value={eventFilter}
                    label={<Translate id="task-management.select-task-type" />}
                  >
                    <MenuItem value="ALL">
                      <Translate id="matches.all" />
                    </MenuItem>
                    <MenuItem value="TRAINING">
                      <Translate id="menu.training" />
                    </MenuItem>
                    <MenuItem value="MATCH">
                      <Translate id="button.match" />
                    </MenuItem>
                    <MenuItem value="null">
                      <Translate id="task-management.general-cat" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl variant="outlined" size="small">
                  <InputLabel id="filter-select-outlined-label">
                    <Translate id="task-management.select-task-status" />
                  </InputLabel>
                  <Select
                    labelId="filter-select-outlined-label"
                    id="filter-select-outlined"
                    value={statusFilter}
                    onChange={(event) => handleFilter(event, 'STATUS')}
                    label={
                      <Translate id="task-management.select-task-status" />
                    }
                  >
                    <MenuItem value="OPEN">
                      <Translate id="task-management.open" />
                    </MenuItem>
                    <MenuItem value="COMPLETED">
                      <Translate id="home.completed" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TaskGroupsTable
              taskGroupsList={taskGroupsList}
              handleOpen={handleOpen}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

TaskGroupsContainer.propTypes = {
  taskGroups: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  fetchTaskGroups: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape().isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ taskGroups, team }) => ({
      taskGroups: taskGroups.items,
      team,
    }),
    { fetchTaskGroups: taskGroupsModule.fetchTaskGroups }
  )
)(TaskGroupsContainer)
