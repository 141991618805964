import { styled } from '@material-ui/core/styles'
import UploadImage from 'assets/images/16px/droppable-plus@1x.svg'

export const SessionPartWrapper = styled('div')(({ theme }) => ({
  fontSize: '14px',
  letterSpacing: '0.1px',
  lineHeight: 1,
  color: '#000',
  '& > div': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export const DrillsList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const DrillsListItem = styled('div')({
  margin: '2px 0',
  lineHeight: '15px',
  fontSize: 12,
  letterSpacing: '0.09px',
  color: '#000',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
})

export const UploadIcon = styled('div')({
  display: 'block',
  width: 50,
  height: 50,
  background: `url(${UploadImage}) no-repeat center`,
  backgroundSize: 'contain',
})