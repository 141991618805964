import React, { useState, useEffect, useMemo } from 'react'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { Grid, Box, Typography, Link } from '@material-ui/core'
import useGeoLocation from 'react-ipgeolocation'
import {
  getLocale,
  setLocale,
  getSignupURL,
  setClub,
  getLoginURL,
} from 'utils/localstorage'
import {
  auth as authModule,
  ageClasses as ageClassesModule,
  clubs as clubsModule,
  teams as teamsModule,
  seasons as seasonsModule,
  users as usersModule,
  team as teamModule,
} from 'redux/modules'
import { identify } from 'utils/segmentTracking'
import { Snackbars as Snackbar } from 'components'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { userlaneIdentify, userlaneInit } from 'utils/userlane'
import useStyles from '../styleComponents'
import SocialLogin from '../SocialLogin'
import urlConstructor from '../../utils/urlConstructor'
import { Header, SignupForm, TeamCreateForm, LegalText } from './components'
import RolesForm from './components/RolesForm'
import languages from 'constants/languages'
import PROP_TYPES from 'constants/propTypes'
import { DEFAULT_CLUB_ID } from 'constants/club'

const SignupPage = ({
  dispatchResetAuthState,
  authState,
  dispatchSignup,
  ageClasses,
  setActiveLanguage,
  history,
  fetchClub,
  fetchAllAgeClasses,
  theme,
  fetchAuthenticatedUser,
  club,
  createTeamUnderClub,
  fetchUserTeams,
  updateUser,
  usersState,
  teamState,
}) => {
  const classes = useStyles(theme)
  const { locale } = useParams() // locale from URL (optional)
  const { search } = useLocation() // URL query parameters
  const geoIP = useGeoLocation()

  const [snackbar, setSnackbar] = useState(false)
  const [country, setCountry] = useState('CH')

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])

  // Get initial club ID from query parameter or from persistent storage. Otherwise, use default value.
  const [clubId] = useState(() => {
    if (query.get('club') && !Number.isNaN(query.get('club'))) {
      return parseInt(query.get('club'), 10)
    }

    const id = authState.signupClubId ? authState.signupClubId : DEFAULT_CLUB_ID

    // @fixme: #storage this tweak needs to be fixed after fetchAllAgeClasses fix
    // Now, fetchAllAgeClasses automatically appends club ID in request which can be undefined
    // at that moment. Should be defined explicitly.
    localStorage.setItem('club', JSON.stringify({ id }))

    return id
  })

  /**
   * Get preferred language from URL :locale parameter or set it to default (EN)
   * and store value in local storage.
   */
  useEffect(() => {
    if (languages.some((lang) => lang.code === locale)) {
      setLocale(locale)
      setActiveLanguage(locale)
    } else {
      setLocale('en')
      setActiveLanguage('en')
    }
  }, [locale])

  useEffect(() => {
    setCountry(geoIP.country)
  }, [geoIP])

  useEffect(() => {
    fetchClub(authState.signupClubId ? authState.signupClubId : clubId).then(
      ({ response }) => {
        // for backward compatibility
        setClub(response.data)
      }
    )
  }, [authState])

  /**
   * Fetch club age classes.
   */
  useEffect(() => {
    if (club && authState.token) {
      fetchAllAgeClasses(club.uuid)
    }
  }, [club])

  /**
   * Handle steps navigation.
   */
  useEffect(() => {
    const { token } = authState

    dispatchResetAuthState()
    if (token) {
      fetchUserTeams({ include: 'seasons' }).then((data) => {
        const team = data.response.length > 0 ? data.response[0] : null
        if (team) {
          const firstSeason = team.relationships.seasons.find(Boolean)
          const url = urlConstructor(team.id, firstSeason.id)
          history.push(url)
        } else {
          fetchAuthenticatedUser()
          history.push(`${getSignupURL()}/step2`)
        }
      })
    }

    if (
      !token &&
      (window.location.pathname.indexOf('/step2') > 0 ||
        window.location.pathname.indexOf('/step3') > 0)
    ) {
      history.push(getSignupURL())
    }
  }, [])

  const handleLanguage = (event) => {
    // @todo: fix after LanguageDropdown - replace event with value

    setLocale(event.target.value)
    setActiveLanguage(event.target.value)

    const params = new URLSearchParams(window.location.search).toString()
    history.push(params ? `${getSignupURL()}?${params}` : getSignupURL())
  }

  const handleSignup = (values) => {
    setSnackbar(false)

    const params = {
      email: values.email,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName || '',
      language: getLocale(),
      country,
      club_id: clubId,
      utm_source: query.get('utm_source'),
      utm_medium: query.get('utm_medium'),
      utm_campaign: query.get('utm_campaign'),
      utm_term: query.get('utm_term'),
      utm_content: query.get('utm_content'),
      time_zone: moment.tz.guess(),
    }

    dispatchSignup(params)
      .then(({ response }) => {
        identify(response.user_id)
      })
      .then(() => {
        fetchUserTeams({ include: 'seasons' }).then((data) => {
          const team = data.response.length > 0 ? data.response[0] : null
          if (team) {
            const firstSeason = team.relationships.seasons.find(Boolean)
            const url = urlConstructor(team.id, firstSeason.id)
            history.push(url)
          } else {
            if (query.get('plan')) {
              history.push(`${getSignupURL()}/step2?plan=${query.get('plan')}`)
            } else {
              history.push(`${getSignupURL()}/step2`)
            }
            fetchAuthenticatedUser()
          }
        })
      })
      .catch((error) => {
        if (error?.errors?.email) {
          setSnackbar({
            message: error.errors.email[0],
            error: true,
          })
        } else {
          setSnackbar({
            message: <Translate id="login.wrong" />,
            error: true,
          })
        }
      })
  }

  const handleTeamCreate = (values) => {
    setSnackbar(false)

    const params = {
      name: values.teamName,
      age_class_uuid: values.ageClass,
      phone: values.phone,
      gender: 'm',
    }

    if (query.get('plan')) {
      params.subscription_plan_uuid = query.get('plan')
    }

    createTeamUnderClub(club.uuid, params)
      .then(() => {
        userlaneIdentify(
          authState.userId,
          authState.locale,
          authState.signupClubId,
          authState.signupTeamId,
          false,
          country
        )
        userlaneInit()

        history.push(`${getSignupURL()}/step3`)
      })
      .catch((error) => {
        setSnackbar({
          message: error,
          error: true,
        })
      })
  }

  const handleTeamRoles = (values) => {
    updateUser({ team_roles: values.teamRoles, id: authState.user.uuid }).then(
      () => {
        // User will be redirected to team dashboard page when hit base url
        history.push('/')
      }
    )
  }

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item md={6}>
        <Box className={classes.centeredGridContent}>
          <Box className={classes.loginBox}>
            {authState.token &&
              (!teamState.uuid ? (
                <>
                  <Header
                    theme={theme}
                    heading={<Translate id="signup.team-form-heading" />}
                    subHeading={<Translate id="signup.team-form-subheading" />}
                  />
                  <TeamCreateForm
                    ageClasses={ageClasses.items}
                    country={country}
                    onSubmit={handleTeamCreate}
                    inProgress={ageClasses.isLoading || teamState.isLoading}
                    theme={theme}
                  />
                </>
              ) : (
                <>
                  <Header
                    theme={theme}
                    heading={<Translate id="signup.roles-form-heading" />}
                    subHeading={<Translate id="signup.roles-form-subheading" />}
                  />
                  <RolesForm
                    ageClasses={ageClasses.items}
                    country={country}
                    onSubmit={handleTeamRoles}
                    inProgress={usersState.isLoading}
                    theme={theme}
                  />
                </>
              ))}
            {!authState.token && (
              <Box>
                <Header
                  theme={theme}
                  heading={<Translate id="signup.signup" />}
                  subHeading={<Translate id="signup.welcome" />}
                />
                <SignupForm
                  language={getLocale()}
                  onLanguageChange={handleLanguage}
                  inProgress={authState.isLoading}
                  theme={theme}
                  onSubmit={handleSignup}
                />
                {clubId === DEFAULT_CLUB_ID && (
                  <SocialLogin
                    history={history}
                    page="login"
                    theme={theme}
                    googleLink={<Translate id="signup.signup-with-google" />}
                    facebookLink={
                      <Translate id="signup.signup-with-facebook" />
                    }
                  />
                )}
                <Box mt={0.8} align="center">
                  <Typography
                    variant="body1"
                    align="center"
                    component="p"
                    className={classes.typography}
                  >
                    <span>
                      <Translate id="signup.do-have-account" />
                    </span>
                    &nbsp;
                    <Link href={getLoginURL()} underline="always">
                      <Translate id="signup.login" />
                    </Link>
                  </Typography>
                  <LegalText theme={theme} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={false} md={6}>
        <Box className={classes.background} />
      </Grid>

      {snackbar && <Snackbar {...snackbar} />}
    </Grid>
  )
}

SignupPage.defaultProps = {
  club: {},
}
SignupPage.propTypes = {
  dispatchSignup: PROP_TYPES.func.isRequired,
  dispatchResetAuthState: PropTypes.func.isRequired,
  createTeamUnderClub: PropTypes.func.isRequired,
  fetchClub: PropTypes.func.isRequired,
  fetchAllAgeClasses: PROP_TYPES.func.isRequired,
  fetchSeasons: PropTypes.func.isRequired,
  fetchAuthenticatedUser: PropTypes.func.isRequired,
  history: PROP_TYPES.history.isRequired,
  setActiveLanguage: PROP_TYPES.func.isRequired,
  authState: PROP_TYPES.shape().isRequired,
  usersState: PROP_TYPES.shape().isRequired,
  teamState: PROP_TYPES.shape().isRequired,
  ageClasses: PROP_TYPES.shape().isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  club: PropTypes.shape(),
  fetchUserTeams: PROP_TYPES.func.isRequired,
  updateUser: PROP_TYPES.func.isRequired,
}

export default compose(
  withRouter,
  withLocalize,
  connect(
    ({ auth, ageClasses, team, clubs, users }) => ({
      authState: auth,
      ageClasses,
      teamState: team,
      theme: clubs.current ? clubs.current.theme : {},
      club: clubs.current,
      usersState: users,
    }),
    {
      dispatchResetAuthState: authModule.reset,
      dispatchSignup: authModule.signup,
      fetchClub: clubsModule.fetchClub,
      createTeamUnderClub: teamModule.createTeamByClub,
      fetchAllAgeClasses: ageClassesModule.V2FetchAllAgeClasses,
      fetchUserTeams: teamsModule.fetchTeamsByParams,
      fetchSeasons: seasonsModule.fetchSeasons,
      fetchAuthenticatedUser: authModule.fetchUser,
      updateUser: usersModule.updateUser,
    }
  )
)(SignupPage)
