import React, { useState } from 'react'
import { MenuItem, Button } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { IconButton, Menu } from './StyledComponents'

const PhaseMenu = ({ withText, onPhaseSelect }) => {
  const phases = ['warmup', 'main_part', 'final_part']

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClose = () => setAnchorEl(null)
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget)

  const handlePhaseSelect = (event) => {
    const { value } = event.currentTarget.dataset
    onPhaseSelect(value)
    setAnchorEl(null)
  }

  return (
    <>
      {withText === true ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleMenuOpen}
          startIcon={<AddCircleIcon />}
        >
          <Translate id="button.add-activity" />
        </Button>
      ) : (
        <IconButton onClick={handleMenuOpen}>
          <AddCircleIcon />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {phases.map((phase) => (
          <MenuItem key={phase} data-value={phase} onClick={handlePhaseSelect}>
            <Translate id={`training.${phase}`} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

PhaseMenu.defaultProps = {
  withText: false,
}

PhaseMenu.propTypes = {
  withText: PropTypes.bool,
  onPhaseSelect: PropTypes.func.isRequired,
}

export default PhaseMenu
