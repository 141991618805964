import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { useStyles } from '../styles'
import PropTypes from 'constants/propTypes'

const CheckboxDropdown = ({
  options,
  selectedOptions,
  handleCategoryChange,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [tempSelectedOptions, setTempSelectedOptions] = useState([
    ...selectedOptions,
  ])

  useEffect(() => {
    setTempSelectedOptions([...selectedOptions])
  }, [selectedOptions])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleToggle = (value) => () => {
    const currentIndex = tempSelectedOptions.indexOf(value)
    const newChecked = [...tempSelectedOptions]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempSelectedOptions(newChecked)
  }

  const handleSave = () => {
    handleCategoryChange(tempSelectedOptions)
    handleClose()
  }

  const handleReset = () => {
    handleCategoryChange([])
    handleClose()
  }

  const renderCategories = (categories, level = 1) => {
    return categories.map((category) => (
      <div key={category.uuid}>
        <MenuItem>
          <FormControlLabel
            className={classes.categoriesLabel}
            control={
              <Checkbox
                checked={tempSelectedOptions.includes(category.uuid)}
                onChange={handleToggle(category.uuid)}
                value={category.uuid}
                className={classes.checkbox}
              />
            }
            label={category.title}
          />
        </MenuItem>
        {category.relationships && category.relationships.children && (
          <div
            className={classes.flexDisplay}
            style={{
              paddingLeft: level == 2 ? `${level * 10}px` : `${level * 20}px`,
            }}
          >
            {renderCategories(category.relationships.children, level + 1)}
          </div>
        )}
      </div>
    ))
  }

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Translate id="articles.categories" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        className={classes.menu}
      >
        <FormGroup>{renderCategories(options)}</FormGroup>
        <Box className={classes.stickyButtons}>
          <Button onClick={handleReset}>
            <Translate id="button.reset" />
          </Button>
          <Button color="primary" onClick={handleSave}>
            <Translate id="button.save" />
          </Button>
        </Box>
      </Menu>
    </>
  )
}

CheckboxDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
}

export default CheckboxDropdown
