import React from 'react'
import { SubLink } from 'containers'
import {
    TEAM_PLAYER_DASHBOARD_ROUTE
} from 'constants/routes'

const renderFullName = (params) => {

    return (

        <SubLink
            to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
                playerId: params.row.id,
            })}
            fromPathname={null}
            key={`${params.row.id}`}
        >
            {params.row.fullName}
        </SubLink>

    )
}

export default renderFullName

