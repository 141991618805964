import axios from 'axios'
import { setLocale, getLocale } from 'utils/localstorage'
import { store } from '../redux/store'
import { LOGIN_ROUTE, WRONG_PERMISSION_ROUTE } from 'constants/routes'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState()
    const { token } = state.auth
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response) {
      if (
        error.response.status === 401 &&
        window.location.pathname.indexOf('/login') < 0
      ) {
        const locale = getLocale()
        localStorage.clear()
        setLocale(locale)
        window.location.href = LOGIN_ROUTE
      } else if (error.response.status === 403) {
        window.location.href = WRONG_PERMISSION_ROUTE
      }
    }

    return Promise.reject(error)
  }
)

const request = (method, path, config = {}) => {
  return axiosInstance({
    method,
    url: path,
    ...config,
  })
}

export const getRequest = (path, params = {}) => {
  return request('GET', path, { params })
}

export const postRequest = (path, data = {}) => {
  return request('POST', path, { data })
}

export const putRequest = (path, data = {}) => {
  return request('PUT', path, { data })
}

export const deleteRequest = (path, params = {}) => {
  return request('DELETE', path, { params })
}

export const patchRequest = (path, params = {}) => {
  return request('PATCH', path, { params })
}