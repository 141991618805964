import React from 'react'
import { Translate } from 'react-localize-redux'
import {
  Typography,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import DatePicker from 'components/inputes/DatePicker'
import { connect } from 'react-redux'
import ClearIcon from '@material-ui/icons/Clear'
import PropTypes from 'prop-types'
import { handleDateChange } from 'utils/validate'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { MATCH_TYPES } from 'constants/match'

const StatisticsFilter = ({
  dateFormat,
  muiSM1,
  muiSM2,
  muiMD1,
  tab,
  matchType,
  setMatchType,
  filterDate,
  setFilterDate,
}) => {
  const matchTypeChange = (event) => {
    setMatchType(event.target.value)
  }

  const clearAll = () => {
    setMatchType('')
    setFilterDate({
      startDate: null,
      endDate: null,
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={muiMD1}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={tab && tab === 'MATCH' ? muiSM1 : '11'}>
            <Box display="flex" alignItems="center">
              <Box mx={2}>
                <Typography variant="span" gutterBottom>
                  <Translate id="statisticsfilter.filter" />
                </Typography>
              </Box>
              <DatePicker
                required
                label={<Translate id="statisticsfilter.start-date" />}
                disableToolbar={false}
                selectedValue={filterDate.startDate}
                onDateChange={(date) =>
                  handleDateChange(date, 'startDate', filterDate, setFilterDate)
                }
                openTo="date"
                isEditMode
                dateFormat={dateFormat}
              />
              <Box mx={2}>
                <Translate id="statisticsfilter.to" />
              </Box>
              <DatePicker
                required
                label={<Translate id="statisticsfilter.end-date" />}
                disableToolbar={false}
                selectedValue={filterDate.endDate}
                onDateChange={(date) => {
                  handleDateChange(date, 'endDate', filterDate, setFilterDate)
                }}
                openTo="date"
                isEditMode
                dateFormat={dateFormat}
              />
            </Box>
          </Grid>
          {tab && tab === 'MATCH' && (
            <Grid item xs={12} sm={muiSM2}>
              <FormControl variant="outlined" margin="dense">
                <InputLabel id="match-type-label">
                  <Translate id="matches.match-type" />
                </InputLabel>
                <Select
                  labelId="match-type-label"
                  id="match-type"
                  value={matchType}
                  onChange={matchTypeChange}
                  label={<Translate id="matches.match-type" />}
                >
                  <MenuItem value="">
                    <Translate id="statisticsfilter.select-match-type" />
                  </MenuItem>
                  {MATCH_TYPES.map((element) => (
                    <MenuItem key={element.type} value={element.type}>
                      {element.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item style={{ marginLeft: 'auto' }} sm={1}>
            <Box textAlign="right" p={1.6}>
              <Tooltip title={<Translate id="button.clear-all" />} arrow>
                <IconButton onClick={clearAll} color="primary" size="small">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>{' '}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
StatisticsFilter.defaultProps = {
  muiSM1: 5,
  muiMD1: 12,
  muiSM2: 2,
  tab: null,
  matchType: '',
  filterDate: {
    startDate: null,
    endDate: null,
  },
}

StatisticsFilter.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  muiSM1: PropTypes.number,
  muiSM2: PropTypes.number,
  muiMD1: PropTypes.number,
  tab: PropTypes.string,
  matchType: PropTypes.string,
  setMatchType: PropTypes.func.isRequired,
  filterDate: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  setFilterDate: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(({ auth }) => ({
    dateFormat: auth.user.datetime_format,
  }))
)(StatisticsFilter)
