import React from 'react'
import { Box, Paper } from '@material-ui/core'
import ActivitiesContainer from 'containers/ActivitiesContainer'
import Navigation from './components/Navigation'

const ActivitiesPage = () => {
  return (
    <Paper
      component={Box}
      elevation={8}
      style={{
        padding: '10px 30px 30px 30px',
      }}
    >
      <Navigation />
      <ActivitiesContainer />
    </Paper>
  )
}

export default ActivitiesPage
