import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { trainings as trainingsModule } from 'redux/modules'
import { makeStyles, styled } from '@material-ui/core/styles'
import {
  CardContent,
  Card,
  CardHeader,
  Typography,
  Button,
  IconButton,
  Hidden,
} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import UserActionDialog from 'components/material/Calender/UserActionDialog'
import { SessionCategory, SessionCategoryMobile } from './components'
import { SessionPartWrapper } from './styledComponents'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1, 0),
  },
  header: {
    paddingBottom: 0,
  },
  duration: {
    marginBottom: 2,
    fontSize: 16,
  },
  sessionWrap: {
    maxHeight: '500px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '320px',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '276px',
    },
  },
}))

const SessionWrapScroll = styled('div')({
  maxHeight: '500px',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    maxHeight: '320px',
  },
  '@media (max-width: 360px)': {
    maxHeight: '276px',
  },
})
const Session = ({
  handleSave,
  sessionDrills,
  changeSessionDrills,
  calculateDuration,
  totalDuration,
  isTrainingSeries,
  handleShareClick,
}) => {
  const filterDrillsByCategory = (category) =>
    sessionDrills.filter((drill) => drill.session_part === category)

  const removeItem = useCallback(
    (removedId) => {
      const targetItemIndex = sessionDrills.findIndex(
        ({ id }) => id === removedId
      )

      changeSessionDrills(
        sessionDrills
          .map((item, index) => (index !== targetItemIndex ? item : null))
          .filter(Boolean)
      )
    },
    [sessionDrills]
  )
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const handleSaveConfirmation = () => {
    if (isTrainingSeries) {
      setIsDialogOpen(true)
    } else {
      handleSave('SINGLE')
    }
  }
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        className={classes.header}
        title={
          <Typography variant="h4" component="h4">
            <Translate id="training.session-summary" />
          </Typography>
        }
        action={
          <IconButton onClick={handleShareClick}>
            <ShareIcon fontSize="small" />
          </IconButton>
        }
      />
      <CardContent>
        <SessionWrapScroll>
          <SessionPartWrapper>
            <Translate id="training.warmup" />
            <Hidden only={['xs', 'sm']}>
              <SessionCategory
                relation="warmup"
                items={filterDrillsByCategory('warmup')}
                removeItem={removeItem}
                calculateDuration={calculateDuration}
              />
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}>
              <SessionCategoryMobile
                relation="warmup"
                items={filterDrillsByCategory('warmup')}
                removeItem={removeItem}
                calculateDuration={calculateDuration}
              />
            </Hidden>
          </SessionPartWrapper>
          <SessionPartWrapper>
            <Translate id="training.main_part" />
            <Hidden only={['xs', 'sm']}>
              <SessionCategory
                relation="main_part"
                items={filterDrillsByCategory('main_part')}
                removeItem={removeItem}
                calculateDuration={calculateDuration}
              />
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}>
              <SessionCategoryMobile
                relation="main_part"
                items={filterDrillsByCategory('main_part')}
                removeItem={removeItem}
                calculateDuration={calculateDuration}
              />
            </Hidden>
          </SessionPartWrapper>
          <SessionPartWrapper>
            <Translate id="training.final_part" />
            <Hidden only={['xs', 'sm']}>
              <SessionCategory
                relation="final_part"
                items={filterDrillsByCategory('final_part')}
                removeItem={removeItem}
                calculateDuration={calculateDuration}
              />
            </Hidden>
            <Hidden only={['xl', 'lg', 'md']}>
              <SessionCategoryMobile
                relation="final_part"
                items={filterDrillsByCategory('final_part')}
                removeItem={removeItem}
                calculateDuration={calculateDuration}
              />
            </Hidden>
          </SessionPartWrapper>
        </SessionWrapScroll>
        <Typography className={classes.duration}>
          <Translate id="training.training-duration" />: <b>{totalDuration}'</b>
        </Typography>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSaveConfirmation}
          className={classes.button}
          size="large"
        >
          <Translate id="button.save" />
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          size="large"
          onClick={handleShareClick}
        >
          <Translate id="button.download-as-pdf" />
        </Button>
      </CardContent>
      <UserActionDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onActionOne={() => {
          handleSave('SINGLE')
          setIsDialogOpen(false)
        }}
        onActionTwo={() => {
          handleSave('SINGLE_AND_FOLLOWING')
          setIsDialogOpen(false)
        }}
        title={<Translate id="training.update" />}
        subTitle={<Translate id="training.update-option" />}
        buttonOneLabel={<Translate id="training.update-this" />}
        buttonTwoLabel={<Translate id="training.update-this-following" />}
      />
    </Card>
  )
}

Session.defaultProps = {
  sessionDrills: [],
  calculateDuration: null,
}

Session.propTypes = {
  handleSave: PropTypes.func.isRequired,
  sessionDrills: PROP_TYPES.arrayOfObjects,
  changeSessionDrills: PropTypes.func.isRequired,
  totalDuration: PropTypes.string.isRequired,
  calculateDuration: PropTypes.func,
}

export default compose(
  withRouter,
  connect(null, {
    deleteTraining: trainingsModule.deleteTraining,
  })
)(Session)