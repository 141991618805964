import React from 'react'
import { connect } from 'react-redux'
import { SubLink, HeartIcon, ShareMuiIcon } from 'containers'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Card,
  Grid,
  Box,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { articles as articlesModule } from 'redux/modules'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLE_ROUTE } from 'constants/routes'

const MuiBox = withStyles(theme => ({
  root: {
    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)

const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    height: 50,
  },
  media: {
    height: 140,
    backgroundPosition: 'top',
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const ArticleItem = ({ article, isFavorite, shareArticle }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <MuiCard>
        <CardActionArea
          component={SubLink}
          to={ARTICLE_ROUTE.populateURLParams({ articleId: article.id })}
        >
          <CardMedia className={classes.media} image={article.thumb_url} />
          <CardContent>
            <Typography
              gutterBottom
              className={classes.descriptionCell}
              variant="h4"
              component="h4"
            >
              {article.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <div className={classes.title}>
            <MuiBox>
              {article.reading_time && (
                <Box>
                  {' '}
                  <Typography variant="span" component="span">
                    <AccessTimeIcon color="disabled" />
                  </Typography>{' '}
                  {`${article.reading_time}`} min
                </Box>
              )}
            </MuiBox>
          </div>
          <HeartIcon
            isActive={isFavorite}
            entity="articles"
            entityId={article.id}
            isPositionRelative
          />
          <ShareMuiIcon
            share={shareArticle}
            id={article.id}
            shareBy="Article"
          />
        </CardActions>
      </MuiCard>
    </Grid>
  )
}

ArticleItem.defaultProps = {
  isFavorite: false,
}

ArticleItem.propTypes = {
  article: PROP_TYPES.shape().isRequired,
  isFavorite: PROP_TYPES.bool,
  shareArticle: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    shareArticle: articlesModule.shareArticle,
  }
)(ArticleItem)
