let userlaneIdentified = false
let userlaneInitialized = false

const userlaneIdentify = (userId, locale, clubId, teamId, paid, country) => {
  if (window.Userlane) {
    if (!userlaneIdentified) {
      // eslint-disable-next-line no-undef
      Userlane('lang', locale)

      // eslint-disable-next-line no-undef
      Userlane('identify', userId, {
        team_id: teamId,
        club_id: clubId,
        paid,
        // plan,
        country,
        device_type: window.innerWidth <= 768 ? 'mobile' : 'desktop'
      })

      userlaneIdentified = true
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Userlane not loaded.')
  }
}

const userlaneInit = () => {
  if (window.Userlane) {
    if (!userlaneInitialized) {
      // eslint-disable-next-line no-undef
      Userlane('init', 'ro88z')

      userlaneInitialized = true
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Userlane not loaded.')
  }
}

export {
  userlaneIdentify,
  userlaneInit
}
