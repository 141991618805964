import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Typography } from '@material-ui/core'
import { SubLink } from 'containers'
import { DataGrid } from '@mui/x-data-grid'
import DataGridExportToolbar from 'components/DataGridExportToolbar'
import { connect } from 'react-redux'
import { fetchTeamPlayers } from 'containers/TeamPlayersData/api'
import { LoadingSpinner } from 'components'
import { Avatar, Player } from '../style/styledComponents'
import { PLAYER_POSITIONS } from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

const PlayersSkills = ({ teamId }) => {
  const [rows, setRows] = useState([])
  const [skillHeaders, setSkillHeaders] = useState([])
  const [loading, setLoading] = useState(false)

  // Render Avatar and full name of player
  const renderAvatarWithName = (params) => (
    <Player
      component={SubLink}
      to={`${TEAM_DASHBOARD_ROUTE}/player/${params.row.uuid}/overview`}
    >
      {params.row.avatar?.full_url !== null ? (
        <Avatar alt={params.row.first_name} src={params.row.avatar?.full_url} />
      ) : (
        <Avatar alt={params.row.first_name} />
      )}
      <div>
        <Typography variant="h4">
          {`${params.row.first_name} ${params.row.last_name}`}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          {PLAYER_POSITIONS.find((p) => p.value === params.row.position).title}
        </Typography>
      </div>
    </Player>
  )
  
  const column = [
    {
      field: 'uuid',
      headerName: 'UUID',
      headerAlign: 'left',
      headerClassName: 'primary',
      align: 'left',
      width: 120,
      hide: true,
      editable: true,
    },
    {
      field: 'avatar',
      headerName: <Translate id="events.table-player" />,
      width: 300,
      renderCell: renderAvatarWithName,
      editable: false,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 300,
      editable: false,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      hide: true,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 300,
      editable: false,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      hide: true,
    },
  ]

  useEffect(() => {
    setLoading(true)
    fetchTeamPlayers(teamId, { fields: 'skills' }).then((res) => {
      const playersData = res.data
      const tempSkillHeaders = []
      const playerData = playersData[0] // Take any player and fetch skills to create table headers

      // Prepare Skills headers array with other grid options
      playerData.skills.forEach((skill) => {
        tempSkillHeaders.push({
          uuid: skill.uuid,
          name: `${skill.name}`,
          disablePadding: false,
          numeric: true,
          field: `${skill.name}`,
          headerName: `${skill.name}`,
          headerAlign: 'left',
          headerClassName: 'primary',
          align: 'left',
          width: 150,
          type: 'number',
        })
      })
      setSkillHeaders(tempSkillHeaders)

      const tempRows = [...playersData]
      // Map skill with skill name as key to bind in DataGrid
      tempRows.forEach((player) => {
        player.skills.map((skill) => {
          player[skill.name] = skill.value

          return player
        })
      })
      setRows([...tempRows])

      setLoading(false)
    })
  }, [])

  const skillsFieldsName = skillHeaders.map((skill) => {
    return skill.field
  })
  // Add First name and Last name as starting columns for CSV export
  skillsFieldsName.unshift('first_name', 'last_name')

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div style={{ height: 'auto', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={[...column, ...skillHeaders]}
            pageSize={100}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            autoHeight
            components={{
              Toolbar: DataGridExportToolbar,
            }}
            componentsProps={{
              toolbar: { csvOptions: { fields: skillsFieldsName } },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'first_name', sort: 'asc' }],
              },
            }}
          />
        </div>
      )}
    </>
  )
}

PlayersSkills.defaultProps = {}

PlayersSkills.propTypes = {
  teamId: PropTypes.number.isRequired,
}

export default connect(
  ({ team }) => ({
    teamId: team.id,
  }),
  {}
)(PlayersSkills)
