
/**
 * 
 *  @todo Check usage and replace with redux store and then remove context
 * 
 */

import React, { createContext, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import filterObject from 'utils/filterObject'
import { sortBy } from 'lodash'
import PROP_TYPES from 'constants/propTypes'

const createNormalizedPlayers = (players) => {
  const normalizedPlayers = players.map((item) => ({
    id: item.id,
    name: `${item.first_name} ${item.last_name ? item.last_name : ''}`,
    notAvailableReason: item.not_available_reason,
    uuid: item.uuid,
  }))

  return sortBy(normalizedPlayers, ({ relation }) => relation)
}
const createNormalizedMatchPlayers = (matchPlayers) => {
  const normalizedMatchPlayers = matchPlayers.map((item) => ({
    id: item.uuid,
    uuid: item.uuid,
    in_roster: item.in_roster,
    notes: item.notes,
    rating: item.rating,
    participant: item.participant,
    playing_minutes: item.playing_minutes,
    position: item.relationships.player.position,
    sub_position: item.relationships.player.sub_position,
    position_x: item.position_x,
    position_y: item.position_y,
    tag: item.tag,
    notAvailableReason: item.reason,
    avatar: item.relationships.player?.relationships.avatar,
    avatar_image: item.relationships.player?.relationships.avatar?.full_url,
    name: `${item.relationships.player.first_name} ${item.relationships.player.last_name}`,
    first_name: item.relationships.player.first_name,
    last_name: item.relationships.player.last_name,
    number: item.relationships.player.number,
    playerUuid: item.relationships.player.uuid,
  }))

  return normalizedMatchPlayers
}

const STATE_FIELDS = [
  'location',
  'date',
  'lineup_id',
  'notes_backup',
  'opponent',
  'goals_scored',
  'goals_received',
  'goals',
  'highlights',
  'status',
  'home',
  'id',
  'allnotes',
  'type',
  'tickers,',
  'street',
  'city',
  'country',
  'postal_code',
  'lat',
  'lng',
  'short_address',
  'state',
  'additional_info',
  'meeting_time',
  'end_time',
]

const PLAYERS_FIELDS = [
  'id',
  'uuid',
  'in_roster',
  'participant',
  'position_x',
  'position_y',
  'rating',
  'notes',
  'tag',
  'position',
  'playing_minutes',
  'first_name',
  'last_name',
  'avatar',
]

export const Context = createContext({})

const MatchStateContext = ({ children, currentMatch }) => {
  const [matchState, changeMatchState] = useState({})
  const [anyChange, setAnyChange] = useState(false)

  useEffect(() => {
    const newMatchState = {
      ...filterObject(
        currentMatch,
        () => true,
        (key) => STATE_FIELDS.includes(key)
      ),
      players: currentMatch.players.map((player) =>
        filterObject(
          player,
          () => true,
          (key) => PLAYERS_FIELDS.includes(key)
        )
      ),
      matchPlayers: createNormalizedMatchPlayers(currentMatch.match_players),
      normalizedPlayers: createNormalizedPlayers(currentMatch.players),
      tickers: currentMatch.tickers,
      matchUuid: currentMatch.uuid,
      availability_cutoff_time: currentMatch.availability_cutoff_time,
    }
    changeMatchState(newMatchState)
  }, [currentMatch.id])

  const onFieldChange = useCallback(
    (fieldName, newValue) => {
      if (fieldName !== 'allnotes') {
        changeMatchState({ ...matchState, [fieldName]: newValue })
        setAnyChange(true)
      }
    },
    [matchState]
  )
  const allFieldChange = useCallback(
    (data) => {
      changeMatchState({ ...matchState, ...data })
      setAnyChange(true)
    },
    [matchState]
  )

  return (
    <Context.Provider
      value={{
        matchState,
        onFieldChange,
        changeMatchState,
        setAnyChange,
        anyChange,
        allFieldChange,
        currentMatch,
      }}
    >
      {children}
    </Context.Provider>
  )
}

MatchStateContext.propTypes = {
  children: PROP_TYPES.children.isRequired,
  currentMatch: PROP_TYPES.shape().isRequired,
}

export default connect(({ matches: { current } }) => ({
  currentMatch: current,
}))(MatchStateContext)
