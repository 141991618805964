import React from 'react'
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Switch,
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { SubLink } from 'containers'
import { Translate } from 'react-localize-redux'
import binIcon from 'assets/images/16px/bin@1x.svg'
import editIcon from 'assets/images/16px/edit@1x.svg'
import { formatCostumDateMonthNoForUI } from 'utils/formatDate'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  link:{
    display:'block',
    cursor:'pointer'
  }
})
const TaskGroupEventsCollapsibleRow = ({
  taskGroup,
  players,
  handleOpen,
  openAlertBox,
  handleAlertOpenForCompleteTask
}) => {
  const [open, setOpen] = React.useState(false)

  const classes = useRowStyles()

  const renderCategory = (tempTaskGroup) => {
    let taskType = ''
    if (
      tempTaskGroup?.event_type?.includes(TRAININGS_GAME.training_camel_case)
    ) {
      taskType = TRAININGS_GAME.training
    } else if (
      tempTaskGroup?.event_type?.includes(TRAININGS_GAME.match_camel_case)
    ) {
      taskType = TRAININGS_GAME.match
    }
    switch (taskType) {
      case TRAININGS_GAME.training:
        return (
          <Chip
            label={<Translate id="menu.training" />}
            color="primary"
          />
        )
      case TRAININGS_GAME.match:
        return (
          <Chip
            label={<Translate id="menu.match-day" />}
            color="secondary"
          />
        )
      default:
        return (
          <Chip
            label={<Translate id="task-management.general-cat" />}
          />
        )
    }
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <SubLink to={`/task-group/${taskGroup.id}`} className={classes.link} hash={ taskGroup?.events?.length === 1 ?`#${taskGroup?.events[0].id}` : null}>
            {taskGroup.title}
          </SubLink>
        </TableCell>
        <TableCell><SubLink to={`/task-group/${taskGroup.id}`} className={classes.link} hash={ taskGroup?.events?.length === 1 ?`#${taskGroup?.events[0].id}` : null}>{renderCategory(taskGroup)} </SubLink></TableCell>
        <TableCell>
        <SubLink to={`/task-group/${taskGroup.id}`} className={classes.link} hash={ taskGroup?.events?.length === 1 ?`#${taskGroup?.events[0].id}` : null}>
          <AvatarGroup>
            {taskGroup?.model_ids.map((model) =>
              players
                .filter((player) => player.id === +model)
                .map((player) => (
                  <Avatar
                    key={player.id}
                    alt={player.first_name}
                    src={player.avatar?.full_url}
                  />
                ))
            )}
          </AvatarGroup>
          </SubLink>
        </TableCell>
        <TableCell>
          {!taskGroup.completed && <Tooltip title={<Translate id="button.edit" />} placement="top" arrow>
            <IconButton onClick={() => handleOpen(taskGroup)} disabled={taskGroup.completed}>
              <img src={editIcon} alt={editIcon} />
            </IconButton>
          </Tooltip>}

          <Tooltip
            title={<Translate id="button.delete" />}
            placement="top"
            arrow
          >
            <IconButton onClick={() => openAlertBox(taskGroup.id)}>
              <img src={binIcon} alt={binIcon} />
            </IconButton>
          </Tooltip>

          {!taskGroup.completed && <Tooltip
            title={<Translate id="button.complete-task" />}
            placement="top"
            arrow
          >
            <Switch
              checked={taskGroup.completed}
              onChange={() => handleAlertOpenForCompleteTask(taskGroup.id)}
              color="primary"
              name="completeTask"
              disabled={taskGroup.completed}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Tooltip>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {taskGroup.event_type ? (
              <Box margin={1.8}>
                <Typography variant="h6" component="h6" style={{marginBottom: '0.5rem'}}>
                  <Translate id="task-management.event-details" />
                </Typography>
                <Table aria-label="events-details" stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="15%">
                        <Translate id="button.event" />
                      </TableCell>
                      <TableCell width="15%">
                        <Translate id="task-management.event-date" />
                      </TableCell>
                      <TableCell width="15%">
                        <Translate id="task-management.due-date" />
                      </TableCell>
                      <TableCell>
                        <Translate id="training.location" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskGroup.events.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell component="th" scope="row">
                          <SubLink
                            to={`/task-group/${taskGroup.id}`}
                            hash={`#${event.id}`}
                          >
                            {event.event_type}
                          </SubLink>
                        </TableCell>
                        <TableCell>
                          {formatCostumDateMonthNoForUI(event.date)}
                        </TableCell>
                        <TableCell>
                          {formatCostumDateMonthNoForUI(event.due_date)}
                        </TableCell>
                        <TableCell>{event.location}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Typography variant="h5" component="h5" style={{margin: '1rem'}}>
                <Table aria-label="events-details" stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%">
                        <Translate id="task-management.task-date" />
                      </TableCell>
                      <TableCell width="5%">
                        <Translate id="task-management.due-date" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskGroup.events.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>
                        <SubLink
                            to={`/task-group/${taskGroup.id}`}
                            hash={`#${event.id}`}
                          >
                          {formatCostumDateMonthNoForUI(event.created_at)}
                          </SubLink>
                        </TableCell>
                        <TableCell>
                          {formatCostumDateMonthNoForUI(event.due_date)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Typography>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
TaskGroupEventsCollapsibleRow.propTypes = {
  taskGroup: PROP_TYPES.shape().isRequired,
  players: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  handleOpen: PROP_TYPES.func.isRequired,
  openAlertBox: PROP_TYPES.func.isRequired,
  handleAlertOpenForCompleteTask: PROP_TYPES.func.isRequired,
}
export default TaskGroupEventsCollapsibleRow
