/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { Card, DatePicker, TimePicker } from 'components'
import {
  formatDateFromServer,
  formatDateTimeToServer,
  formatCostumDateMonthNoForUI,
  getHourMinute,
} from 'utils/formatDate'
import { styled } from '@material-ui/core/styles'
import ScheduleIcon from '@material-ui/icons/Schedule'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import CloseIcon from '@material-ui/icons/Close'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import editIcon from 'assets/images/16px/edit@1x.svg'
import Description from '@material-ui/icons/Description'
import Rating from '@material-ui/lab/Rating'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import {
  DialogContent,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  Grid,
  Link,
  Divider,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import { connect } from 'react-redux'
import { compose } from 'redux'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus'
import OfflineBolt from '@material-ui/icons/OfflineBolt'
import { Context } from '../TrainingStateContext'
import PROP_TYPES from 'constants/propTypes'
import UserActionDialog from 'components/material/Calender/UserActionDialog'
import InfoIcon from '@material-ui/icons/Info'

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: '18px',
    paddingBottom: '18px',
    textAlign: 'left',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  select: {
    paddingRight: 0,
  },
  infoIcon: {
    position: 'absolute',
    top: 20,
    right: 25,
  },
}))

const TrainingInfoSection = styled('div')(({ theme }) => ({
  '& .training-info': {
    color: '#a6a6a6',
    marginBottom: theme.spacing(1),
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    '& .MuiGrid-item': {
      display: 'flex',
    },
    '& span': {
      '& svg': {
        fontSize: '22px',
        marginRight: theme.spacing(1),
      },
    },
    '& .MuiRating-root': {
      marginLeft: theme.spacing(1),
    },
  },
}))

const SummaryCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  '& .form-group': {
    marginBottom: 0,
    minHeight: 'inherit',
  },
  '& .date-selector': {
    width: '100%',
    fontSize: 16,
    color: '#888',
    borderRadius: 15,
    boxShadow: `inset 0px 0 30px 2px rgba(190, 190, 190, 0.7)`,
    background: '#fff',
    '&:active, &:focus': {
      borderColor: '#000000',
      boxShadow: `inset 0px 0 30px 2px rgba(190, 190, 190, 0.5)`,
    },
  },
  '& .time-selector, & .place-selector': {
    margin: `0 ${theme.spacing(2)}px`,
    width: '80%',
    fontSize: 16,
    color: '#888',
    borderRadius: 15,
    textTransform: 'capitalize',
    boxShadow: `inset 0px 0 30px 2px rgba(190, 190, 190, 0.7)`,
    background: '#fff',
    height: 40,
    padding: '6px 12px',
    fontWeight: 400,
    lineHeight: '1.5px',
    borderColor: 'var(--dark-silver)',
    borderWidth: 1.2,
    '&:active, &:focus': {
      borderColor: '#000000',
      boxShadow: `inset 0px 0 30px 2px rgba(190, 190, 190, 0.5)`,
    },
  },
  '& .place-selector': {
    textTransform: 'capitalize',
  },
  '@media only screen and (max-width: 640px)': {
    '& .date-selector, & .time-selector, & .place-selector': {
      margin: 0,
      textTransform: 'capitalize',
      width: '100%',
    },
  },
}))

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
  '& h4': {
    fontWeight: 600,
    fontSize: 14,
    color: '#062221',
    margin: 0,
  },
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  '& .MuiRating-root': {
    '& .MuiRating-label': {
      bottom: 2,
    },
  },
  '& .inputMultiline': {
    marginTop: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: 144,
    },
  },
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="medium"
          className={classes.closeButton}
        >
          <CloseIcon onClick={onClose} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const Summary = ({ handleSave, dateFormat }) => {
  const classes = useStyles()
  const [eventData, setEventData] = useState({
    date: '',
    end_time: '',
    location: '',
    street: '',
    city: '',
    country: '',
    postalCode: '',
    lat: '',
    lng: '',
    short_address: '',
  })

  const {
    trainingState: {
      date,
      location,
      street,
      city,
      country,
      postalCode,
      lat,
      lng,
      short_address,
      training_focus: trainingFocus,
      end_time,
      intensity,
      additional_info,
      series_uuid,
      availability_cutoff_time,
    },
    onFieldChange,
    allFieldChange,
  } = useContext(Context)
  const [intens, changeIntens] = useState(intensity)
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const onChangeRating = (newRating) => {
    changeIntens(newRating)
    onFieldChange('intensity', newRating)
  }
  const [dateDay, time] = formatDateFromServer(date)

  const [open, setOpen] = useState(false)

  const [eventTempData, setEventTempData] = useState([])
  const [cutoffTime, setCutoffTime] = useState(availability_cutoff_time)
  const [cutoffTimeOptions, setCutoffTimeOptions] = useState([0, 6, 12, 24])

  const handleClickOpen = () => {
    setOpen(true)
    setEventTempData(eventData)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCancel = () => {
    setOpen(false)
    allFieldChange(eventTempData)
    setEventData(eventTempData)
  }
  const handleTrainingFocus = (event) => {
    const {
      target: { value },
    } = { ...event }
    onFieldChange('training_focus', value)
  }
  const onDateChange = (value) => {
    setEventData({ ...eventData, date: value })
    onFieldChange('date', formatDateTimeToServer(value))
  }
  const onEndDateChange = (value) => {
    setEventData({ ...eventData, end_time: value })
    onFieldChange('end_time', formatDateTimeToServer(value))
  }
  const handleAdditionalInfo = (event) => {
    const {
      target: { value },
    } = { ...event }
    onFieldChange('additional_info', value)
  }
  const handleSaveConfirmation = () => {
    if (series_uuid) {
      setIsDialogOpen(true)
    } else {
      handleSave('SINGLE')
      handleClose()
    }
  }
  const handleCutoffTimeChange = (event) => {
    const value = event.target.value
    setCutoffTime(value)
    onFieldChange('availability_cutoff_time', value)
  }

  useEffect(() => {
    setEventData({
      ...eventData,
      date,
      location,
      street,
      city,
      country,
      postalCode,
      lat,
      lng,
      short_address,
      trainingFocus,
      end_time,
      intensity,
      additional_info,
      availability_cutoff_time,
    })
  }, [
    date,
    location,
    street,
    city,
    country,
    postalCode,
    lat,
    lng,
    short_address,
    trainingFocus,
    end_time,
    intensity,
    additional_info,
    availability_cutoff_time,
  ])

  return (
    <SummaryCard>
      <CardHeader>
        <Typography variant="h4">
          <Translate id="training.overview" />
        </Typography>
          <IconButton onClick={handleClickOpen}>
            <img src={editIcon} alt={editIcon} />
          </IconButton>
      </CardHeader>
      <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
        <DialogTitle onClose={handleCancel}>
          <Translate id="button.edit-training" />
        </DialogTitle>
        <Divider />
        <StyledDialogContent>
          <Grid alignItems="center">
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <DatePicker
                  required
                  label={<Translate id="training.date" />}
                  selectedValue={date}
                  onDateChange={onDateChange}
                  openTo="date"
                  isEditMode
                  dateFormat={dateFormat}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TimePicker
                  selectedValue={date}
                  onDateChange={onDateChange}
                  label={<Translate id="training.time" />}
                  isEditMode
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Translate>
                {({ translate }) => (
                  <PlacesSearchField
                    error={eventData.error_location}
                    helperText={
                      eventData.error_location
                        ? translate('events.validation.location')
                        : ''
                    }
                    defaultValue={eventData.location}
                    savedValue={eventData.location}
                    onFieldChange={allFieldChange}
                    changeFormData={setEventData}
                    eventData={eventData}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LocationOnIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Translate>
            </Grid>
            <Grid md={12}>
              <Translate>
                {({ translate }) => (
                  <TextField
                    defaultValue={trainingFocus}
                    label={translate('form.training-focus')}
                    name="trainingFocus"
                    margin="dense"
                    variant="outlined"
                    onChange={handleTrainingFocus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FilterCenterFocusIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Translate>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TimePicker
                  selectedValue={end_time}
                  onDateChange={onEndDateChange}
                  label={<Translate id="matches.end-time" />}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" margin="dense">
                  <InputLabel id="cutoff-time-label">
                    <Translate id="player.event-response.cutoff-time" />
                  </InputLabel>
                  <Select
                    value={cutoffTime}
                    onChange={handleCutoffTimeChange}
                    label={<Translate id="player.event-response.cutoff-time" />}
                    className={classes.select}
                    style={{
                      paddingRight: 0,
                    }}
                    endAdornment={
                      <InputAdornment
                        position="start"
                        className={classes.infoIcon}
                      >
                        <Tooltip
                          title={
                            <Translate id="player.event-response.info-message" />
                          }
                          placement="bottom-end"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    }
                  >
                    {cutoffTimeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option === 0 ? (
                          <Translate id="player.event-response.disabled" />
                        ) : (
                          <>
                            {option}{' '}
                            <Translate id="player.event-response.hours" />
                          </>
                        )}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Translate>
                {({ translate }) => (
                  <TextField
                    multiline
                    className="inputMultiline"
                    rows={5}
                    maxRows={5}
                    minRows={5}
                    defaultValue={additional_info}
                    label={translate('matches.additional-info')}
                    name="trainingFocus"
                    variant="outlined"
                    onChange={handleAdditionalInfo}
                  />
                )}
              </Translate>
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" className="pt-2">
                <Translate id="training.intensity" />
              </Typography>
              <Rating
                size="medium"
                value={intens}
                precision={0.5}
                onChange={(e, v) => onChangeRating(v)}
              />
              {intens > 0 && (
                <Button
                  color="secondary"
                  size="small"
                  style={{ float: 'right' }}
                  variant="outlined"
                  onClick={() => onChangeRating(0)}
                >
                  Reset <Translate id="training.intensity" />
                </Button>
              )}
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                margin="dense"
                fullWidth
                style={{ marginTop: 10 }}
                onClick={handleSaveConfirmation}
              >
                <Translate id="button.save" />
              </Button>
            </Grid>
          </Grid>
        </StyledDialogContent>
      </Dialog>
      <TrainingInfoSection>
        <div className="training-info">
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ display: 'flex' }}>
              <span>
                <CalendarTodayOutlinedIcon />
              </span>
              {formatCostumDateMonthNoForUI(dateDay, dateFormat)}
            </Grid>
            <Grid item xs={6} style={{ display: 'flex' }}>
              <span>
                <ScheduleIcon />
              </span>
              {time}
            </Grid>
            <Grid item xs={6}>
              <span>
                <ScheduleIcon />
              </span>
              {end_time && getHourMinute(end_time)}
            </Grid>
            <Grid item xs={6}>
              <span>
                <FilterCenterFocusIcon />
              </span>
              {trainingFocus}
            </Grid>
            <Grid item xs={6}>
              <span>
                <HourglassEmptyOutlinedIcon />
              </span>
              {availability_cutoff_time === 0 ? (
                <Translate id="player.event-response.disabled" />
              ) : (
                <>
                  {availability_cutoff_time}{' '}
                  <Translate id="player.event-response.hours" />
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <span>
                <LocationOnOutlinedIcon />
              </span>
              <Link
                href={`https://maps.google.com/?q=${lat},${lng}`}
                target="_blank"
              >
                {short_address || ''}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <span>
                <Description />
              </span>
              {additional_info}
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <span style={{ display: 'flex' }}>
                <OfflineBolt fontSize="small" style={{ color: 'gray' }} />{' '}
                <Translate id="training.intensity" />
              </span>
              <Rating size="small" value={intensity} precision={0.5} readOnly />
            </Grid>
          </Grid>
          <UserActionDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onActionOne={() => {
              handleSave('SINGLE')
              setIsDialogOpen(false)
              handleClose()
            }}
            onActionTwo={() => {
              handleSave('SINGLE_AND_FOLLOWING')
              setIsDialogOpen(false)
              handleClose()
            }}
            title={<Translate id="training.update" />}
            subTitle={<Translate id="training.update-option" />}
            buttonOneLabel={<Translate id="training.update-this" />}
            buttonTwoLabel={<Translate id="training.update-this-following" />}
          />
        </div>
      </TrainingInfoSection>
    </SummaryCard>
  )
}

Summary.defaultProps = {}

Summary.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ users, auth }) => ({
      users,
      dateFormat: auth.user.datetime_format,
    }),
    null
  )
)(Summary)
