import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'USER'
const FETCH_USER = actionsTypesGenerator(`FETCH_${namespace}`)
const UPDATE_USER = actionsTypesGenerator(`UPDATE_${namespace}`)
const REVOKE_USER_ROLE = actionsTypesGenerator(`REVOKE_${namespace}_ROLE`)

export const fetchUser = () => ({
  types: FETCH_USER,
  callAPI: {
    method: 'GET',
    entity: 'User',
    path: `/user`,
  },
})

export const updateUser = data => ({
  types: UPDATE_USER,
  callAPI: {
    method: 'PATCH',
    entity: 'User',
    path: `/v2/users/${data.id}`,
    data,
  },
})

export const revokeUserRole = data => ({
  types: UPDATE_USER,
  callAPI: {
    method: 'DELETE',
    entity: 'User role',
    path: `${data.staffId ? `/staff/${data.staffId}`:`/players/${data.playerId}`}/user-roles/${data.id}`,
    data,
  },
})

export default handleActions(
  {
    [combineActions(FETCH_USER.success, UPDATE_USER.success, REVOKE_USER_ROLE.success)]: onRequest,
    [UPDATE_USER.success]: (state, { response: { data = {} } }) => {
      return onSuccess({
        ...state,
        current: data,
      })
    },
    [combineActions(FETCH_USER.success)]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        current: data,
      })
    },
    [combineActions(FETCH_USER.failure)]: onFailure,
    [combineActions(REVOKE_USER_ROLE.failure)]: onFailure,
  },
  initialState.users
)
