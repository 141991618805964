import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { SubLink } from 'containers'
import { PlayerStaffItem as PlayerItem } from 'components'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography, Tooltip } from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import InfoIcon from '@material-ui/icons/Info'
import { Icon, Tag, NewForLineup, PlayerAvailabiltyAndChangePlayerPopover } from './components'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'

const MuiBox = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})(Box)

const Player = (props) => {
  const {
    fromPathname,
    onChange,
    last_name: lastName,
    first_name: firstName,
    number,
    avatar_image: avatar,
    id: playerId,
    playing_minutes: playingMinutes,
    rating,
    notes,
    tag,
    sub_position: subPosition,
    handleSave,
    user_id: userId,
    notAvailableReason,
    hideAvailablityButton,
    hidePlayerChangePopover
  } = props

  const name = lastName ? lastName.charAt(0) : firstName.charAt(0)

  // team pages
  return (
    <PlayerItem.Container className="playerIconItem">
      {fromPathname !== 'Match' ? (
        <MuiBox
          component={SubLink}
          to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({ playerId })}
          fromPathname={fromPathname}
        >
          <PlayerAvailabiltyAndChangePlayerPopover
            rating={rating}
            notes={notes}
            playingMinutes={playingMinutes}
            handleMatchSave={handleSave}
            playerId={playerId}
            tag={tag}
            avatar={avatar}
            name={name}
            hidePlayerChangePopover={hidePlayerChangePopover}
          />
          <Typography component="h6" variant="h6">
            {avatar ? number : ''} {lastName || firstName}{' '}
            {userId ? (
              <Translate>
                {({ translate }) => (
                  <Tooltip title={translate('player.invited-player')}>
                    <VerifiedUserIcon fontSize="small" color="primary" />
                  </Tooltip>
                )}
              </Translate>
            ) : (
              <Translate>
                {({ translate }) => (
                  <Tooltip title={translate('player.not-invited-player')}>
                    <VerifiedUserIcon fontSize="small" color="error" />
                  </Tooltip>
                )}
              </Translate>
            )}
          </Typography>
        </MuiBox>
      ) : (
        <MuiBox>
          <PlayerAvailabiltyAndChangePlayerPopover
            rating={rating}
            notes={notes}
            playingMinutes={playingMinutes}
            handleMatchSave={handleSave}
            playerId={playerId}
            tag={tag}
            avatar={avatar}
            name={name}
            hidePlayerChangePopover={hidePlayerChangePopover}
          />
          <Typography component="h6" variant="h6">
            {lastName || firstName}
          </Typography>
          <Typography component="p" variant="span">
            {number} {subPosition}
            &nbsp;
            {notAvailableReason ? (
              <Tooltip title={notAvailableReason} placement="right">
                <InfoIcon
                  fontSize="small"
                  style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </Typography>
          <Box
            onClick={(e) => e.preventDefault()}
            role="presentation"
            hidden={hideAvailablityButton}
          >
            <Tag playerId={playerId} tag={tag} onChange={onChange} />
          </Box>
        </MuiBox>
      )}
    </PlayerItem.Container>
  )
}

Player.defaultProps = {
  fromPathname: '',
  onChange: null,
  first_name: null,
  last_name: null,
  notes: '',
  tag: '',
  sub_position: '',
  playing_minutes: 0,
  handleSave: null,
  user_id: '',
  notAvailableReason: '',
  hideAvailablityButton: false,
}

Player.propTypes = {
  fromPathname: PropTypes.string,
  onChange: PropTypes.func,
  last_name: PropTypes.string,
  first_name: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  avatar_image: PropTypes.node.isRequired,
  id: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  notes: PropTypes.string,
  tag: PropTypes.string,
  playing_minutes: PropTypes.number,
  sub_position: PropTypes.string,
  handleSave: PropTypes.func,
  user_id: PropTypes.number,
  notAvailableReason: PropTypes.string,
  hideAvailablityButton: PropTypes.bool,
}

// Player.CreateNew = CreateNew
Player.NewForLineup = NewForLineup
Player.Icon = Icon

export default Player
