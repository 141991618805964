import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import { PLAYER_TAGS } from 'constants/player'

const PlayerTags = ({ value: activeTagValue, onChange }) => (
  <>
    {PLAYER_TAGS.map(({ value, title: tagTitle }) => (
      <MenuItem
        key={value}
        size="small"
        variant="outlined"
        disabled={activeTagValue === 0}
        selected={activeTagValue === value}
        onClick={() =>
          activeTagValue === value ? onChange('available') : onChange(value)
        }
      >
        {tagTitle}
      </MenuItem>
    ))}
  </>
)

PlayerTags.defaultProps = {
  value: '',
}

PlayerTags.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default PlayerTags
