import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { ReactComponent as PlayerLogo } from 'assets/images/player-no-color.svg'

const PlayerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '10px 10px',
  width: '35px',
})

const PlayerImage = styled(PlayerLogo)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '35px',
  height: '35px',
})

const IconPlayerNumber = styled('div')({
  color: '#fff',
  fontSize: '18px',
  textShadow: '-1px 1px 3px rgba(0, 0, 0, 0.5)',
  width: '35px',
  textAlign: 'center',
  position: 'absolute',
  fontSize: '11px',
})

const Icon = ({ to, color, number }) => (
  <PlayerContainer to={to}>
    <PlayerImage stroke={color} />
    <IconPlayerNumber color={color}>{number}</IconPlayerNumber>
  </PlayerContainer>
)

Icon.defaultProps = {
  to: '',
  color: '#fff',
  number: '',
}

Icon.propTypes = {
  to: PropTypes.string,
  color: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Icon