import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { Player } from 'containers'

const StyleTeamContainer = styled('div')({
  padding: 16,
})

const PlayersList = ({
  players,
  className,
  WrapperComponent,
  fromPathname,
  onPlayerChange,
  handleSave,
  hidePlayerChangePopover
}) => (
  <StyleTeamContainer>
    <div className={className}>
      {players.sort((fel, sel) => {
        return fel.number > sel.number
          ? 1
          : -1
      }).map(player => {
        return !WrapperComponent ? (
          <div className="d-flex" key={player.id}>
            <Player
              key={player.id}
              onChange={onPlayerChange && onPlayerChange(player.id)}
              {...player}
              handleSave={handleSave}
            />
          </div>
        ) : (
          <WrapperComponent
            key={player.id}
            playerId={player.id}
            handleSave={handleSave}
          >
            <Player
              fromPathname={fromPathname}
              onChange={onPlayerChange && onPlayerChange(player.id)}
              {...player}
              handleSave={handleSave}
              hidePlayerChangePopover={hidePlayerChangePopover}
            />
          </WrapperComponent>
        )
      })}
    </div>
  </StyleTeamContainer>
)

PlayersList.defaultProps = {
  players: [],
  WrapperComponent: null,
  fromPathname: '',
  onPlayerChange: null,
  handleSave: null,
  hidePlayerChangePopover: false
}

PlayersList.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  className: PropTypes.string.isRequired,
  WrapperComponent: PropTypes.func,
  fromPathname: PropTypes.string,
  onPlayerChange: PropTypes.func,
  handleSave: PropTypes.func,
  hidePlayerChangePopover: PropTypes.bool
}

export default styled(PlayersList)({
  display: 'flex',
  flexFlow: 'row wrap',
  marginBottom: 15,

  '&:last-child': {
    marginBottom: 0,
    '& div .playerIconItem': {
      minWidth: '80px !important',
    },
  },
})
