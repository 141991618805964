import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  Typography,
  Breadcrumbs,
  Hidden,
  IconButton,
  Divider,
} from '@material-ui/core'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CloseIcon from '@material-ui/icons/Close'
import ImagesSlider from '../../ImagesSlider'
import PhaseMenu from './PhaseMenu'
import { DialogActions, DialogTitle, Toolbar } from './StyledComponents'
import DrillBody from './DrillBody'

const DrillViewDialog = ({
  drill,
  open,
  hanldeOpenClose,
  withPhaseMenu,
  handlePhaseSelect,
}) => {
  return (
    <Dialog open={open} onClose={hanldeOpenClose} fullWidth maxWidth="xl">
      <DialogTitle id="alert-dialog-title">
        {drill.title}
        <IconButton aria-label="close" size="small" onClick={hanldeOpenClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Toolbar>
        <Grid container spacing={1}>
          <Hidden only={['xs', 'sm']}>
            <Grid item md={8}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">
                  <PersonAddOutlinedIcon />
                  {drill.relationships.age_classes
                    .map((ageClass) => ageClass.name)
                    .join(', ')}
                </Typography>
                <Typography color="inherit">
                  <PeopleOutlineIcon />
                  {drill.max_players > 0
                    ? `${drill.min_players} - ${drill.max_players}`
                    : `min ${drill.min_players}`}
                </Typography>
                <Typography color="inherit">
                  <AccessTimeIcon />
                  {typeof drill.duration === 'number' &&
                    drill.duration !== null &&
                    `${drill.duration} min`}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Hidden>
          <Grid item md={4}>
            {withPhaseMenu && (
              <PhaseMenu withText onPhaseSelect={handlePhaseSelect} />
            )}
          </Grid>
          <Hidden only={['xl', 'lg', 'md']}>
            <Grid item xs={12}>
              {drill.relationships.images.length > 0 && (
                <ImagesSlider images={drill.relationships.images} />
              )}
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
      <DrillBody drill={drill} />
      <Hidden only={['xl', 'lg', 'md']}>
        <DialogActions>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">
              <PersonAddOutlinedIcon />
              {drill.relationships.age_classes
                .map((ageClass) => ageClass.name)
                .join(', ')}
            </Typography>
            <Typography color="inherit">
              <PeopleOutlineIcon />
              {drill.max_players > 0
                ? `${drill.min_players} - ${drill.max_players}`
                : `min ${drill.min_players}`}
            </Typography>
            <Typography color="inherit">
              <AccessTimeIcon />
              {typeof drill.duration === 'number' &&
                drill.duration !== null &&
                `${drill.duration} min`}
            </Typography>
          </Breadcrumbs>
        </DialogActions>
      </Hidden>
    </Dialog>
  )
}

DrillViewDialog.defaultProps = {
  drill: {
    min_players: 0,
    duration: null,
    relationships: {
      images: [],
    },
  },
  withPhaseMenu: false,
}
DrillViewDialog.propTypes = {
  drill: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          full_url: PropTypes.string.isRequired,
        })
      ),
      age_classes: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }),
    duration: PropTypes.number,
    min_players: PropTypes.number,
    max_players: PropTypes.number.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  hanldeOpenClose: PropTypes.func.isRequired,
  withPhaseMenu: PropTypes.bool,
  handlePhaseSelect: PropTypes.func.isRequired,
}

export default DrillViewDialog
