import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  Box,
  Typography,
  Avatar,
  DialogTitle as MuiDialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import ImageUploading from 'react-images-uploading'
import EditIcon from '@material-ui/icons/Edit'
import BallIcon from 'assets/images/ballicon.svg'
import {
  Close as CloseIcon,
  PhotoLibraryOutlined as PhotoLibraryOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@material-ui/icons'
import { Field, reduxForm } from 'redux-form'
import { Input } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import Joi from 'joi'
import createJoiValidator from 'utils/createJoiValidator'
import SelectList from 'components/inputes/SelectList'
import { connect } from 'react-redux'
import { compose } from 'redux'
import CircularSpinner from 'components/loader/CircularSpinner'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import countries from 'constants/countries'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}))
const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 1.4, 1, 3),
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      marginLeft: 'auto',
    },
  },
})
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const validationSchema = Joi.object().keys({
  name: Joi.string()
    .max(255)
    .required()
    .label(getTranslatedLabelForFormField('myteams.team-name')),
  country: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('team.country')),
  league: Joi.string()
    .max(255)
    .required()
    .label(getTranslatedLabelForFormField('team.league')),
  age_class: Joi.number()
    .required()
    .label(getTranslatedLabelForFormField('team.age-class')),
  gender: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('team.gender')),
})

const TeamEditForm = ({
  open,
  onClose,
  handleSubmit,
  ageClasses,
  initialValues,
  images,
  handleImageChange,
  teamsState,
}) => {
  const maxNumber = 1
  const maxMbFileSize = 5 * 1024 * 1024 // 5Mb
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      {teamsState.isLoading && <CircularSpinner />}
      <DialogTitle onClose={onClose}>
        <Translate id="myteams.edit-team" />
      </DialogTitle>
      <DialogContent dividers>
        <div className="profileContent">
          <Box className={classes.root}>
            {images.length === 0 && (
              <Avatar
                variant="square"
                alt="logo"
                className={classes.large}
                src={
                  initialValues.logo ? initialValues.logo.full_url : BallIcon
                }
              />
            )}
          </Box>
          <ImageUploading
            value={images}
            onChange={handleImageChange}
            maxNumber={maxNumber}
            maxFileSize={maxMbFileSize}
            acceptType={['jpg', 'gif', 'png', 'jpeg', 'jfif', 'pjpeg', 'pjp']}
            dataURLKey="data_url"
          >
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove }) => (
              // write your building UI
              <div className="changeImgContent">
                <div className="uploadImg">
                  {imageList.length === 0 && (
                    <IconButton onClick={onImageUpload}>
                      <EditIcon />
                    </IconButton>
                  )}
                </div>

                {imageList.map((image, index) => (
                  <div className="newImgUpload" key={index}>
                    <img alt="" src={image.data_url} />
                    <IconButton onClick={() => onImageUpdate(index)}>
                      <PhotoLibraryOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={() => onImageRemove(index)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
        <Typography variant="h4" component="h4" gutterBottom>
          <Translate id="team.general-info" />
        </Typography>
        <Field
          name="name"
          label={<Translate id="myteams.team-name" />}
          component={Input}
          margin="dense"
          required
        />
        <Field
          name="country"
          component={SelectList}
          label={<Translate id="team.country" />}
          id="country"
          required
        >
          {countries
            .filter((p) => p.value !== '')
            .map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
        </Field>
        <Field
          name="league"
          label={<Translate id="team.league" />}
          component={Input}
          margin="dense"
          required
        />
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Field
              name="age_class"
              component={SelectList}
              label={<Translate id="team.age-class" />}
              id="age_class"
              required
            >
              {ageClasses.length > 0 &&
                ageClasses.map((ageClass) => (
                  <MenuItem key={ageClass.id.toString()} value={ageClass.id}>
                    {ageClass.name}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item md={6}>
            <Field
              name="gender"
              component={SelectList}
              label={<Translate id="team.gender" />}
              id="gender"
              required
            >
              <MenuItem value="f">
                <Translate id="form.female" />
              </MenuItem>
              <MenuItem value="m">
                <Translate id="form.male" />
              </MenuItem>
            </Field>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} fullWidth>
          <Translate id="button.cancel" />
        </Button>
        <Button
          fullWidth
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={teamsState.isLoading}
        >
          <Translate id="button.save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TeamEditForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ageClasses: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  initialValues: PropTypes.shape().isRequired,
  images: PropTypes.shape().isRequired,
  handleImageChange: PropTypes.func.isRequired,
  teamsState: PropTypes.shape().isRequired,
}

export default compose(
  withLocalize,
  connect(
    ({ teams }) => ({
      teamsState: teams,
    }),
    {}
  ),
  reduxForm({
    form: 'teamUpdateForm',
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  })
)(TeamEditForm)
