import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { Paper, Tabs, Tab, Toolbar, Button, Box } from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import hasSubscription from 'utils/hasSubscription'
import { useStyles } from './components/style/styled'

import {
  PlayersSkills,
  PlayersStatistics,
  PlayersAttendance,
  PlayersPersonalData,
} from './components'
import { BASIC } from 'constants/plans'

const TeamPlayersDataContainer = ({ team, team: { players }, theme }) => {
  const classes = useStyles(theme)
  const tabs = ['stats', 'personal', 'skills']
  const [value, setValue] = useState(tabs[0])
  const [rows, setRows] = useState([...players])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    setRows([...players])
  }, [players])

  function sortCsvDataByPlayer(object1, object2) {
    if (object1.Player < object2.Player) return -1
    if (object1.Player > object2.Player) return 1

    return 0
  }
  const csvData = rows.map((datum) => {
    return hasSubscription() !== BASIC
      ? {
          Player: `${datum.first_name} ${datum.last_name}`,
          'Uniform#': datum.number,
          Position: datum.position,
          'Total Team Trainings': datum.trainings_total,
          'Player Training Participation': `${
            datum.trainings_attended
          } (${Math.round(
            (datum.trainings_attended * 100) / datum.trainings_total
          )}%)`,
          'Total Matches': datum.total_matches,
          'Match Particiaption': `${datum.match_participation} (${Math.round(
            (datum.match_participation * 100) / datum.total_matches
          )}%)`,
          'Total Match Time (Min)': datum.total_minute_played,
          'Goal Scored': datum.goals_scored,
          'Goal Assisted': datum.goals_assisted,
          'Yellow Card': datum.ycard,
          'Yellow+Red Card': datum.yrcard,
          'Red Card': datum.rcard,
        }
      : {
          Player: `${datum.first_name} ${datum.last_name}`,
          'Uniform#': datum.number,
          Position: datum.position,
          'Total Team Trainings': datum.trainings_total,
          'Player Training Participation': `${
            datum.trainings_attended
          } (${Math.round(
            (datum.trainings_attended * 100) / datum.trainings_total
          )}%)`,
        }
  })

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root}>
        <Tabs
          value={value}
          className={classes.title}
          indicatorColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={<Translate id="tab.team-players-statistics" />}
            value="stats"
          />
          <Tab
            label={<Translate id="tab.team-players-attendance" />}
            value="attendance"
          />
          <Tab
            label={<Translate id="tab.team-players-player-data" />}
            value="personal"
          />
          <Tab
            label={<Translate id="tab.team-players-skills" />}
            value="skills"
          />
          <Box ml="auto">
            {value === 'stats' && (
              <CSVLink
                data={csvData ? csvData.sort(sortCsvDataByPlayer) : null}
                className={classes.csvLink}
              >
                <Button
                  variant="contained"
                  className={classes.csvButton}
                  size="samll"
                  startIcon={<SaveAltIcon fontSize="small" />}
                  color="primary"
                >
                  <Translate id="button.export" />
                </Button>
              </CSVLink>
            )}
          </Box>
        </Tabs>
      </Toolbar>
      {value === 'stats' && <PlayersStatistics />}
      {value === 'attendance' && <PlayersAttendance />}
      {value === 'personal' && <PlayersPersonalData />}
      {value === 'skills' && <PlayersSkills />}
    </Paper>
  )
}

TeamPlayersDataContainer.defaultProps = {
  team: {},
}

TeamPlayersDataContainer.propTypes = {
  team: PropTypes.shape(),
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default connect(({ team }) => ({
  team,
}))(TeamPlayersDataContainer)
