import React, {useEffect,useState} from 'react'
import { compose } from 'redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  IconButton,
  Typography,
  Button,
  Dialog,
  Box,
  Tabs,
  Tab,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
} from '@material-ui/core'
import DatePicker from 'components/inputes/DatePicker'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import CloseIcon from '@material-ui/icons/Close'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { InnrHead } from 'containers'
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined'
import Description from '@material-ui/icons/Description'
import { connect } from 'react-redux'
import TabPanel from 'components/material/TabPanel'
import { formatDateForServer } from 'utils/formatDate'
import { checkTextFieldError, handleDateChange, handleTextField } from 'utils/validate'

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const AddEditPlayerFitnessMedical = ({
  playerId,
  onClose,
  open,
  createPlayerPhysique,
  updatePlayerPhysique,
  createPlayerInjury,
  updatePlayerInjury,
  createPlayerNote,
  updatePlayerNote,
  fetchTeamPlayer,
  selectedTabValue,
  existingData,
  dateFormat
}) => {
  const [formData, setFormData] = React.useState({
    height:0.00,
    weight:0.00,
    note:null,
    id:null,
    noted_at:new Date(),
    type:'injury',
    from:new Date(),
    to:new Date()
  })
  const [updateMode, setUpdateMode] = useState(false);
  const [fixedTab, setFixedTab] = useState(0);

  const min = 0;
  const max = 500;

  const handlePhysiqueChange = (event) => {
    let val = event.target.value
    if (event.target.value > max) {
      val = max
    } else if(event.target.value < min) {
      val = min
    }
    event.target.value=val
    handleTextField(event,formData,setFormData)
  }
  
  const handleTabChange = newValue => {
    setFixedTab(newValue)
  }
  const resetForm=()=>{   
    setFormData({
      height:existingData?.height || 0.00,
      weight:existingData?.weight || 0.00,
      note:existingData?.note || '',
      id:existingData?.id || null,
      noted_at:existingData?.noted_at || formatDateForServer(new Date()),
      type:existingData?.type || 'injury',
      from:existingData?.from || formatDateForServer(new Date()),
      to:existingData?.to || formatDateForServer(new Date())
    })    
  }

  const getTabValue = type => {
    switch (type) {
      case 'physique':
        return 0
      case 'injury':
        return 1
      case 'note':
        return 2
      default:
        return 0
    }
  }
   
  const handleFormDateChange = (date,field) => {    
    handleDateChange(date,field,formData,setFormData)
  }

  const onSubmit = () => {
    
    if (fixedTab === 0) {
      
      if (checkTextFieldError(formData, [
        'height',
        'weight',
      ])) return

      const data = {
        category: 'physique',
        height: formData.height,
        weight: formData.weight,
        noted_at: formData.noted_at,
        note: formData.note,
      }
      if (updateMode) {
        updatePlayerPhysique(formData.id, data)
      } else {
        createPlayerPhysique(playerId, data)
      }
    } else if (fixedTab === 1) {
      const data = {
        type: formData.type,
        from: formData.from,
        to: formData.to,
        note: formData.note,
      }
      if (updateMode) {
        updatePlayerInjury(formData.id, data)
      } else {
        createPlayerInjury(playerId, data)
      }
    } else if (fixedTab === 2) {
      const data = {
        noted_at: formData.noted_at,
        note: formData.note,
      }
      if (updateMode) {
        updatePlayerNote(formData.id, data)
      } else {
        createPlayerNote(playerId, data)
      }
    }
    onClose()
    setFixedTab(0)
    fetchTeamPlayer(playerId)
  }

  const handleInjuryTypeChange = event => {
    setFormData({...formData,type:event.target.value})
  }

  useEffect(() => {
    resetForm()
    setUpdateMode(existingData!=null)
    setFixedTab(typeof selectedTabValue === 'string'? getTabValue(selectedTabValue): 0)
  }, []);

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <InnrHead
        title={
          updateMode ? (
            <Translate id="team.update-record" />
          ) : (
            <Translate id="team.add-record" />
          )
        }
      >
        {' '}
        <IconButton onClick={resetForm} size="small">
          <RotateLeftIcon />
        </IconButton>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <>
        <Tabs value={fixedTab} onChange={handleTabChange} centered>
          {!(updateMode && fixedTab !== 0) && (
            <Tab
              icon={<AccessibilityIcon color="action" />}
              label={<Translate id="player.record.body-data" />}
              {...a11yProps(0)}
              onClick={() => handleTabChange(0)}
            />
          )}
          {!(updateMode && fixedTab !== 1) && (
            <Tab
              icon={<HealingOutlinedIcon color="action" />}
              label={<Translate id="player.record.medical-note" />}
              {...a11yProps(0)}
              onClick={() => handleTabChange(1)}
            />
          )}
          {!(updateMode && fixedTab !== 2) && (
            <Tab
              icon={<Description color="action" />}
              label={<Translate id="player.record.additional-note" />}
              {...a11yProps(0)}
              onClick={() => handleTabChange(2)}
            />
          )}
        </Tabs>
        {fixedTab === 0 && (
          <TabPanel value={fixedTab} index={0}>            
            <div container justify="center">
              <Translate>
                {({ translate }) => (
                  <>
                    <DatePicker
                      name="noted_at"
                      label={`${translate('form.note-date')} *`}
                      selectedValue={formData.noted_at}
                      dateFormat={dateFormat}
                      onDateChange={date=>handleFormDateChange(date,'noted_at')}
                    />
                    <Box display="flex">
                      <Box mr={1}>
                          <TextField
                            id="height"
                            name="height"
                            label={`${translate('form.player-height')} *`}
                            value={formData.height}
                            onChange={handlePhysiqueChange}
                            margin='dense'
                            InputProps={{
                              endAdornment: <InputAdornment position="end">(cm)</InputAdornment>,
                            }}
                            aria-describedby="height-helper-text"
                            type="number"
                            variant="outlined"
                            error={formData.height_error}
                            helperText={
                              formData.height_error ? (
                                <Translate id="form.required-fields" />
                              ) : (
                                ''
                              )
                            }
                          />
                      </Box>

                      <Box>
                          <TextField
                            id="weight"
                            name='weight'
                            label={`${translate('form.player-weight')} *`}
                            value={formData.weight}
                            onChange={handlePhysiqueChange}
                            margin='dense'
                            InputProps={{
                              endAdornment: <InputAdornment position="end">(kg)</InputAdornment>,
                            }}
                            aria-describedby="weight-helper-text"
                            type="number"
                            variant="outlined"
                            error={formData.weight_error}
                            helperText={
                              formData.weight_error ? (
                                <Translate id="form.required-fields" />
                              ) : (
                                ''
                              )
                            }
                          />
                      </Box>
                    </Box>
                    <TextField
                      name="note"
                      variant="outlined"
                      label={translate('form.additional-note')}
                      value={formData.note}
                      margin='dense'
                      onChange={(e)=>handleTextField(e,formData,setFormData)}
                    />
                    <Typography textAlign="center">
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                        onClick={onSubmit}
                      >
                        {updateMode ? (
                          <Translate id="team.update-record" />
                        ) : (
                          <Translate id="team.add-record" />
                        )}
                      </Button>
                    </Typography>
                  </>
                )}
              </Translate>
            </div>            
          </TabPanel>
        )}
        {fixedTab === 1 && (
          <TabPanel value={fixedTab} index={1}>
            <div container justify="center">
              <Translate>
                {({ translate }) => (
                  <>
                    <Grid container spacing={0.8}>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" margin='dense'>
                          <InputLabel id="demo-simple-select-outlined-label">
                          {translate('form.type')} *
                          </InputLabel>
                          <Select
                            labelId="type-label"
                            id="type-select-outlined"
                            value={formData.type}
                            onChange={handleInjuryTypeChange}
                            label= {`${translate('form.type')}*`}
                          >
                            <MenuItem value="other">{translate('form.other')}</MenuItem>
                            <MenuItem value="injury">{translate('form.injury')}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          name="from"
                          id="from"
                          label={`${translate('form.from')} *`}
                          selectedValue={formData.from}
                          dateFormat={dateFormat}
                          onDateChange={date=>handleFormDateChange(date,'from')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker
                          name="to"
                          id="to"
                          label={`${translate('form.to')} *`}
                          selectedValue={formData.to}
                          dateFormat={dateFormat}
                          onDateChange={date=>handleFormDateChange(date,'to')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="note"
                          margin='dense'
                          onChange={(e)=>handleTextField(e,formData,setFormData)}
                          variant="outlined"
                          label={translate('form.what-happened')}
                          value={formData.note}
                        />
                      </Grid>
                    </Grid>
                    
                    <Typography textAlign="center">
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                        onClick={onSubmit}
                      >
                        {updateMode ? (
                          <Translate id="team.update-record" />
                        ) : (
                          <Translate id="team.add-record" />
                        )}
                      </Button>
                    </Typography>
                  </>
                )}
              </Translate>
            </div>
          </TabPanel>
        )}
        {fixedTab === 2 && (
          <TabPanel value={fixedTab} index={2}>
            <div container justify="center">
              <Translate>
                {({ translate }) => (
                  <>
                    <DatePicker
                      name="noted_at"
                      label={`${translate('form.note-date')} *`}
                      id="noted_at"
                      margin='dense'
                      selectedValue={formData.noted_at}
                      dateFormat={dateFormat}
                      onDateChange={date=>handleFormDateChange(date,'noted_at')}
                    />
                    <TextField
                      id="note"
                      name="note"
                      margin='dense'
                      label={translate('form.additional-note')}
                      value={formData.note}
                      onChange={(e)=>handleTextField(e,formData,setFormData)}
                      variant="outlined"
                    />
                    <Typography textAlign="center">
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        fullWidth
                        onClick={onSubmit}
                      >
                        {updateMode ? (
                          <Translate id="team.update-record" />
                        ) : (
                          <Translate id="team.add-record" />
                        )}
                      </Button>
                    </Typography>
                  </>
                )}
              </Translate>
            </div>
          </TabPanel>
        )}
      </>
    </Dialog>
  )
}

AddEditPlayerFitnessMedical.defaultProps = {
  existingData:null
}

AddEditPlayerFitnessMedical.propTypes = {
  playerId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  createPlayerPhysique: PropTypes.func.isRequired,
  updatePlayerPhysique: PropTypes.func.isRequired,
  createPlayerInjury: PropTypes.func.isRequired,
  updatePlayerInjury:PropTypes.func.isRequired,
  createPlayerNote: PropTypes.func.isRequired,
  updatePlayerNote: PropTypes.func.isRequired,
  fetchTeamPlayer:PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  existingData: PropTypes.shape(),
  selectedTabValue: PropTypes.string.isRequired
}

export default compose(
  connect(({ auth }) => ({
    dateFormat: auth.user.datetime_format
  }), null),
)(AddEditPlayerFitnessMedical)
