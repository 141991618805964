import React, { useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'
import { auth as authModule, clubs as clubsModule } from 'redux/modules'
import { withLocalize, Translate } from 'react-localize-redux'
import { useLocation, useParams, withRouter } from 'react-router-dom'
import { Grid, Box, Hidden, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Snackbars as Snackbar } from 'components'
import { getSignupURL, setLocale } from 'utils/localstorage'
import { identify } from 'utils/segmentTracking'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import useStyles from 'routes/styleComponents'
import { Header } from 'routes/Signup/components'
import SocialLogin from 'routes/SocialLogin'
import SignInForm from './components/SignInForm'
import PROP_TYPES from 'constants/propTypes'
import languages from 'constants/languages'

const SignInPage = ({
  auth,
  dispatchLogin,
  dispatchResetAuthState,
  fetchClub,
  theme,
  history,
  setActiveLanguage,
}) => {
  const classes = useStyles(theme)

  const { locale } = useParams() // locale from URL (optional)
  const { search } = useLocation() // URL query parameters

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])

  const [snackbar, setSnackbar] = useState(false)

  // Get desired club ID from query parameters or use default
  const clubId = query.get('club') ?? 1

  /**
   * Get preferred language from URL :locale parameter or set it to default (EN)
   * and store value in local storage.
   */
  useEffect(() => {
    if (languages.some((lang) => lang.code === locale)) {
      setLocale(locale)
      setActiveLanguage(locale)
    } else {
      setLocale('en')
      setActiveLanguage('en')
    }
  }, [locale])

  useEffect(() => {
    const { token, signupTeamId } = auth

    dispatchResetAuthState()

    if (token && signupTeamId) {
      history.push(`${getSignupURL()}/step2`)
    } else {
      // setActiveLanguage(language)

      // Fetch default or specified club details and set theme
      fetchClub(clubId)
    }
  }, [])

  const handleSubmit = (values) => {
    setSnackbar(false)

    dispatchLogin(values.email, values.password, locale)
      .then(({ response }) => {
        identify(response.user_id)

        if (response.signup_completed) {
          history.push('/')
        } else {
          history.push(`${getSignupURL()}/step2`)
        }
      })
      .catch(() => {
        setSnackbar({
          message: <Translate id="login.wrong" />,
          error: true,
        })
      })
  }

  const handleLanguage = (event) => {
    setLocale(event.target.value)
    setActiveLanguage(event.target.value)
  }

  return (
    <>
      <Grid container spacing={0} className={classes.root}>
        <Hidden only={['md', 'lg', 'xl']}>
          <Box className={classes.SignupTopText}>
            For full experience use Coachbetter
            <br /> on your desktop computer or tablet
          </Box>
        </Hidden>
        <Grid md={6} item container spacing={0}>
          <Grid item xs={12} className={classes.centeredGridContent}>
            <Box className={classes.loginBox}>
              <Header
                theme={theme}
                heading={<Translate id="login.heading" />}
                subHeading={<Translate id="login.sub-heading" />}
              />
              <SignInForm
                onLanguageChange={handleLanguage}
                inProgress={auth.isLoading}
                theme={theme}
                onSubmit={handleSubmit}
              />
              <Box mb={1}>
                {clubId === 1 && (
                  <SocialLogin
                    history={history}
                    classes={classes}
                    page="login"
                    googleLink={<Translate id="login.signin-with-google" />}
                    facebookLink={<Translate id="login.signin-with-facebook" />}
                  />
                )}
              </Box>
              <Box mt={0.8} align="center">
                {clubId === 1 && (
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.typography}
                  >
                    <Translate id="login.dont-have-acount" /> &nbsp;
                    <Link
                      href={getSignupURL()}
                      className={classes.link}
                      underline="always"
                    >
                      <Translate id="login.signup" />
                    </Link>
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid md={6} item>
            <Box className={classes.background} />
          </Grid>
        </Hidden>
      </Grid>
      {snackbar && <Snackbar {...snackbar} />}
    </>
  )
}

SignInPage.propTypes = {
  auth: PROP_TYPES.shape().isRequired,
  dispatchLogin: PROP_TYPES.func.isRequired,
  dispatchResetAuthState: PROP_TYPES.func.isRequired,
  fetchClub: PROP_TYPES.func.isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  history: PROP_TYPES.shape().isRequired,
  setActiveLanguage: PROP_TYPES.func.isRequired,
}

export default compose(
  withRouter,
  withLocalize,
  connect(
    ({ auth, clubs }) => ({
      auth,
      theme: clubs.current ? clubs.current.theme : {},
    }),
    {
      dispatchLogin: authModule.login,
      dispatchResetAuthState: authModule.reset,
      fetchClub: clubsModule.fetchClub,
    }
  )
)(SignInPage)
