import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { PlayersList } from 'components'
import groupPlayersByPosition from 'utils/groupPlayersByPosition'
import { Paper, Toolbar, Divider, Tabs, Tab } from '@material-ui/core'
import { CreateNew } from '../../../containers/Player/components'

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: 56,
    '& .MuiTab-root': {
      minHeight: 56,
    },
  },
  button: {
    margin: theme.spacing(0, 1.6),
  },
  title: {
    flex: '1 1 0',
    [theme.breakpoints.down('md')]: { width: '30%' },
  },
}))

const TeamPlayers = ({
  teamPlayers,
  //  isCreateNewShown = false
}) => {
  const {
    goalkeepers,
    midfielders,
    defenders,
    strikers,
  } = groupPlayersByPosition(teamPlayers)
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const gkLength = ` (${goalkeepers.length})`
  const mdLength = ` (${midfielders.length})`
  const dfLength = ` (${defenders.length})`
  const stLength = ` (${strikers.length})`

  const classes = useStyles()

  return (
    <>
      <Paper>
        <Toolbar disableGutters="false" className={classes.toolbar}>
          <div className={classes.title}>
            <Translate>
              {({ translate }) => (
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={translate('team.whole-team')} />
                  <Tab label={translate('team.goalies') + gkLength} />
                  <Tab label={translate('team.defender') + dfLength} />
                  <Tab label={translate('team.midfield') + mdLength} />
                  <Tab label={translate('team.striker') + stLength} />
                </Tabs>
              )}
            </Translate>
          </div>
          <CreateNew className={classes.button} />
        </Toolbar>
        <Divider />
        {value === 0 && <PlayersList players={teamPlayers} position="" />}
        {value === 1 && (
          <PlayersList players={goalkeepers} position="goalkeepers" />
        )}
        {value === 2 && (
          <PlayersList players={defenders} position="defenders" />
        )}
        {value === 3 && (
          <PlayersList players={midfielders} position="midfielders" />
        )}
        {value === 4 && <PlayersList players={strikers} position="strikers" />}
      </Paper>
    </>
  )
}

TeamPlayers.defaultProps = {
  teamPlayers: [],
  //   isCreateNewShown: false,
}

TeamPlayers.propTypes = {
  teamPlayers: PropTypes.arrayOf(PropTypes.object.isRequired),
  // isCreateNewShown: PropTypes.bool,
}

export default TeamPlayers
