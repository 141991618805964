import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { DrillModal } from 'components'
import { drills as drillsModule } from 'redux/modules'
import { connect } from 'react-redux'
import { Typography, Box, IconButton, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import InfoIcon from '@material-ui/icons/Info'
import RemoveIcon from '@material-ui/icons/Remove'
import { DrillsList, DrillsListItem } from '../styledComponents'
import {
  SessionCategoryStyled,
  SessionCategoryBox,
  StyledArea,
} from './sessionStyled'

const SessionCategoryMobile = ({
  items,
  removeItem,
  fetchDrill,
  calculateDuration,
}) => {
  const [openedDrillUuid, setOpenedDrillUuid] = useState(null)

  const openedDrill = useMemo(
    () => items.findByUuid(openedDrillUuid),
    [openedDrillUuid, items]
  )

  const closeOpenedDrill = () => setOpenedDrillUuid(null)

  const increaseDuration = (index) => {
    items[index].customized_duration += 1 // eslint-disable-line
    calculateDuration()
  }

  const decreaseDuration = (index) => {
    items[index].customized_duration -= 1 // eslint-disable-line
    calculateDuration()
  }

  return (
    <>
      {openedDrillUuid && (
        <DrillModal
          drill={openedDrill}
          onHide={closeOpenedDrill}
          open={Boolean(openedDrillUuid)}
        />
      )}
      {!items.length ? (
        <>
          <span>
            <Translate id="training.drag-drills" />
          </span>
        </>
      ) : (
        <div />
      )}
      <StyledArea>
        <DrillsList>
          {items.map(
            (
              {
                id,
                uuid,
                title,
                duration,
                customized_duration: customizedDuration,
              },
              index
            ) => (
              <DrillsListItem key={uuid}>
                <SessionCategoryBox>
                  <Box>
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      component="a"
                      align="left"
                      style={{ flexGrow: '1', width: 180, cursor: 'pointer' }}
                      onClick={() => setOpenedDrillUuid(uuid)}
                    >
                      <InfoIcon />
                      {title}
                    </Typography>
                    <SessionCategoryStyled>
                      <IconButton
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => decreaseDuration(index)}
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                      <Box className="mx-1">
                        <TextField
                          value={`${customizedDuration}'`}
                          defaultValue={`${duration}'`}
                        />
                      </Box>
                      <IconButton
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => increaseDuration(index)}
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </SessionCategoryStyled>
                  </Box>
                  <Box>
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => {
                        fetchDrill(uuid, {
                          removed: true,
                          track: 'training_session',
                        })
                        removeItem(id)
                      }}
                    />
                  </Box>
                </SessionCategoryBox>
              </DrillsListItem>
            )
          )}
        </DrillsList>
      </StyledArea>
    </>
  )
}

SessionCategoryMobile.defaultProps = {
  items: [],
  calculateDuration: null,
}

SessionCategoryMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  removeItem: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  calculateDuration: PropTypes.func,
}

export default connect(null, {
  fetchDrill: drillsModule.fetchDrill,
})(SessionCategoryMobile)
