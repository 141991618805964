import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Grid } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { Field, reduxForm } from 'redux-form'
import Joi from 'joi'
import createJoiValidator from 'utils/createJoiValidator'
import InputPassword from 'components/inputes/InputPassword'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import useStyles from '../../styleComponents'
import { Input, LanguageDropdown } from '../../../components'

const validationSchema = Joi.object().keys({
  firstName: Joi.string().max(255).required().label(getTranslatedLabelForFormField('form.first-name')),
  lastName: Joi.string().label(getTranslatedLabelForFormField('form.last-name')),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(255)
    .label(getTranslatedLabelForFormField('form.e-mail'))
    .required(),
  password: Joi.string().min(8).required().label(getTranslatedLabelForFormField('login.password')),
})

const SignupForm = ({
  language,
  onLanguageChange,
  inProgress,
  theme,
  submitting,
  handleSubmit,
}) => {
  const classes = useStyles(theme)

  return (
    <Box>
      <Box mb={1}>
        <Grid container spacing={2} className="socialWrap">
          <Grid item xs={6}>
            <Field
              component={Input}
              label={<Translate id="form.first-name" />}
              name="firstName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={Input}
              label={<Translate id="form.last-name" />}
              name="lastName"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={1}>
        <Field
          name="email"
          label={<Translate id="form.e-mail" />}
          component={Input}
          required
        />
      </Box>
      <Box mb={1}>
        <Field
          component={InputPassword}
          label={<Translate id="login.password" />}
          name="password"
          required
        />
      </Box>
      <Box mb={1}>
        <LanguageDropdown
          language={language}
          handleChange={onLanguageChange}
          margin="none"
        />
      </Box>
      <Button
        disabled={inProgress || submitting}
        fullWidth
        variant="contained"
        type="submit"
        size="large"
        className={classes.button}
        onClick={handleSubmit}
      >
        <Translate id="signup.signup-btn" />
      </Button>
    </Box>
  )
}

SignupForm.defaultProps = {
  theme: {},
}
SignupForm.propTypes = {
  language: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  theme: PropTypes.shape(),
}

export default reduxForm({
  form: 'signupForm',
  validate: createJoiValidator(validationSchema),
})(SignupForm)
