import React from 'react'
import { compose } from 'redux'
import { DropTarget, DragLayer } from 'react-dnd'
import { styled } from '@material-ui/core/styles'
import { Player, DraggablePlayer } from 'containers'
import { Card } from 'components'
import { Button } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'
import './Field.css'

const StyledPlayer = styled('div')({
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
})

const Field = ({
  players,
  onAddNew,
  onChangePlayerPosition,
  onDelete,
  connectDropTarget,
}) => (
  <Card>
    <Card.Title>
      <span>{players.length}</span>
      <Button variant="contained" color="primary" onClick={onAddNew}>
        Add new
      </Button>
    </Card.Title>
    {connectDropTarget(
      <div id="participantsField" className="field">
        {players.map((player, index) => (
          <StyledPlayer
            key={player.id}
            positionX={player.position_x}
            positionY={player.position_y}
            style={{
              left: `${player.position_x}%`,
              top: `${player.position_y}%`,
            }}
          >
            <DraggablePlayer key={player.id} playerId={player.id}>
              <Player.NewForLineup
                number={index + 1}
                playerPosition={player.player_position}
                onChangePosition={onChangePlayerPosition(index)}
                onDelete={() => onDelete(index)}
              />
            </DraggablePlayer>
          </StyledPlayer>
        ))}
      </div>
    )}
  </Card>
)

Field.defaultProps = {
  players: [],
}

Field.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
  onAddNew: PROP_TYPES.func.isRequired,
  onChangePlayerPosition: PROP_TYPES.func.isRequired,
  onDelete: PROP_TYPES.func.isRequired,
  onDrop: PROP_TYPES.func.isRequired, //eslint-disable-line
  connectDropTarget: PROP_TYPES.func.isRequired,
}

const fieldTarget = {
  drop({ item: { playerId }, clientOffset, onDrop }) {
    onDrop(playerId, clientOffset)
  },
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
  })),
  DropTarget('player', fieldTarget, targetConnect => ({
    connectDropTarget: targetConnect.dropTarget(),
  }))
)(Field)
