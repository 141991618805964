import React, { useMemo } from 'react'
import { LinearProgress, Box, makeStyles } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const DURATION_LIMIT = 90

const useStyles = makeStyles(theme => ({
  durationBar: {
    '& > div': {
      marginBottom: 20,
      borderRadius: 30,
      height: 6,
      backgroundColor: ({ isOverflow }) => (isOverflow ? '#ef6d6f' : ''),
      '& > div': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 30,
      },
    },
  },
  durationText: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: '1',
    marginBottom: 6,
    textAlign: 'right',
    color: 'var(--text-dark)',
  },
}));

const SessionDuration = ({ sessionDrills }) => {
  const duration = useMemo(() => {
    return sessionDrills.reduce((total, drill) => total + drill.duration, 0)
  }, [sessionDrills])

  const durationOverflow =
    duration > DURATION_LIMIT ? duration - DURATION_LIMIT : 0

  const relativeDuration =
    duration <= DURATION_LIMIT ? duration : DURATION_LIMIT
    const classes = useStyles();

  return (
    <>
      <Box className={classes.durationText}>
        {duration}/{DURATION_LIMIT}min
      </Box>
      <Box className={classes.durationBar}  isOverflow={Boolean(durationOverflow)}>
      <LinearProgress
          variant="determinate"
          value={(relativeDuration / (DURATION_LIMIT + durationOverflow)) * 100}
        />
      </Box>
    </>
  )
}

SessionDuration.defaultProps = {
  sessionDrills: [],
}

SessionDuration.propTypes = {
  sessionDrills: PROP_TYPES.arrayOfObjects,
}

export default SessionDuration
