import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import {
  Box,
  CardContent,
  Typography,
  IconButton,
  CardActionArea,
  Divider,
  Tooltip,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ShareIcon from '@material-ui/icons/Share'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import EditIcon from '@material-ui/icons/Edit'
import { SubLink } from 'containers'
import fallbackImageUrl from '../../assets/images/login-coach.jpg'
import {
  MuiCard,
  MuiBox,
  CardMedia,
  CardActions,
} from './components/StyledComponents'
import DrillViewDialog from './components/DrillViewDialog'
import PhaseMenu from './components/PhaseMenu'
import AddDrillToTrainingDialog from './components/AddDrillToTrainingDialog'
import { useStyles } from './styles'
import { DRILL_EDIT_ROUTE } from 'constants/routes'

const DrillCard = ({
  drill,
  withPhaseMenu,
  onFavoriteToggle,
  onDuplicate,
  language,
  showAddToTrainingButton,
}) => {
  const classes = useStyles()
  const [drillViewDialogOpen, setDrillViewDialogOpen] = useState(false)
  const [addToTrainingModalOpen, setAddToTrainingModalOpen] = useState(false)

  const canUpdate = drill.can.includes('update')

  const toggleDrillViewDialogOpen = () =>
    setDrillViewDialogOpen(!drillViewDialogOpen)

  // @todo need to discuss with Alina. Any related functionality remaining?
  const handlePhaseSelect = (phase) => console.log(phase)

  const toggleAddToTrainingDialog = () => {
    setAddToTrainingModalOpen(!addToTrainingModalOpen)
  }

  const imageUrl =
    drill.relationships.images.length > 0
      ? drill.relationships.images[drill.relationships.images.length - 1]
          .full_url
      : fallbackImageUrl

  const handleShareClick = () => {
    const shareUrl = `${process.env.REACT_APP_API_URL}/drills/${drill.uuid}/share?language=${language}`
    window.open(shareUrl, '_blank')
  }

  return (
    <>
      {drillViewDialogOpen && (
        <DrillViewDialog
          drill={drill}
          hanldeOpenClose={toggleDrillViewDialogOpen}
          open={drillViewDialogOpen}
          withPhaseMenu={withPhaseMenu}
          handlePhaseSelect={handlePhaseSelect}
        />
      )}
      {addToTrainingModalOpen && (
        <AddDrillToTrainingDialog
          open={addToTrainingModalOpen}
          handleClose={toggleAddToTrainingDialog}
          drill={drill}
        />
      )}
      <MuiCard>
        <CardActionArea onClick={toggleDrillViewDialogOpen}>
          <CardMedia image={imageUrl} />
          <CardContent>
            <Typography gutterBottom variant="h4" component="h4" noWrap>
              {drill.title}
            </Typography>
            <MuiBox>
              <Box>
                <Typography>
                  <Translate id="drills.duration" />:
                </Typography>
                {typeof drill.duration === 'number' &&
                  drill.duration !== null &&
                  `${drill.duration} min`}
              </Box>
              <Box>
                <Typography>
                  <Translate id="drills.players" />:
                </Typography>
                {`${drill.min_players} - ${drill.max_players}`}
              </Box>
            </MuiBox>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions>
          <Box>
            {showAddToTrainingButton && (
              <Tooltip title={<Translate id="training.add-to-training" />}>
                <IconButton
                  onClick={toggleAddToTrainingDialog}
                  size="small"
                  color="primary"
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {canUpdate && (
            <IconButton
              size="small"
              component={SubLink}
              className={classes.editDrillIcon}
              to={DRILL_EDIT_ROUTE.populateURLParams({
                drillUuid: drill.uuid,
              })}
            >
              <Tooltip title={<Translate id="button.edit" />}>
                <EditIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
          <Tooltip title={<Translate id="button.duplicate" />}>
            <IconButton onClick={onDuplicate} size="small">
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={<Translate id="button.share" />}>
            <IconButton onClick={handleShareClick} size="small">
              <ShareIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={<Translate id="drills.favorite" />}>
            <IconButton onClick={onFavoriteToggle} size="small">
              <FavoriteIcon
                color={drill.favorite ? 'primary' : 'inherit'}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
          {withPhaseMenu && <PhaseMenu onPhaseSelect={handlePhaseSelect} />}
        </CardActions>
      </MuiCard>
    </>
  )
}

DrillCard.defaultProps = {
  drill: {
    min_players: 0,
    relationships: {
      images: [],
    },
  },
  withPhaseMenu: false,
  showAddToTrainingButton: false,
}

DrillCard.propTypes = {
  drill: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          full_url: PropTypes.string.isRequired,
        })
      ),
    }),
    duration: PropTypes.number,
    min_players: PropTypes.number,
    max_players: PropTypes.number.isRequired,
    favorite: PropTypes.bool.isRequired,
    can: PropTypes.arrayOf(PropTypes.string),
  }),
  withPhaseMenu: PropTypes.bool,
  onFavoriteToggle: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  showAddToTrainingButton: PropTypes.bool,
  language: PropTypes.string.isRequired,
}

export default DrillCard
