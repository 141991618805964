import React, { useContext, useCallback, useState, useMemo } from 'react'
import { styled, makeStyles, createTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { withRouter } from 'react-router-dom'
import {
  drills as drillsModule,
  trainings as trainingsModule,
} from 'redux/modules'
import ShareIcon from '@material-ui/icons/Share'
import { Translate } from 'react-localize-redux'
import {
  Hidden,
  Button,
  Box,
  Badge,
  Dialog,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Divider,
  Card,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import EventPopup from 'components/material/Calender/eventPopup'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { SubLink } from 'containers'
import playerIcon from 'assets/images/16px/player@1x.svg'
import { compose } from 'redux'
import { Ticker } from 'components'
import hasSubscription from 'utils/hasSubscription'
import UserActionDialog from 'components/material/Calender/UserActionDialog'
import TaskGroupsWidget from '../../containers/TaskManagement/TaskGroupsWidget'
import { Context } from './TrainingStateContext'
import {
  Summary,
  MiniCalendar,
  TrainingPackages,
  Drills,
  Session,
  GroupsAndParticipation,
  TrainingNotes,
} from './components'
import { TRAININGS_GAME } from 'constants/calender'
import { BASIC } from 'constants/plans'

const theme = createTheme({})
const useStyles = makeStyles({
  paper: {
    width: '100%',
    display: 'flex',
    padding: '.6rem',
  },
  Cancel: {
    margin: '0 .8rem',
  },
  PlayerNum: {
    margin: '0 .8rem',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  groupsParticipation: {
    marginTop: 25,
    marginBottom: 25,
  },
  [theme.breakpoints.down('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },

    MobileSectionDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%',
      },
    },
    DialogClose: {
      position: 'absolute',
      top: '4px',
      right: '52px',
      zIndex: '10',
    },
    MobileSectionDialogBtn: {
      textAlign: 'center',
      display: 'flex',
      height: '4.375rem',
      position: 'fixed',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      padding: '0.875rem 1.125rem',
      bottom: '0',
      left: '0',
      borderTop: '1px solid var( --light-grey)',
      background: 'var(--white)',
      width: '100%',
      zIndex: '10',
      '& a': {
        width: '50%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
      '& .MuiBadge-badge': {
        top: '2px',
        left: '6px',
        position: 'relative',
        transform: 'inherit',
      },
    },
  },
})

const CustomDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1.3, 0),
}))

const SESSION_CATEGORIES = ['warmup', 'main_part', 'final_part']
const SESSION_DRILLS_FIELD_NAME = 'sessionDrills'

const TrainingDashboardLayout = ({
  currentTrainingId,
  trainingUuid,
  handleSave,
  availableDrills,
  availableTrainings,
  fetchDrill,
  createTraining,
  deleteTraining,
  history,
  language,
  timeZone,
  setLoading,
  match: { params },
  fetchTrainingTickers,
}) => {
  const { teamId, seasonId } = params
  const {
    trainingState: {
      sessionDrills,
      normalizedPlayers,
      training_players,
      series_uuid,
    },
    onFieldChange,
    resetState,
    setDragging,
  } = useContext(Context)

  const durationMemo = useMemo(() => {
    return sessionDrills.reduce(
      (total, drill) => total + drill.customized_duration,
      0
    )
  }, [sessionDrills])

  const [totalDuration, setTotalDuration] = useState(
    sessionDrills.reduce((total, drill) => total + drill.customized_duration, 0)
  )

  const calculateDuration = () => {
    setTotalDuration(
      sessionDrills.reduce(
        (total, drill) => total + drill.customized_duration,
        0
      )
    )
  }

  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [isAlertBox, changeAlertBox] = useState(false)
  const [isTrainingDialogOpen, setIsTrainingDialogOpen] = useState(false)
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = React.useState(false)
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const onDragEnd = useCallback(
    ({ destination, draggableId: drillUuid, source }) => {
      setDragging({ dragging: false, destination: null })
      if (!destination) {
        return
      }
      const { droppableId: category, index } = destination

      const newDrill = SESSION_CATEGORIES.includes(source.droppableId)
        ? sessionDrills.findByUuid(drillUuid)
        : availableDrills.findByUuid(drillUuid)

      const drillsForTargetCategory = sessionDrills.filter(
        (drill) =>
          drill.session_part === category &&
          (drill.uuid !== drillUuid || source.droppableId !== category)
      )

      drillsForTargetCategory.splice(index, 0, {
        ...newDrill,
        session_part: category,
        customized_duration: newDrill.duration,
      })

      onFieldChange(SESSION_DRILLS_FIELD_NAME, [
        ...drillsForTargetCategory,
        ...sessionDrills.filter(
          (drill) =>
            drill.session_part !== category &&
            (drill.uuid !== drillUuid ||
              drill.session_part !== source.droppableId)
        ),
      ])
      fetchDrill(drillUuid, {})
    },
    [sessionDrills, availableDrills]
  )
  const handleClose2 = () => {
    setOpen2(false)
    changeAlertBox(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
    setIsDialogOpen(false)
  }

  const handleOpen2 = (text = '') => {
    setTrainingAndGame(text)
    setOpen2(true)
  }

  const newCreateTraining = (data) => {
    createTraining(0, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose2()
      window.location.href = `/team/${+teamId}/season/${+seasonId}/trainings/${uuid}`
    })
  }

  const onOK = (props) => {
    deleteTraining(trainingUuid, { apply_on: props }).then(() => {
      window.location.href = `/team/${+teamId}/season/${+seasonId}/events`
      handleClose2()
    })
  }

  const openAlertBox = (hasSeries = false) => {
    if (!hasSeries) {
      changeAlertBox(true)
    } else {
      setIsTrainingDialogOpen(true)
    }
    handleOpen2()
  }

  const handleSaveConfirmation = () => {
    if (series_uuid) {
      setIsDialogOpen(true)
    } else {
      handleSave('SINGLE')
    }
  }
  const openDuplicateBox = (id) => {
    changeDuplicateData(availableTrainings.find((p) => p.id === id) || null)
    handleOpen2(TRAININGS_GAME.training)
    setIsDuplicate(true)
  }
  const onChange = (fieldName) => (newValue) =>
    onFieldChange(fieldName, newValue)

  const CustomIconButton = styled(IconButton)({
    display: 'flex',
  })

  const CustomheadRight = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
    },
  })

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    setTotalDuration(durationMemo)
  }, [durationMemo])

  const handleShareClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/trainings/${trainingUuid}/share?language=${language}&time_zone=${timeZone}`
    window.open(shareUrl, '_blank')
  }

  return (
    <>
      {open2 && (
        <EventPopup
          open={open2}
          onClose={handleClose2}
          onOK={onOK}
          duplicateData={duplicateData}
          isAlertBox={isAlertBox}
          trainingAndGame={trainingAndGame}
          createTraining={newCreateTraining}
          createMatch={() => 0}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
          selectedEventType={trainingAndGame}
          isDuplicate={isDuplicate}
          isTrainingDialogOpen={isTrainingDialogOpen}
        />
      )}

      <Grid container spacing={3}>
        <Hidden only={['xs', 'sm']}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid item md={6} xs={12} align="left" display="flex">
                <MiniCalendar
                  // MiniCalender is a common component for other events too and there we still navigate through id instead of uuid
                  availableTrainings={availableTrainings.map((t) => {
                    return { ...t, isTraining: true }
                  })}
                  trainingUuid={trainingUuid}
                />
              </Grid>
              <Hidden only={['md', 'lg', 'xl']}>
                <CustomDivider />
              </Hidden>
              <Grid item md={6} xs={12} align="right" display="flex">
                <CustomheadRight>
                  <Tooltip
                    title={<Translate id="events.table-player" />}
                    placement="top"
                    arrow
                  >
                    <CustomIconButton className={classes.PlayerNum}>
                      {' '}
                      <img src={playerIcon} alt={playerIcon} />{' '}
                      <Typography variant="h6" component="h6">
                        {`${
                          normalizedPlayers.filter((p) => p.available === 1)
                            .length
                        }/${normalizedPlayers.length}`}
                      </Typography>
                    </CustomIconButton>
                  </Tooltip>
                  <Tooltip
                    title={<Translate id="button.share" />}
                    placement="top"
                    arrow
                  >
                    <IconButton onClick={handleShareClick}>
                      <ShareIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={<Translate id="button.duplicate" />}
                    placement="top"
                    arrow
                  >
                    <IconButton
                      onClick={() => openDuplicateBox(currentTrainingId)}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={<Translate id="button.delete" />}
                    placement="top"
                    arrow
                  >
                    <IconButton onClick={() => openAlertBox(!!series_uuid)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <SubLink
                    to={`/team/${+teamId}/season/${+seasonId}/events`}
                    isFullPath
                  >
                    <Button
                      color="primary"
                      size="large"
                      className={classes.Cancel}
                    >
                      <Translate id="button.cancel" />
                    </Button>
                  </SubLink>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSaveConfirmation}
                  >
                    <Translate id="button.save" />
                  </Button>
                  <UserActionDialog
                    open={isDialogOpen}
                    onClose={handleClose2}
                    onActionOne={() => {
                      handleSave('SINGLE')
                      setIsDialogOpen(false)
                    }}
                    onActionTwo={() => {
                      handleSave('SINGLE_AND_FOLLOWING')
                      setIsDialogOpen(false)
                    }}
                    title={<Translate id="training.update" />}
                    subTitle={<Translate id="training.update-option" />}
                    buttonOneLabel={<Translate id="training.update-this" />}
                    buttonTwoLabel={
                      <Translate id="training.update-this-following" />
                    }
                  />
                </CustomheadRight>
              </Grid>
            </Paper>
          </Grid>
        </Hidden>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={({ draggableId }) => {
            setDragging({ dragging: true, destination: draggableId })
          }}
        >
          <Grid item md={8} lg={9} xs={12}>
            <Hidden only={['md', 'lg', 'xl']}>
              {' '}
              <Summary
                availableTrainings={availableTrainings}
                handleSave={handleSave}
              />
            </Hidden>

            <Drills
              className="session-drills"
              sessionDrills={sessionDrills}
              changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
            />

            <div className="training-packages">
              <TrainingPackages
                sessionDrills={sessionDrills}
                changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
              />
            </div>
            <Card
              className={`${classes.groupsParticipation} groups-participation`}
            >
              <GroupsAndParticipation
                className="groups-participation"
                players={normalizedPlayers}
                training_players={training_players}
                changePlayers={onChange('training_players')}
              />
            </Card>
            {hasSubscription() !== BASIC && (
              <Card>
                <TrainingNotes />
              </Card>
            )}
          </Grid>

          <Grid item md={4} lg={3} xs={12}>
            <Hidden only={['xs', 'sm']}>
              {' '}
              <Summary
                availableTrainings={availableTrainings}
                handleSave={handleSave}
              />
            </Hidden>
            <Box style={{ position: 'sticky', top: '130px' }}>
              <Hidden only={['xs', 'sm']}>
                <Session
                  sessionDrills={sessionDrills}
                  changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
                  handleSave={handleSave}
                  calculateDuration={calculateDuration}
                  totalDuration={totalDuration}
                  resetState={resetState}
                  handleShareClick={handleShareClick}
                  isTrainingSeries={series_uuid}
                />
              </Hidden>

              <Hidden only={['md', 'lg', 'xl']}>
                <Box className={classes.MobileSectionDialogBtn}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    <Translate id="button.view-training" />
                    <Badge
                      badgeContent={`${totalDuration}'`}
                      color="secondary"
                      className={classes.CustomBadge}
                    />
                  </Button>
                  {totalDuration > 0 && (
                    <Badge color="error" badgeContent="!" />
                  )}
                </Box>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  className={classes.MobileSectionDialog}
                >
                  <IconButton
                    edge="start"
                    className={classes.DialogClose}
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Session
                    sessionDrills={sessionDrills}
                    changeSessionDrills={onChange(SESSION_DRILLS_FIELD_NAME)}
                    handleSave={handleSave}
                    calculateDuration={calculateDuration}
                    totalDuration={totalDuration}
                    resetState={resetState}
                    handleShareClick={handleShareClick}
                    isTrainingSeries={series_uuid}
                  />
                </Dialog>
              </Hidden>
              <Box mt={3}>
                <TaskGroupsWidget isTrainingEvent eventId={currentTrainingId} />
              </Box>
              <Box mt={3}>
                <Ticker loader={() => fetchTrainingTickers(trainingUuid)} />
              </Box>
            </Box>
          </Grid>
        </DragDropContext>
      </Grid>
    </>
  )
}

TrainingDashboardLayout.defaultProps = {
  availableDrills: [],
  availableTrainings: [],
}

TrainingDashboardLayout.propTypes = {
  trainingUuid: PropTypes.string.isRequired,
  currentTrainingId: PropTypes.number.isRequired,
  handleSave: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  createTraining: PropTypes.func.isRequired,
  deleteTraining: PropTypes.func.isRequired,
  fetchTrainingTickers: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  availableDrills: PropTypes.arrayOf(PropTypes.shape()),
  availableTrainings: PropTypes.arrayOf(PropTypes.shape()),
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  language: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
}

export default compose(
  connect(
    ({ trainings, drills, auth }) => ({
      availableDrills: Object.values(drills.itemsByCategory).flat(1),
      availableTrainings: trainings.current.trainings,
      trainingUuid: trainings.current.uuid,
      currentTrainingId: trainings.current.id,
      language: auth.user.language,
      timeZone: auth.user.time_zone,
    }),
    {
      fetchDrill: drillsModule.fetchDrill,
      createTraining: trainingsModule.createTraining,
      deleteTraining: trainingsModule.deleteTraining,
      fetchTrainingTickers: trainingsModule.fetchTrainingTickers,
    }
  ),
  withRouter
)(TrainingDashboardLayout)
