import React from 'react'
import { SubLink } from 'containers'
import { Box, Avatar } from '@material-ui/core'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'

const AvatarImage = (params) => {

    return (
        params.row.avatar !== 'none' && (
        <Box display="flex" alignItems="center">
            <Avatar
                // className='mr-2'
                style={{marginRight: '0.5rem'}}
                alt="avatar"
                src={params.row.avatar ? params.row.avatar : params.row.avatar_image}
            />

            <SubLink
                to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
                    playerId: params.row.id,
                })}
                fromPathname={null}
                key={`${params.row.id}`}
            >
                {params.row.firstName}
            </SubLink>
        </Box>)
    )
}
export default AvatarImage
