import React, { useCallback, useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Box, Button, Grid, TablePagination } from '@material-ui/core'
import SearchBar from 'material-ui-search-bar'
import { debounce } from 'lodash'
import { LoadingSpinner, TrainingPackageCard, NotFound } from 'components'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Source from './components/Source'
import {
  fetchClubAgeClasses,
  fetchClubTrainingPackages,
  fetchTeamTrainingPackages,
  fetchFavouriteTrainingPackages,
  updateFavoriteTrainingPackage,
} from './api'
import { useStyles, MenuPaper } from './styles'

const TrainingPackagesContainer = ({ team }) => {
  const classes = useStyles()

  const { drill_providers: drillProviders } = team
  const [providers, setProviders] = useState([])
  const [ageClasses, setAgeClasses] = useState([])
  const [selectedAgeClass, setSelectedAgeClass] = useState('')
  const [selectedSource, setSelectedSource] = useState(
    drillProviders[0]?.uuid || ''
  )
  const [trainingPackages, setTrainingPackages] = useState([])
  const [meta, setMeta] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(12)
  const [page, setPage] = useState(0)

  const checkAndSetAgeClass = useCallback(() => {
    const isTeamAgeClass = ageClasses.some(
      (ageClass) => ageClass.uuid === team.age_class_uuid
    )
    setSelectedAgeClass(isTeamAgeClass ? team.age_class_uuid : 'all')
  }, [ageClasses, team.age_class_uuid])

  useEffect(() => {
    if (ageClasses.length > 0) {
      checkAndSetAgeClass()
    }
  }, [ageClasses])

  const fetchTrainingPackages = async (filters) => {
    try {
      let response = null
      const params = {
        ...filters,
        per_page: rowsPerPage,
        page: page + 1,
      }
      if (selectedSource === 'my-team-training-packages') {
        response = await fetchTeamTrainingPackages(team.uuid, params)
      } else if (selectedSource === 'my-favourites') {
        response = await fetchFavouriteTrainingPackages(team.uuid, params)
      } else {
        response = await fetchClubTrainingPackages(selectedSource, params)
      }
      setTrainingPackages(response.data)
      setMeta(response.meta)
    } catch (error) {
      console.error('Error fetching training packages:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (drillProviders.length > 0) {
      const validProviders = drillProviders.filter(
        (provider) => provider.uuid !== undefined
      )
      const modifiedProviders = [
        ...validProviders,
        {
          name: <Translate id="training.my-team-training-packages" />,
          uuid: 'my-team-training-packages',
        },
        {
          name: <Translate id="drills.my-favourites" />,
          uuid: 'my-favourites',
        },
      ]
      setProviders(modifiedProviders)
    }
  }, [drillProviders])

  useEffect(() => {
    if (
      selectedSource &&
      selectedSource !== 'my-team-training-packages' &&
      selectedSource !== 'my-favourites'
    ) {
      fetchClubAgeClasses(selectedSource)
        .then((res) => {
          setAgeClasses([{ name: 'All', uuid: 'all' }, ...res.data])
        })
        .catch((error) => {
          console.error('Error fetching age classes:', error)
        })
    }
  }, [selectedSource])

  useEffect(() => {
    setLoading(true)
    const filters = {}
    if (
      selectedSource &&
      selectedSource !== 'my-team-training-packages' &&
      selectedSource !== 'my-favourites'
    ) {
      if (selectedAgeClass && selectedAgeClass !== 'all') {
        filters['filter[age_class]'] = selectedAgeClass
      }
    }
    if (searchQuery) {
      filters['filter[search]'] = searchQuery
    }
    if (
      selectedSource &&
      (selectedAgeClass !== '' ||
        selectedSource === 'my-favourites' ||
        selectedSource === 'my-team-training-packages')
    ) {
      fetchTrainingPackages(filters)
    }
  }, [selectedSource, selectedAgeClass, searchQuery, rowsPerPage, page])

  const resetState = () => {
    setPage(0)
    setRowsPerPage(12)
    setSearchQuery('')
  }

  const handleSearchChange = useCallback(
    debounce((newValue) => {
      setSearchQuery(newValue)
    }, 400),
    []
  )

  const handleSourceChange = (event) => {
    setSelectedSource(event.target.value)
    setSelectedAgeClass('all')
    resetState()
  }

  const handleAgeClassChange = (event) => {
    setSelectedAgeClass(event.target.value)
    resetState()
  }

  const handleReset = () => {
    checkAndSetAgeClass()
    resetState()
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleFavoriteToggle = (trainingPackage) => {
    updateFavoriteTrainingPackage(
      trainingPackage.uuid,
      !trainingPackage.favorite
    )
      .then(() => {
        setTrainingPackages((prevPackages) =>
          prevPackages.map((pkg) =>
            pkg.uuid === trainingPackage.uuid
              ? { ...pkg, favorite: !trainingPackage.favorite }
              : pkg
          )
        )
      })
      .catch((error) => {
        console.error('Error toggling favorite status:', error)
      })
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.filtersContainer}>
          <Source
            selectedValue={selectedSource}
            options={providers}
            onChange={handleSourceChange}
            label={<Translate id="drills.source" />}
            labelId="source-label"
          />
          {selectedSource !== 'my-team-training-packages' &&
            selectedSource !== 'my-favourites' && (
              <>
                <Source
                  selectedValue={selectedAgeClass}
                  options={ageClasses}
                  onChange={handleAgeClassChange}
                  label={<Translate id="team.age-class" />}
                  labelId="age-class-label"
                />
                <Button onClick={handleReset}>
                  <Translate id="button.reset" />
                </Button>
              </>
            )}
        </Box>
        <Box className={classes.searchBarContainer}>
          <SearchBar
            value={searchQuery}
            onChange={handleSearchChange}
            onCancelSearch={() => {
              setSearchQuery('')
              handleSearchChange('')
            }}
            className={classes.searchBar}
          />
          <TablePagination
            labelRowsPerPage={<Translate id="drills.rows-perpage" />}
            component="div"
            count={meta.total}
            page={page}
            className={classes.tablePagination}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[12, 24, 36]}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  component: MenuPaper,
                },
              },
            }}
          />
        </Box>
      </Box>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Grid container spacing={3}>
          {trainingPackages.length > 0 ? (
            trainingPackages.map((trainingPackage) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={trainingPackage.uuid}
              >
                <TrainingPackageCard
                  trainingPackage={trainingPackage}
                  onFavoriteToggle={() => handleFavoriteToggle(trainingPackage)}
                  showAddToTrainingButton
                />
              </Grid>
            ))
          ) : (
            <NotFound />
          )}
        </Grid>
      )}
    </>
  )
}

TrainingPackagesContainer.propTypes = {
  team: PropTypes.shape({
    drill_providers: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    uuid: PropTypes.string.isRequired,
    age_class_uuid: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  connect(({ team }) => ({
    team,
  }))
)(TrainingPackagesContainer)
