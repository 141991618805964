import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import {
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { Input } from 'components'
import { PLAYER_POSITIONS } from 'constants/player'
import PROP_TYPES from 'constants/propTypes'

const AddPlayerManually = ({ handleSave, onClose }) => {
  const [error, setError] = useState(false)
  const emptyPlayer = {
    first_name: '',
    last_name: '',
    number: '',
    position: 'midfield',
  }
  const [player, setPlayer] = useState(emptyPlayer)

  const resetForm = () => {
    setPlayer(emptyPlayer)
  }
  const handleInput = (event) => {
    const {
      target: { name, value },
    } = { ...event }

    setPlayer({ ...player, [name]: value })
  }
  const handleSaveDialog = (isClose) => {
    const { first_name: firstName, last_name: lastName } = { ...player }

    if (!firstName || !lastName) {
      setError(true)

      return false
    }
    setError(false)

    handleSave(player)

    if (isClose) {
      onClose()
      resetForm()
    } else {
      resetForm()
    }

    return false
  }

  return (
    <>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Input
            label={<Translate id="form.first-name" />}
            margin="dense"
            onChange={handleInput}
            name="first_name"
            value={player.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Input
            label={<Translate id="form.last-name" />}
            margin="dense"
            onChange={handleInput}
            name="last_name"
            value={player.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Input
            type="number"
            label={<Translate id="form.number" />}
            margin="dense"
            onChange={handleInput}
            name="number"
            value={player.number}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <FormControl variant="outlined" margin="dense">
            <Select
              labelId="Position"
              name="position"
              value={player.position}
              onChange={handleInput}
            >
              {PLAYER_POSITIONS.map((option) => (
                <MenuItem key={`${option.value}`} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {error ? (
          <Typography color="error">
            <Translate id="form.all-required-fields" />
          </Typography>
        ) : (
          <Typography color="error">
            <Translate id="form.required-fields" />
          </Typography>
        )}
      </Grid>
      <Grid container spacing={2} style= {{ marginTop: '1rem', marginBottom: '1rem'}}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveDialog(false)}
            fullWidth
          >
            <Translate id="matches.highlights.save-n-add" />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveDialog(true)}
            fullWidth
          >
            <Translate id="matches.highlights.add-n-exit" />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

AddPlayerManually.propTypes = {
  handleSave: PROP_TYPES.func.isRequired,
  onClose: PROP_TYPES.func.isRequired,
}
export default AddPlayerManually
