import { Paper } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filtersContainer: {
    display: 'flex',
    justifyItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  searchBar: {
    background: '#f2f2f2',
    width: '600px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    marginTop: 0,
    maxWidth: 100,
    marginBottom: 0,
    marginRight: 100,
    '& .MuiInputBase-formControl': {
      height: 48,
      minWidth: 180,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  tablePagination: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  [theme.breakpoints.down('xs')]: {
    formControl: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    '& .MuiList-padding': {
      paddingBottom: 0,
    },
    '& .MuiPaper-root': {
      width: 'auto',
      maxHeight: 500,
      maxWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80vw',
        height: 'auto',
      },
    },
  },
  stickyButtons: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexDisplay: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  categoriesLabel: {
    marginRight: '0px',
  },
}))

export const MenuPaper = styled(Paper)({
  border: `1px solid ${grey[300]}`,
  boxShadow: `1px 1px 12px 0 ${grey[400]}`,
})
