import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { Translate } from 'react-localize-redux'
import { users as usersModule, players as playerModule } from 'redux/modules'
import AddPlayersDialog from 'containers/TeamDashboard/components/AddPlayersDialog'
import useStyles from '../styled'
import { playerColumns, SORT_NAME } from '../Constants'
import PROP_TYPES from 'constants/propTypes'

const PlayerAccess = ({ players, team, revokeUserRole, fetchTeamPlayers, theme }) => {
  const classes = useStyles(theme)
  const [allplayers, setAllplayers] = useState([])
  const [loadingcircular, setLoadingCircular] = useState(false)
  const [openAddPlayers, setOpenAddPlayers] = useState(false)
  const [tabDisabled, setTabDisabled] = useState(false)
  const [sortModel, setSortModel] = React.useState(SORT_NAME)

  const getAllPlayers = () => {
    fetchTeamPlayers(team.id, { fields: `users` }).then(result => {
      setAllplayers(result.response.data)
    })
      .catch(() => {
        throw Error("Error in player API.")
      })
  }

  if (!allplayers.length && players.length > 0) {
    getAllPlayers()
  }
  
  const playerRows = allplayers.flatMap((player) => {
    const rows = !player.users_with_role.length ? [{
      playerId: player.id,
      id: player.id,
      firstName: `${player.first_name} ${player.last_name}`,
      avatar: player.avatar?.full_url,
      email: null,
      position: null,
      userRoleUuid: null,
      deletePlayer: null,
    }] : []
    player.users_with_role.forEach((user, index) => {
      const playerInfo = {
        playerId: player.id,
        id: `${player.id}-${index}`,
        firstName: `${player.first_name} ${player.last_name}`,
        avatar: (index===0) ? player.avatar?.full_url : 'none',
        email: user.user.email,
        position: user.role.name.charAt(0).toUpperCase() + user.role.name.slice(1),
        userRoleUuid: user.uuid,
        deletePlayer: revokeUserRole,
      };
      rows.push(playerInfo);
    })
    return rows;
  });

  const handleClose = () => {
    setOpenAddPlayers(false)
  }

  const handleSave = () => {
    setLoadingCircular(true)
  }

  const handleAddPlayersOpen = () => {
    setOpenAddPlayers(true)
    setTabDisabled(true)
  }

  return (
    <Box className={classes.root}>
      {openAddPlayers && (
        <AddPlayersDialog
          open={openAddPlayers}
          tabDisabled={tabDisabled}
          onClose={handleClose}
          handleSave={handleSave}
          loadingcircular={loadingcircular}
        />
      )}
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleAddPlayersOpen}
        style={{ marginBottom: '1.5rem' }}
      >
        <Typography variant="button">
          <Translate id="team-invite.add-players" />
        </Typography>
      </Button>
      <DataGrid
        className={classes.table}
        rows={playerRows}
        columns={playerColumns}
        pageSize={100}
        rowHeight={60}
        headerHeight={45}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        disableColumnFilter
        hideFooter
        disableColumnSelector
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}

PlayerAccess.defaultProps = {
  players: [],
}

PlayerAccess.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
  team: PROP_TYPES.shape().isRequired,
  revokeUserRole: PROP_TYPES.func.isRequired,
  fetchTeamPlayers: PROP_TYPES.func.isRequired,
  theme: PROP_TYPES.theme.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ team }) => ({
      team,
    }),
    {
      revokeUserRole: usersModule.revokeUserRole,
      fetchTeamPlayers: playerModule.fetchTeamPlayers,
    }
  )
)(PlayerAccess)
