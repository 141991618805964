import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { Select, MenuItem, InputLabel } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'
import { PLAYER_TAGS } from 'constants/player'

const PlayerTags = ({
  input,
  options,
  name,
  label,
  disabled,
  meta,
  size,
  variant,
  margin,
}) => {
  const error = meta ? meta.touched && meta.error : null
  const [playerTag, changePlayerTag] = useState(PLAYER_TAGS[0].value)

  return (
    <>
      <FormControl error={error} variant={variant} size={size} margin={margin}>
        <InputLabel id="player-tag-label">{label}</InputLabel>
        <Select
          labelId="player-tag-label"
          name={name}
          value={playerTag}
          disabled={disabled}
          label={label}
          helperText={error}
          onChange={event => {
            changePlayerTag(event.target.value)
          }}
          {...input}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

PlayerTags.defaultProps = {
  disabled: false,
  label: '',
  name: '',
  margin: 'none',
  size: 'small',
  variant: 'outlined',
  id: '',
  options: PLAYER_TAGS,
}

PlayerTags.propTypes = {
  input: PropTypes.shape().isRequired,
  options: PROP_TYPES.options,
  meta: PropTypes.shape().isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
}

export default PlayerTags
