import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { Box as MuiBox } from '@material-ui/core'
import { setLocale, getLocale } from 'utils/localstorage'
import { auth as authModule } from 'redux/modules'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Box } from '../styledComponents'

const LanguageToggle = ({
  languages,
  activeLanguage,
  setActiveLanguage,
  user,
  updateUser,
}) => {

  setLocale(getLocale())
  const [langs, setLangs] = React.useState(
    languages.map(p => {
      const lang = { ...p }

      return {
        value: lang.code,
        title: lang.name,
        active: lang.code === getLocale(),
      }
    })
  )
  const handleLang = code => {
    setActiveLanguage(code)
    setLocale(code, activeLanguage)

    setLangs(
      langs.map(p => {
        const lang = { ...p }
        if (lang.value === code) {
          lang.active = true
        } else {
          lang.active = false
        }

        return lang
      })
    )
    updateUser({id:user.id, language: code, preventToastNotification: true})
  }

  React.useEffect(() => {
    setActiveLanguage(getLocale())
  }, [langs])

  return (
           <Box px={2} py={2} textAlign="center">
                  {langs.map(({ value, title, active }) => {
                    return (
                      <MuiBox
                      variant="span"
                      component="span"
                        className={active ? 'active' : ''}
                        key={value}
                        onClick={() => handleLang(value)}
                      >
                        {title}
                      </MuiBox>
                    )
                  })}
                </Box>       

  )
}

LanguageToggle.propTypes = {
  languages: PropTypes.array,
  activeLanguage: PropTypes.shape(),
  setActiveLanguage: PropTypes.func,
  updateUser:PropTypes.func,
  user:PropTypes.array,
}

export default compose(
  connect(
    ({ auth }) => ({
      user:auth.user,
    }),
    {
      updateUser: authModule.updateUser,
    }
  ),
  withLocalize,
  withRouter
)(LanguageToggle)
