import React, { useState } from 'react'
import { connect } from 'react-redux'
import { MatchPlayerTags, PlayerCurrentTag } from 'components'
import { Box, Menu } from '@material-ui/core'
import { players } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const Tag = ({ playerId, tag: initialTag, onChange, updatePlayer }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [tag, changeTag] = useState(initialTag)

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = newTag => {
    changeTag(newTag)

    setAnchorEl(null)

    if (onChange) {
      onChange({ tag: newTag })

      return
    }

    updatePlayer(playerId, { tag: newTag })
  }

  return (
    <>
      <Box onClick={handleClickListItem}>
        {' '}
        <PlayerCurrentTag tag={tag} />
      </Box>
      <Menu
        id="player-tag"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MatchPlayerTags value={tag} onChange={handleChange} />
      </Menu>
    </>
  )
}

Tag.defaultProps = {
  tag: '',
  onChange: null,
}

Tag.propTypes = {
  playerId: PROP_TYPES.id.isRequired,
  tag: PROP_TYPES.string,
  onChange: PROP_TYPES.func,
  updatePlayer: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  { updatePlayer: players.updatePlayer }
)(Tag)
